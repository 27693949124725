import Explainer from '@kerfed/common-ui/components/Explainer';
import FileImage from '@kerfed/common-ui/components/FileImage';
import { FormattedId } from '@kerfed/common-ui/components/formatting';
import React from 'react';
import { FileUploader } from '../components';

type FileProps = {
  fileId: string;
  file: Components.Schemas.File;
};

const File = ({ fileId, file }: FileProps) => (
  <div className="card">
    <FileImage url={file.previews?.png} />
    {file?.status?.error?.message && !file?.previews?.png ? (
      <img src="/static/images/frown.svg" />
    ) : null}
    <div className="card-body">
      <h2 className="card-title">{file.name}</h2>
      <div className="id-text card-text ">
        <Explainer
          explanation={
            'FID is the File ID, which may be referenced in drawings and invoices.'
          }
        >
          FID:&nbsp;
          <FormattedId id={fileId} />
        </Explainer>

        {file?.status?.error?.message ? (
          <div>
            <strong>Error: </strong>
            {file?.status?.error?.message}
          </div>
        ) : null}
      </div>

      <h5 className="card-text">
        {file.status.isDone ? null : (
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped"
              role="progressbar"
              style={{ width: `${(file.status.progress || 0.0) * 100.0}%` }}
              aria-valuenow={(file.status.progress || 0.0) * 100.0}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
        )}
      </h5>
    </div>
  </div>
);

type Props = {
  files: { [fileId: string]: Components.Schemas.File };
  onUploads: any;
};

export default ({ files, onUploads }: Props) => (
  <div className="card-deck">
    {Object.entries(files).map(entry => {
      const [fileId, file] = entry;
      return <File key={fileId} fileId={fileId} file={file} />;
    })}
    <div className="card dropzone">
      <FileUploader onUploads={onUploads} />
    </div>
  </div>
);
