import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import logo from 'url:../images/logo_black.svg';

interface ArticleProps {
  articleUrl: string;
}

export default class Article extends Component<ArticleProps, any> {
  state = { markdown: undefined };

  componentDidMount() {
    fetch(this.props.articleUrl)
      .then(res => res.text())
      .then(markdown => this.setState({ markdown }));
  }

  render() {
    return (
      <div className="container-md bg-white p-3">
        <div>
          {this.state.markdown ? (
            <ReactMarkdown source={this.state.markdown} />
          ) : (
            <div className="loader loader-inline">
              <div className="spinner-border " role="status" />
            </div>
          )}
          <div className="segmentRectangle" />
        </div>
      </div>
    );
  }
}

export function About(props: any) {
  return (
    <div className="container-md bg-white p-3">
      <div className="grid p-3">
        <div className="row">
          <div className="col-md-6">
            <img className="img-fluid" src={logo} />
          </div>
        </div>
        <div className="row">
          <div className="card col ">
            <div className="card-body">
              <h5 className="card-title">
                <h2>Company Mission</h2>
              </h5>
              <br />
              <p style={{ fontSize: '1.4rem' }}>
                "Increase the productivity of both machine designers and
                manufacturers through <strong>part generic</strong> automation."
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h2>Background</h2>

            <p>
              Kerfed, Inc. is based in Pittsburgh, Pennsylvania and was founded
              by alumni of the
              <a href="https://www.ri.cmu.edu/"> CMU Robotics Institute, </a>
              after years of working on various research and corporate projects.
            </p>
            <p>
              Kerfed’s spiritual predecessor is{' '}
              <a href="https://www.darpa.mil/program/adaptive-vehicle-make">
                DARPA's AVM/iFAB
              </a>
              . One of the insights behind the program was that rather than
              aspiring to replace every process with slice-based 3D printing
              techniques we should create a "hardware abstraction layer". This
              layer could divide parts between different fabrication processes
              and suppliers. At the time, iFAB required a lot of manual part
              annotation which made the dream of “3D model in, HMMWV out”
              include substantial amounts of engineering time per-part.
            </p>
            <p>
              Over the years since we have developed the Kerfed Engine, an
              automated, robust and performant product. We are working to
              develop physical and software automation to help machine shops and
              manufacturers increase productivity, revenue, and ability to
              compete globally.
            </p>
          </div>
        </div>
        <div className="grid">
          <div className="row">
            <div className="col-md">
              <h2>Contact</h2>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Information</td>
                    <td>
                      <a href="mailto:info@kerfed.com">info@kerfed.com</a>
                    </td>
                  </tr>
                  <tr>
                    <td>Support</td>
                    <td>
                      <a href="mailto:support@kerfed.com">support@kerfed.com</a>
                    </td>
                  </tr>
                  <tr>
                    <td>Accounting</td>
                    <td>
                      <a href="mailto:accounting@kerfed.com">
                        accounting@kerfed.com
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>(412) 532-9813</td>
                  </tr>
                </tbody>
              </table>
              <h2>Mailing Address</h2>
              <div className="card col mx-3">
                <div className="card-body">
                  <strong>Kerfed, Inc.</strong>
                  <br />
                  6425 Living Place #2061
                  <br />
                  Pittsburgh, PA 15206
                </div>
              </div>
            </div>
            <div className="col-md">
              <img src="images/office.jpg" />
            </div>
          </div>
        </div>
        <div className="segmentRectangle" />
      </div>
    </div>
  );
}

export function Tolerances(props: any) {
  return (
    <div className="container-md bg-white p-3">
      <div>
        <h1>Mechanical Tolerances</h1>
        <h2>Scope</h2>
        <p>
          This is the main tolerance policy, and it applies to all parts and
          overrides tolerances listed anywhere else. This tolerance policy will
          be automatically evaluated for each part, and tolerances will be
          listed in the block section of the proof drawings. If the drawings
          disagree with this policy for any reason, the less precise of the two
          numbers is what we commit to.
        </p>
        <h2>Definitions</h2>
        <div>
          <div className="card col mx-3">
            <img src="/static/images/drawing.svg" />
            <div className="card-body">
              <h5 className="card-text">
                An example of the drawings automatically generated for each
                part: note tolerance in block (click to enlarge)
              </h5>
            </div>
          </div>
        </div>
        <ul>
          <li>
            <strong>Part Length</strong>: the diagonal of an oriented bounding
            box of a part. For example, a 12”x12”x1” part would have a part
            length of 17.0”
          </li>
        </ul>
        <h2>Flat Part Tolerances</h2>
        <p>
          Kerfed offers two processes for flat parts, each of which have their
          own tolerances
        </p>
        <h3>Machined Flat Parts</h3>
        <ul>
          <li>All inner and outer profiles will have a milled finish</li>
          <li>Profile dimensions will be +/- .002”</li>
          <li>
            Thickness tolerance is +/- .003”, and top and bottom faces may or
            may not be milled
          </li>
        </ul>
        <h3>Waterjet / Laser Flat Parts</h3>
        <ul>
          <li>Inner and outer edges will have a rough surface finish</li>
          <li>
            Inner and outer edges will have a taper not to exceed 3° (.013” on a
            .25” thick part)
          </li>
          <li>
            2D features measured on one of the two faces will be +/- .004” for
            part length under 24”
          </li>
          <li>
            2D features measured on one of the two faces will be +/- .010” for
            part length over 24”
          </li>
          <li>
            Top and bottom faces will not be milled (stock plate/bar stock
            surface finish)
          </li>
          <li>
            If a reaming operation is specified, the hole diameter is +/- .0005”
          </li>
        </ul>
        <h2>Bent Parts Tolerances</h2>
        <ul>
          <li>Bends will be +/- 2°</li>
          <li>
            Features in flat regions of the part will accurate relative to other
            features in that same flat region to +/-.004”
          </li>
        </ul>
        <h2>COTS Parts</h2>
        <ul>
          <li>Parts are globally sourced</li>
          <li>
            Trade size bearings and pins generally have standard mechanical
            properties, but no guarantees are made other than that the part
            numbers will match.
          </li>
        </ul>
        <div className="segmentRectangle" />
      </div>
    </div>
  );
}

export function Terms(props: any) {
  return <Article articleUrl="/static/content/terms.md" />;
}

export function Returns(props: any) {
  return <Article articleUrl="/static/content/returns.md" />;
}

export function Design(props: any) {
  return (
    <div className="container-md bg-white p-3">
      <div>
        <h1>Machine Design</h1>
        <p>
          Kerfed is focused on assemblies designed primarily with bent sheet
          metal and flat plate parts with commercial spacers, dowel pins, and
          bearings.
        </p>
        <p>
          There are numerous advantages to designing things this way, rather
          than a heavy reliance on machined mill or lathe parts: short lead
          times, good materials, and a lot of precision per dollar.
        </p>
        <h2>Kerfed Engine Tips</h2>
        <ul>
          <li>
            Kerfed will use hints in part and file names to help autoconfigure
            parts
          </li>
          <li>Supported delimiters are: ".", "-", "_"</li>
          <li>Hints are not case sensitive</li>
          <ul>
            <li>
              Unit Hints: for file formats that don't specify units, e.g. STL
              files
            </li>
            <ul>
              <li>Millimeters: "unitMM"</li>
              <li>Meters: "unitM"</li>
              <li>Inches: "unitIN"</li>
            </ul>
            <li>Material Hints: for formats which don't support materials</li>
            <ul>
              <li>6061-T6 Aluminum: "6061"</li>
              <li>5052 Aluminum: "5052"</li>
              <li>Steel, A36: "A36"</li>
            </ul>
            <li>Examples</li>
            <ul>
              <li>
                plate_top_a36: will automatically apply A36 steel as the
                material to the part
              </li>
              <li>
                upper.suspension.unitmm.STL: will indicate you've saved the STL
                file (which has no support for units) in millimeters.
              </li>
            </ul>
          </ul>
          <li>
            Kerfed, like{' '}
            <a href="http://www.freesteel.co.uk/wpblog/2009/11/23/detecting-shallow-areas-on-bad-triangulations">
              {' '}
              most CAM systems that actually work
            </a>{' '}
            operates on{' '}
            <a href="https://blog.grabcad.com/blog/2013/04/22/a-brief-introduction-to-cad-file-formats">
              tessellated geometry
            </a>
          </li>
          <ul>
            <li>
              <strong>3DXML</strong> is a very concise and quick to export
              format well supported by Solidworks: some assemblies that are 25MB
              STL files are less than 1MB as 3DXML. It supports part names,
              instancing, units, and other metadata.
            </li>
            <li>
              <a href="https://3mf.io/what-is-3mf">3MF</a> is a 3D printing
              format that has rough support for instancing, and is a good second
              choice.
            </li>
            <li>
              Saving an assembly as mesh format (like STL or PLY) will work with
              Kerfed, but these formats usually don't support things like part
              names, instancing, colors, or units.
            </li>
            <li>
              If you save multiple parts in the same mesh file as a "polygon
              soup," Kerfed will be able to split it into parts and figure out
              quantities and unique parts from geometric identifiers.
            </li>
          </ul>
        </ul>
        <h2>Design of Flat Parts: Waterjets, Fiber Lasers</h2>
        <div>
          <div className="card col mx-3">
            <img src="/static/images/waterjet_close.jpg" />
            <div className="card-body">
              <h5 className="card-text">A waterjet cutting aluminum</h5>
            </div>
          </div>
        </div>
        <p>
          These machines are typically designed to process 4'x8' or 5'x10' sheet
          material, and can generally position their cutting head within
          &plusmn;.001". Process variables usually reduce part tolerance to
          &plusmn;.004". The material is typically fixed, so that tolerance is
          global to the part rather than relative to other features like you
          would get in processes that require refixturing followed by indexing.
        </p>
        <p>
          Fiber lasers are generally better suited to materials .375" or
          thinner. Waterjets are slower but can cut much thicker materials:
          Kerfed restricts flat parts to 2" thick due to plate stock
          availability, but water jets are able to cut thicker materials.
        </p>
        <h4>Tips</h4>
        <ul>
          <li>
            If you require the outer profile of a part to mate precisely with
            other parts, waterjet/laser parts are not the best option: the
            Kerfed Engine will recommend machining if it detects interferences
            on the outer profile
          </li>
          <li>
            Watejets will product a dimensionally accurate, but rough and
            slightly tapered (approximately 3 degrees) edge
          </li>
          <li>
            A great strategy is to waterjet plate parts, and then have circular
            holes reamed to interface with precision components like dowel pins
            or ball bearings
          </li>
          <ul>
            <li>
              Kerfed will detect these interferences and recommend secondary
              reaming operations
            </li>
            <li>
              It is always a good strategy to undersize the holes in your model
              if you intend them to be reamed: generally .010" is a safe amount
              under the cut tolerance to allow for reaming.
            </li>
          </ul>
        </ul>
        <h4>Typical Machines</h4>
        <ul>
          <li>
            <a href="http://www.bystronic.com/en/products/laser-cutting-systems/BySprintFiber.php">
              Bystronic Fiber Laser
            </a>
          </li>
          <li>
            <a href="http://www.amada.com/america/laser-cutting-systems">
              Amada Fiber Laser
            </a>
          </li>
          <li>
            <a href="https://www.flowwaterjet.com/">Flow Waterjet</a>
          </li>
          <li>
            <a href="https://www.omax.com">Omax Waterjet</a>
          </li>
        </ul>
        <h4>Additional Design Guides</h4>
        <ul>
          <li>
            <a href="https://www.thefabricator.com/article/waterjetcutting/designing-for-abrasive-waterjet-fabrication">
              Designing For Abrasive Waterjet Fabrication
            </a>
          </li>
          <li>
            <a href="http://www.instructables.com/id/How-to-Build-your-Everything-Really-Really-Fast">
              How To Build Your Everything Really Really Fast
            </a>
          </li>
        </ul>
        <h2>Design of Sheet Metal Parts: CNC Press Brake</h2>
        <div>
          <div className="card col mx-3">
            <img src="/static/images/press.jpg" />
            <div className="card-body">
              <h5 className="card-text">A CNC press brake</h5>
            </div>
          </div>
        </div>
        <h4>Tips</h4>
        <ul>
          <li>
            Use the <strong>sheet metal tools</strong> in your CAD package
          </li>
          <ul>
            <li>This will generate flattenable geometries.</li>
            <li>
              If you model parts using extrusions or other tools, the inner and
              outer radii at a bend will not be consistent with a bent sheet,
              and Kerfed (or anybody else) will not be able to unbend them.
            </li>
          </ul>
          <li>Try to keep bend count to a minimum.</li>
          <li>Multiple bends are easier if they have parallel axis</li>
          <li>
            Kerfed currently only supports bend lines: rolled or punched
            features are not supported.
          </li>
        </ul>
        <h4>Typical Machines</h4>
        <ul>
          <li>
            <a href="http://www.amada.com/america/press-brakes">
              Amada CNC Press Brake
            </a>
          </li>
          <li>
            <a href="https://www.trumpf.com/en_US/products/machines-systems/bending-machines">
              Trumpf CNC Press Brake
            </a>
          </li>
          <li>
            <a href="https://www.e-ci.com/press-brakes/">
              Cincinnati CNC Press Brake
            </a>
          </li>
        </ul>

        <h4>Additional Design Guides</h4>
        <ul>
          <li>
            <a href="http://www.machinedesign.com/mechanical/following-dfm-guidelines-working-sheet-metal">
              Machine Design Magazine: DFM Guidelines For Sheet Metal
            </a>
          </li>
        </ul>
        <h2>Commercial (COTS) Parts</h2>
        <p>
          The Kerfed Engine recognizes a number of commercial, off the shelf
          parts. It has two strategies for recognizing parts: an exact hash
          query which requires our database to have the exact component you are
          referencing. This is useful for components directly from vendors with
          exact models used with no alterations.
        </p>
        <p>
          The other method is a parameterized query that works on commercial
          round parts: dowel pins, bearings, round spacers, washers. The
          advantage of this is there are a hundred ways to draw the same ball
          bearing (are the edges filleted or chamfered, how much, did you bother
          to model the races and balls, etc), and by doing a parameterized query
          we don't need an exact match on internal geometry. Your model just
          needs to have the right outer diameter, inner diameter (if
          applicable), and height.
        </p>
        <h4>Bearings</h4>
        <p>
          Due to global production, metric bearings are often wildly cheaper
          than inch sized bearings, especially at larger diameters. The COTS
          database references trade size bearings, which are widely available
          from vendors like <a href="http://www.vxb.com">VXB</a>,{' '}
          <a href="https://www.thebigbearingstore.com">Big Bearing Store</a>,{' '}
          <a href="https://www.mcmaster.com">McMaster-Carr</a>, as well as
          Alibaba.
        </p>
        <h4>Dowel Pins</h4>
        <p>
          A dowel pin in a reamed hole is a great way to design fixtures, shear
          connections between plates, and repeatable references.
        </p>
        <h4>Threaded Connections</h4>
        <p>
          The Kerfed Engine does not automatically recognize fasteners, as for a
          number of reasons this is more difficult to do robustly than bearings
          and pins. Some general advice however:
        </p>
        <ul>
          <li>
            Every threaded connection should have a thread locking strategy
          </li>
          <ul>
            <li>
              An easy and bulletproof connection is two through holes, a{' '}
              <a href="https://www.mcmaster.com/%2391257a550">hex cap</a> or{' '}
              <a href="https://www.mcmaster.com/%2391251a550">
                socket head cap
              </a>{' '}
              screw, a{' '}
              <a href="https://www.mcmaster.com/%2397135a210">nylock nut</a>,
              and a{' '}
              <a href="https://www.mcmaster.com/%2398180a110">flat washer</a> on
              both sides.
            </li>
            <li>
              Captive nuts such as PEM or rivet nuts can be a good option, and
              are one less loose component, although separate thread locker may
              be required
            </li>
            <li>
              <a href="https://www.mcmaster.com/%2391205a550">
                Nylon patch screws
              </a>{' '}
              are available with pre-applied threadlocker, which is better than
              a lock washer or nothing, but worse than a nylock.
            </li>
          </ul>
          <li>
            Tapped holes reduce part profile, but are usually worse from a
            strength, fabrication, and thread locking perspective than a chunky
            cap screw/nylock.
          </li>
        </ul>
        <h2>Next Steps</h2>
        <p>
          Feel free to reach out to{' '}
          <a href="mailto:support@kerfed.com">support@kerfed.com</a> with any
          questions.
        </p>
        <div className="segmentRectangle" />
      </div>
    </div>
  );
}

export function Pricing(props: any) {
  return (
    <div className="container-md bg-white p-3">
      <h1>Kerfed Engine: Pricing</h1>
      <div className="grid">
        <div className="row my-auto">
          <div className="card col mx-3">
            <img src="/static/images/hillclimb.jpg" />
            <div className="card-body">
              <h3 className="card-title">
                kerfed.com: Multi-Shop Optimization
              </h3>
              <h5 className="card-text">
                kerfed.com provides a convenient way to split jobs between
                multiple machine shops. Parts are automatically allocated to the
                cheapest machine for each part across multiple machine shops.
                When orders are placed each part is sent directly to the most
                competitive shop for each part.
              </h5>
            </div>
          </div>
          <div className="card col mx-3">
            <img src="/static/images/vf2.jpg" />
            <div className="card-body">
              <h3 className="card-title">Shop Direct</h3>
              <h5 className="card-text">
                The Kerfed Engine is embedded in the web pages of partner
                machine shops, so if you prefer to deal with a local shop
                directly you can go straight to your preferred shop.
              </h5>
            </div>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md">
            <h2>Pricing</h2>
            <p>
              Parts are quoted by calculating a <b>cycle time</b> for each part
              based on parameters like feed rate curves, plunge times, etc.
              Prices are generated by adding material costs to the cycle time
              multiplied by a <b>work rate</b> for a particular machine. The
              work rate is the fully burdened (labor, capital, operating, etc)
              billable rate of a particular machine in USD per minute.
            </p>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md">
            <h2>Supported Manufacturing Processes</h2>

            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Part Type</th>
                  <th>Typical Machines</th>
                  <th>Part Geometry Restrictions</th>
                  <th>Design Notes</th>
                  <th>Release To Manufacturing Format</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>Flat</b>
                  </td>
                  <td>
                    <a href="https://www.omax.com/omax-waterjet/60120">
                      Omax Waterjet
                    </a>
                    {', '}
                    <a href="https://www.bystronic.com/en/products/laser-cutting-systems/BySprintFiber.php">
                      Bystronic Fiber Laser
                    </a>
                    {', '}
                    <a href="https://www.epiloglaser.com/laser-machines/legend-laser-series.htm">
                      CO2 Laser
                    </a>
                    {', '}
                    <a href="https://lagunatools.com/cnc-plasma-cutter/cutmaster-level-2-8/">
                      CNC Plasma Cutter
                    </a>
                  </td>
                  <td>
                    Flat parts must be smaller than
                    60&rdquo;x120&rdquo;x4&rdquo; , with a minimum radius of
                    0.01&rdquo; &nbsp;and minimum hole size of 0.050&rdquo;
                  </td>
                  <td>
                    Parts less than 0.25&rdquo; thick can usually be cut on a
                    fiber laser, which is roughly 10x faster than a waterjet.
                  </td>
                  <td>
                    DXF files, one file per material thickness nested with
                    ordered quantities
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Bent</b>
                  </td>
                  <td>
                    <a href="https://www.amada.com/america/down-acting-hg-5020">
                      Amada CNC Press Brake
                    </a>
                  </td>
                  <td>
                    Part thickness must be 0.375&rdquo; &nbsp;or less. Bends
                    must be cylindrical and under 8&rsquo; long and between
                    0.06&rdquo; and 2&rdquo; in diameter. Flanges must be 3
                    times thickness or longer.
                  </td>
                  <td>
                    To ensure bendable geometry, bent parts must &nbsp;be
                    designed using the sheet metal tools package in your CAD
                    system. Assemblies containing PEM nuts will automatically
                    detect and add the nut installation.
                  </td>
                  <td>
                    Nested flat pattern DXF in correct quantities; separate per-
                    part file including bend lines (K- factors set in shop
                    parameters)
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Rolled</b>
                  </td>
                  <td>
                    <a href="https://www.elitemetaltools.com/tool-shop/products/baileigh-plate-roll-pr-403?sku%3DPR-403">
                      Plate Roller
                    </a>
                  </td>
                  <td>
                    0.060&rdquo; thick material or less has a 1&rdquo; minimum
                    internal radius, 36&rdquo; &nbsp;max height, 0.060&rdquo;-
                    0.1875&rdquo; thick has a 5.5&rdquo; minimum radius,
                    48&rdquo; &nbsp;max height
                  </td>
                  <td>
                    Holes should generally be smaller than roughly half of the
                    rolled part radius.{' '}
                    <a href="https://www.youtube.com/watch?v%3DgmapHqguNJo">
                      Example video
                    </a>
                  </td>
                  <td>
                    Cuttable DXF flat pattern; shop drawings indicating outer
                    diameter, quantity, welds, and finishes.
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Turned</b>
                  </td>
                  <td>
                    <a href="https://www.haascnc.com/machines/lathes/st/models/standard/st-15.html">
                      Haas ST15 Lathe
                    </a>
                  </td>
                  <td>
                    All outer profile features accessible radially with
                    trapezoidal tool, and all inner profile features accessible
                    axially.
                  </td>
                  <td>
                    Keeping outer shaft close to a stock size can reduce costs
                    considerably due to shorter cycle times.
                  </td>
                  <td>
                    Operations list, DXF file of side profile, and oriented
                    original model.
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>CNC Milled</b>
                  </td>
                  <td>
                    <a href="https://www.fanucamerica.com/products/fanuc-robodrill-robomachine">
                      Fanuc Robodrill
                    </a>
                  </td>
                  <td>
                    Milled parts consisting of pockets aligned with clampable
                    faces.
                  </td>
                  <td>
                    All features should be representable as extruded cuts.
                    Fillets and chamfers on the bottom of pockets are generally
                    to be avoided.
                  </td>
                  <td>
                    Operations list and an oriented model for CAM programming.
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Additive</b>
                  </td>
                  <td>
                    <a href="https://formlabs.com/3d-printers/form-3/">
                      Formlabs Form3
                    </a>
                  </td>
                  <td>Additive processes are also known as 3D printing.</td>
                  <td>Thin walls are generally to be avoided.</td>
                  <td>
                    Packed AMF or STL files with parts oriented correctly.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="segmentRectangle" />
      </div>
    </div>
  );
}

export function Privacy(props: any) {
  return <Article articleUrl="/static/content/privacy.md" />;
}

export function Technology(props: any) {
  return (
    <div className="container-sm bg-white p-3">
      <h1 className="my-3">Technology</h1>
      <video
        width="100%"
        height="100%"
        autoPlay={true}
        loop={true}
        muted={true}
      >
        <source src="/static/images/tech.mp4" type="video/mp4" />
      </video>
      <h2>Kerfed Engine</h2>
      <p>
        The Kerfed Engine is a software system which turns 3D CAD models of
        mechanical assemblies to instant quotes, and orders to parts.
      </p>
      <p>
        The goal is to build a "hardware abstraction layer" which automatically
        handles all of the intermediate steps between data and parts, such as
        clicking on edge loops in CAM systems, packing DXF files, and countless
        other easily automated tasks.
      </p>
      <p>
        Kerfed’s spiritual predecessor is{' '}
        <a href="https://www.darpa.mil/program/adaptive-vehicle-make">
          DARPA's AVM/iFAB
        </a>
        . One of the insights behind the program was that rather than aspiring
        to replace every process with slice-based 3D printing techniques we
        should create a "hardware abstraction layer". This layer could divide
        parts between different fabrication processes and suppliers. At the
        time, iFAB required a lot of manual part annotation which made the dream
        of “3D model in, HMMWV out” include an intermediate step where each part
        needed substantial amounts of engineering time.
      </p>
      <p>
        The Kerfed Engine was developed by alumni of CMU's branch of the DARPA
        AVM program. Over the years since, we have developed the Kerfed Engine,
        an automated, robust and performant product. The system today handles
        flat and bent parts and identifies commercial bearings, pins, washers,
        and spacers in assemblies.
      </p>
      <p>
        Our goal is to add processes and increase the breadth of the COTS
        databases such that even complicated assemblies (i.e. a rocket, iPhone,
        or toaster) can be sourced in under a minute.
      </p>

      <table className="table table-bordered">
        <tr>
          <td>
            <h2>Flat Parts</h2>
            <p>
              A flat part is a 2D profile extruded by 0.02” to 2.00”. These
              parts can be quickly and precisely made by many great methods,
              including waterjets and laser cutters.
            </p>
          </td>
          <td>
            <img className="img-fluid" src="/static/images/rend_flat.png" />
          </td>
        </tr>
        <tr>
          <td>
            <h2>Bent Parts</h2>
            <p>
              A bent sheet metal part consists of flat sections connected by
              bend lines. Modern CNC press brakes in good hands can produce
              these quickly with no custom tooling required.
            </p>
          </td>
          <td>
            <img className="img-fluid" src="/static/images/rend_bent.png" />
          </td>
        </tr>
        <tr>
          <td>
            <h2>Slip Rolled Parts</h2>
            <p>
              Slip or plate rolling is a common sheet metal operation where
              parts are designed as thin-wall partial cylinders with a seam as
              small as 0.5 degrees, in material up to 3/16" thick.
            </p>
          </td>
          <td>
            <img className="img-fluid" src="/static/images/rend_roll.png" />
          </td>
        </tr>

        <tr>
          <td>
            <h2>Turned Parts</h2>
            <p>
              Single-fixture turning is any part which can be fixtured in a
              lathe and then run in a single pass on a CNC lathe. This reduces
              cost and increases precision.
            </p>
          </td>
          <td>
            <img className="img-fluid" src="/static/images/rend_turn.png" />
          </td>
        </tr>
        <tr>
          <td>
            <h2>COTS Parts</h2>
            <p>
              The Kerfed Engine detects ball bearings and dowel pins to auto-
              tolerance holes, and provides part numbers and quantities from the
              assembly.
            </p>
          </td>
          <td>
            <img className="img-fluid" src="/static/images/rend_cots.png" />
          </td>
        </tr>
      </table>

      <div className="segmentRectangle" />
    </div>
  );
}
