import { PayloadAction } from '@reduxjs/toolkit';

import { ShopState } from './types';

export const shopUpdate = (
  state: ShopState,
  action: PayloadAction<{ shopId: string; shop: Components.Schemas.Shop }>,
) => {
  const { shopId, shop } = action.payload;
  state[shopId] = shop;
};

export const shopRemove = (
  state: ShopState,
  action: PayloadAction<{ shopId: string }>,
) => {
  const { shopId } = action.payload;
  delete state[shopId];
};
