import { batch } from 'react-redux';

import { debounce, generateId } from '@kerfed/common/utils';
import { computeTotalPrice } from '@kerfed/common/pricing';
import { actions } from '.';
import { actions as orderActions } from '../order';
import { RootState, AppDispatch } from '..';
import * as api from '../../api';
import {
  getParts,
  getQuote,
  getQuoteComputed,
  makeGetComputedMethodOptions,
  makeGetComputedPartOptions,
  makeGetComputedQuoteOptions,
} from './selectors';
import { PartSpecification } from '@kerfed/common/schemas/part';

const DISCOUNT_CODE_LENGTH = 9;

export const quoteUpdate = actions.quoteUpdate;
export const fileUpdate = actions.fileUpdate;
export const optionsNoteSet = actions.optionsNoteSet;

type OrderCreateProps = {
  quoteId: string;
  message?: string;
  emails?: string[];
};

export const orderCreate = ({
  quoteId,
  emails,
  message,
}: OrderCreateProps) => async (
  dispatch: AppDispatch,
  getState: () => RootState,
): Promise<string | undefined> => {
  const getQuoteOptions = makeGetComputedQuoteOptions();
  const getPartOptions = makeGetComputedPartOptions();
  const getMethodOptions = makeGetComputedMethodOptions();
  const nonce = generateId();

  // Indicate that ordering has started.
  dispatch(
    actions.orderingStart({
      quoteId,
      nonce,
    }),
  );

  // Construct placeholder structures for arguments.
  const initialState = getState();
  const options = {
    ...getQuoteOptions(initialState, { quoteId }),
    parts: {},
  };

  // Search all specified parts to construct part options.
  for (const partId of Object.keys(getParts(initialState, { quoteId }) || {})) {
    const partOptions = getPartOptions(initialState, { quoteId, partId });
    if (!partOptions?.quantity) continue;
    if (!partOptions?.methodId) {
      console.warn(`Part ${partId} spec computed while method ID was empty.`);
      continue;
    }

    const { methodId } = partOptions;
    const methodOptions = getMethodOptions(initialState, {
      quoteId,
      partId,
      methodId,
    });

    options.parts[partId] = {
      ...partOptions,
      ...methodOptions,
    };
  }

  try {
    // Perform the order creation API call.
    const order = await api.orderCreate({
      ...options,
      quoteId,
      emails,
      message,
    });

    batch(() => {
      // Load the resulting order into the state.
      dispatch(
        orderActions.orderUpdate({
          orderId: order.id,
          order,
        }),
      );

      // Update the result in the quote (indicating the creation completed).
      dispatch(
        actions.orderingEnd({
          quoteId,
          nonce,
        }),
      );
    });

    return order.id;
  } catch (err) {
    // Update the result in the quote (indicating the creation failed).
    console.warn(err);
    const error = err?.toString ? err.toString() : 'Unknown ordering error.';
    dispatch(
      actions.orderingEnd({
        quoteId,
        nonce,
        error,
      }),
    );
    return undefined;
  }
};

export const partUpdate = ({ quoteId, partId, part }) => async (
  dispatch: AppDispatch,
) => {
  dispatch(actions.partUpdate({ quoteId, partId, part }));
  await dispatch(priceUpdate({ quoteId }));
};

export const priceUpdate = ({ quoteId }) => async (
  dispatch: AppDispatch,
  getState: () => RootState,
) => {
  const getPartOptions = makeGetComputedPartOptions();
  const initialState = getState();
  const quote = getQuote(initialState, { quoteId });
  const discount = getQuoteComputed(initialState, { quoteId })?.discount;

  if (!quote) {
    return;
  }
  // Extract all part options.
  const partOptions = {} as {
    [partId: string]: Components.Schemas.PartOptions;
  };
  for (const partId of Object.keys(quote?.parts || {})) {
    const partOption = getPartOptions(initialState, { quoteId, partId });
    if (partOption && partOption.quantity) partOptions[partId] = partOption;
  }

  // Extract price from all the parts.
  const specs = [] as PartSpecification[];
  // Iterate over all parts and collate unit pricing.
  for (const [partId, partOption] of Object.entries(partOptions)) {
    specs.push(quote?.specs?.[partId]?.[partOption.methodId || '']);
  }

  // Compute the total price.
  const price = computeTotalPrice(specs, discount);

  // Update the result in the quote.
  dispatch(actions.priceUpdate({ quoteId, price }));
};

export const specUpdate = ({
  quoteId,
  partIds,
}: {
  quoteId: string;
  partIds: string[];
}) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const getQuoteOptions = makeGetComputedQuoteOptions();
  const getPartOptions = makeGetComputedPartOptions();
  const getMethodOptions = makeGetComputedMethodOptions();

  // Snapshot initial state and create a nonce to track this call.
  const initialState = getState();
  const nonce = generateId();

  // Construct placeholder structures for arguments.
  const methodIds = {};
  const options = {
    ...getQuoteOptions(initialState, { quoteId }),
    parts: {},
  };

  // Search all specified parts to construct part options.
  for (const partId of partIds) {
    const partOptions = getPartOptions(initialState, { quoteId, partId });
    if (!partOptions?.methodId) {
      console.warn(`Part ${partId} spec computed while method ID was empty.`);
      continue;
    }

    const { methodId } = partOptions;
    const methodOptions = getMethodOptions(initialState, {
      quoteId,
      partId,
      methodId,
    });

    dispatch(
      actions.specStart({
        quoteId,
        partId,
        methodId,
        nonce,
      }),
    );

    options.parts[partId] = {
      ...partOptions,
      ...methodOptions,
    };

    methodIds[partId] = methodId;
  }

  try {
    // Query the pricing for this set of parts.
    // TODO: Debounce this API call to avoid DDOS.
    const spec = await api.quotePrice(quoteId, options);

    // Set all of the results via reducer.
    for (const [partId, partSpec] of Object.entries(spec)) {
      const methodId = methodIds[partId];
      batch(() =>
        dispatch(
          actions.specFinish({
            quoteId,
            partId,
            methodId,
            nonce,
            spec: partSpec,
          }),
        ),
      );
    }
  } catch {
    // Clear all of the results via reducer.
    for (const partId of Object.keys(options.parts)) {
      const methodId = methodIds[partId];
      batch(() =>
        dispatch(
          actions.specFinish({
            quoteId,
            partId,
            methodId,
            nonce,
            spec: undefined,
          }),
        ),
      );
    }
  }

  await dispatch(priceUpdate({ quoteId }));
};

// Create a scoped debounced version of this function to call.
const shopDiscountDebounced = debounce(api.shopDiscount);

export const discountUpdate = ({
  quoteId,
  shopId,
  discountId: rawDiscountId,
}) => async (dispatch: AppDispatch) => {
  // Sanitize the discount code string (uppercase, bounded length).
  const discountId = rawDiscountId
    .slice(0, DISCOUNT_CODE_LENGTH)
    .toLocaleUpperCase();

  // If this is not a full discount code, just set the discount
  // code and clear any existing discount.
  if (discountId.length != DISCOUNT_CODE_LENGTH) {
    // Set the discount code and clear any existing discount.
    dispatch(
      actions.discountClear({
        quoteId,
        discountId,
      }),
    );
    return;
  }

  // Perform a debounced API call to lookup this discount ID.
  const nonce = generateId();
  dispatch(
    actions.discountStart({
      quoteId,
      discountId,
      nonce,
    }),
  );

  try {
    // Query the API server to see if this is a valid code.
    const { discountValue } = await shopDiscountDebounced(shopId, {
      discountId,
    });

    // Set the resulting discount on the store.
    dispatch(
      actions.discountEnd({
        quoteId,
        discount: discountValue || 0.0,
        nonce,
      }),
    );
  } catch {
    // Clear the resulting discount on the store.
    dispatch(
      actions.discountEnd({
        quoteId,
        discount: 0.0,
        nonce,
      }),
    );
  }
};

export const optionsExpediteSet = ({ quoteId, expediteId }) => async (
  dispatch: AppDispatch,
  getState: () => RootState,
) => {
  dispatch(actions.optionsExpediteSet({ quoteId, expediteId }));

  const parts = await getParts(getState(), { quoteId });
  if (!parts) return;

  const partIds = Object.keys(parts);
  if (!partIds.length) return;

  await dispatch(specUpdate({ quoteId, partIds }));
};

export const optionsCsmSet = ({ quoteId, partId, isCsm }) => async (
  dispatch: AppDispatch,
) => {
  dispatch(actions.optionsCsmSet({ quoteId, partId, isCsm }));
  await dispatch(specUpdate({ quoteId, partIds: [partId] }));
};

export const optionsPostSet = ({
  quoteId,
  partId,
  methodId,
  selections,
}) => async (dispatch: AppDispatch) => {
  dispatch(actions.optionsPostSet({ quoteId, partId, methodId, selections }));
  await dispatch(specUpdate({ quoteId, partIds: [partId] }));
};

export const optionsProcessSet = ({
  quoteId,
  partId,
  methodId,
  selections,
}) => async (dispatch: AppDispatch) => {
  dispatch(
    actions.optionsProcessSet({
      quoteId,
      partId,
      methodId,
      selections,
    }),
  );

  await dispatch(specUpdate({ quoteId, partIds: [partId] }));
};

export const optionsQuantitySet = ({ quoteId, partId, quantity }) => async (
  dispatch: AppDispatch,
) => {
  dispatch(actions.optionsQuantitySet({ quoteId, partId, quantity }));
  await dispatch(specUpdate({ quoteId, partIds: [partId] }));
};

export const optionsMethodSet = ({ quoteId, partId, methodId }) => async (
  dispatch: AppDispatch,
) => {
  dispatch(actions.optionsMethodSet({ quoteId, partId, methodId }));
  await dispatch(priceUpdate({ quoteId }));
};
