import '@kerfed/common-ui/assets/helpers.css';
import { FormattedId } from '@kerfed/common-ui/components';
import Explainer from '@kerfed/common-ui/components/Explainer';
import FileImage from '@kerfed/common-ui/components/FileImage';
import { formatUuid } from '@kerfed/common/utils';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RootState } from '../store';
import { optionsQuantitySet } from '../store/quote/actions';
import { getPart, makeGetComputedPartOptions } from '../store/quote/selectors';
import EditablePartMethodSelector from './EditablePartMethodSelector';

type Props = {
  unfinished?: boolean;
  quoteId: string;
  partId: string;
  part: Components.Schemas.QuotePart;
  partOptions: Components.Schemas.PartOptions;
  onOptionsQuantitySet: typeof optionsQuantitySet;
};

type Detection = {
  fileId: string;
  node: string;
};

const DetectionsHelper = ({ detections }: { detections: Detection[] }) => {
  const detectionsMap = detections.reduce((acc, { fileId }) => {
    return {
      ...acc,
      [fileId]: (acc[fileId] || 0) + 1,
    };
  }, {} as { [fileId: string]: number });

  const explanation = Object.entries(detectionsMap)
    .sort()
    .map(
      ([fileId, quantity]) => `${quantity} found in FID ${formatUuid(fileId)}`,
    )
    .join('\n');

  return (
    <Explainer explanation={explanation}>
      <b>{detections.length}</b> detected in assembly
    </Explainer>
  );
};

const PartHeader = (props: Props) => {
  const { partId, quoteId, part, unfinished } = props;

  return (
    <div className="grid p-auto">
      <div className="row">
        <div className="col-md-9">
          <div>
            <h2>{part?.name || 'Unnamed Part'}</h2>
            <div className="id-text">
              <Explainer
                explanation={
                  'GPN is the Geometric Part Number, which may be referenced in drawings and invoices.'
                }
              >
                GPN:&nbsp;
                <FormattedId id={partId} />
              </Explainer>
            </div>
            {!unfinished && (
              <DetectionsHelper detections={part?.detections || []} />
            )}
          </div>
        </div>
        <div className="col-md-3 align-right">
          <FileImage url={part?.previews?.png} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <EditablePartMethodSelector quoteId={quoteId} partId={partId} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = () => {
  const getPartOptions = makeGetComputedPartOptions();

  return (state: RootState, props: Props) => {
    const partOptions = getPartOptions(state, { ...props });

    const part = getPart(state, props);
    return {
      part,
      partOptions,
    };
  };
};

const mapDispatchToProps = {
  onOptionsQuantitySet: optionsQuantitySet,
};

export default compose<Props, { quoteId: string; partId: string }>(
  connect(mapStateToProps, mapDispatchToProps),
)(PartHeader);
