import React from 'react';
import moment from 'moment';

import { formatUuid } from '@kerfed/common/utils';

/// Render a formatted ID in a consistent way.
export const FormattedId: React.FC<{ id?: string }> = ({ id, ...props }) => {
  return <span {...props}>{formatUuid(id)}</span>;
};

/// Render a formatted datetime in a consistent way.
export const FormattedDate: React.FC<{ date?: string | Date }> = ({
  date,
  ...props
}) => {
  if (!date) return <span {...props}>(unknown)</span>;

  const formatString = 'MMMM Do YYYY, h:mm:ss a';
  return <span {...props}>{moment(date).format(formatString)}</span>;
};
