import React from 'react';
import { Link } from 'react-router-dom';

import creditcards from 'url:../images/credit.svg';
import logo from 'url:../images/logo_white.svg';
import {
  FaTwitter,
  FaGithub,
  FaFacebookSquare,
  FaYoutube,
} from 'react-icons/fa';

import '../styles/Footer.scss';

export default () => (
  <footer className="footer navbar-inverse" role="contentinfo">
    <div className="container my-3">
      <div className="row">
        <div className="col-sm-3 py-5">
          <img src={logo} className="img-fluid" />
        </div>
      </div>
      <div className="row">
        <div className="col-md">
          <ul>
            <li>
              <h3>Support</h3>
            </li>
            <li>(412) 532-9813</li>
            <li>
              <a href="mailto:support@kerfed.com" style={{ color: 'white' }}>
                support@kerfed.com&nbsp;
              </a>
            </li>
          </ul>
        </div>

        <div className="col-md">
          <ul>
            <li>
              <h3>Mechanical</h3>
            </li>
            <li>
              <a href="https://blog.kerfed.com" target="_blank">
                Kerfed Blog
              </a>
            </li>
            <li>
              <Link to={`/design`}>Mechanical Design</Link>
            </li>
            <li>
              <Link to={`/pricing`}>Processes Supported</Link>
            </li>
            <li>
              <Link to={`/tolerances`}>Mechanical Tolerances</Link>
            </li>
          </ul>
        </div>

        <div className="col-md">
          <ul>
            <li>
              <h3>Corporate</h3>
            </li>
            <li>
              <Link to={`/returns`}>Returns</Link>
            </li>
            <li>
              <Link to={`/technology`}>Technology</Link>
            </li>
            <li>
              <Link to={`/about`}>About Kerfed</Link>
            </li>
            <li>
              <Link to={`/privacy`}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={`/terms`}>Terms and Conditions</Link>
            </li>
            <li>
              <Link to={`/onboard`}>Machine Shop Signup</Link>
            </li>
          </ul>
        </div>

        <div className="col-md">
          <ul>
            <li>
              <h3>Software Developers</h3>
            </li>
            <li>
              <Link to={`/account`}>API Keys</Link>
            </li>
            <li>
              <a href="https://api.kerfed.com" target="_blank">
                API Overview
              </a>
            </li>
            <li>
              <a href="https://api.kerfed.com/docs/v1" target="_blank">
                API Reference
              </a>
            </li>

            <li>
              <a
                href="https://github.com/kerfed/kerfed-api-examples"
                target="_blank"
              >
                API Examples On Github
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-md align-self-center align-center py-3">
          <a target="_blank" href="https://twitter.com/kerfedcom/">
            <FaTwitter className="brand-icon" />
          </a>
          <a target="_blank" href="https://fb.me/kerfedcom">
            <FaFacebookSquare className="brand-icon" />
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UC7OHZbvYxw_tYA548uM31rA"
          >
            <FaYoutube className="brand-icon" />
          </a>
          <a target="_blank" href="https://github.com/kerfed/">
            <FaGithub className="brand-icon" />
          </a>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-4" />
        <div className="col-sm-4 align-center py-3">
          <img src={creditcards} className="img-fluid" />
        </div>
      </div>

      <div className="row">
        <div className="col-md align-self-center align-center">
          &copy; 2019 Kerfed, Inc. &nbsp;&middot;&nbsp; 6425 Living Place #2061
          Pittsburgh PA
        </div>
      </div>
    </div>
  </footer>
);
