import React, { useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { actions } from './index';
import * as api from '../../api';

type Props = {
  orderId?: string;
};

const WithOrder = React.memo((props: Props) => {
  const { orderId } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  // Create a periodic subscription to quote updates.
  // TODO(PV): Make this periodic.
  useEffect(() => {
    if (!orderId) return;
    let dateSince: Date | undefined;
    let cancelId: number | undefined;

    const update = async () => {
      try {
        const nextDateSince = new Date(Date.now());
        const order = await api.orderGet(orderId, dateSince);
        if (!order) return;
        dispatch(
          actions.orderUpdate({
            orderId,
            order,
          }),
        );
        dateSince = nextDateSince;
        cancelId = window.setTimeout(update, 57000);
      } catch (e) {
        if (e == 401 || e == 404) {
          // permission denied or it doesn't exist
          window.clearTimeout(cancelId);
          history.push('/404');
        } else {
          console.error(e);
          dispatch(
            actions.orderRemove({
              orderId,
            }),
          );
          dateSince = undefined;
          cancelId = window.setTimeout(update, 11000);
        }
      }
    };

    cancelId = window.setTimeout(update, 0);
    return () => window.clearTimeout(cancelId);
  });

  // Create a periodic subscription to the list of parts.
  useEffect(() => {
    if (!orderId) return;
    let dateSince: Date | undefined;
    let cancelId: number | undefined;
    let isCancelled = false;

    const update = async () => {
      try {
        const nextDateSince = new Date(Date.now());
        const parts = await api.orderPartList(orderId, 0, 100);
        if (isCancelled) return;

        batch(() => {
          for (const part of parts?.items || []) {
            dispatch(
              actions.partUpdate({
                orderId,
                partId: part.id,
                part,
              }),
            );
          }
        });

        dateSince = nextDateSince;
        cancelId = window.setTimeout(update, 61000);
      } catch (e) {
        console.error(e);
        dateSince = undefined;
        cancelId = window.setTimeout(update, 13000);
      }
    };

    cancelId = window.setTimeout(update, 0);
    return () => {
      isCancelled = false;
      window.clearTimeout(cancelId);
    };
  });

  return null;
});

export default Component => <T extends Props>(props: T) => (
  <div>
    <Component {...props} />
    <WithOrder orderId={props.orderId} />
  </div>
);
