import { auth, googleProvider } from '@kerfed/common/api/firebase';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/js/dist/dropdown';
import '../styles/UserButton.scss';

type LoginButtonProps = {
  onLogin: () => void;
};

const LoginButton = ({ onLogin }: LoginButtonProps) => (
  <button
    type="button"
    className="user-button btn btn-outline-light"
    onClick={onLogin}
  >
    Log in
  </button>
);

type ProfileMenuProps = {
  onLogout: () => void;
  user: firebase.User;
};

// TODO: This requires further cleanup later.
const ProfileMenu = ({ user, onLogout }: ProfileMenuProps) => (
  <div className="dropdown">
    <button
      className="user-button btn btn-outline-light dropdown-toggle"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {user.displayName || user.email || 'Unknown'}
    </button>
    <div className="dropdown-menu bg-dark" aria-labelledby="dropdownMenuButton">
      <Link to="/quotes" className="dropdown-item">
        My Quotes
      </Link>
      <Link to="/orders" className="dropdown-item">
        My Orders
      </Link>
      <Link to="/account" className="dropdown-item">
        My Account
      </Link>
      <a onClick={onLogout} href="#" className="dropdown-item text-danger">
        Log Out
      </a>
    </div>
  </div>
);

const onLogin = () => {
  auth.signInWithRedirect(googleProvider);
};

const onLogout = () => {
  auth.signOut();
};

export const UserButton = () => {
  const [user, setUser] = useState<firebase.User | null>(null);

  useEffect(() => {
    // Set up handler that updates when user login status changes.
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user && user.isAnonymous === false) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  if (user) {
    return <ProfileMenu onLogout={onLogout} user={user} />;
  } else {
    return <LoginButton onLogin={onLogin} />;
  }
};

export default UserButton;
