import React from 'react';

/**
 * Display a block showing a formatted message
 *
 * @param body text for body of message blob.
 * @param heading text for heading of message
 * @param kind bootstrap alert class, i.e. 'warning', 'primary', etc
 * @returns
 */
export const Message = (
  body: string,
  heading?: string,
  kind: string = 'danger',
) => (
  <div className={`alert alert-${kind} rounded-0`} role="alert">
    {heading ? <h2 className="alert-heading">{heading}</h2> : null}
    {body ? <p>{body}</p> : null}
  </div>
);
