import React from 'react';

/**
 * Display a human-readable explanation for a piece of text as a popup.
 * Uses click instead of hover semantics because it is mobile-friendly.
 */
export const Explainer = ({ children, explanation }) => (
  <span
    style={{ textDecoration: 'underline dotted', cursor: 'pointer' }}
    title={explanation}
  >
    {children}
  </span>
);

export default Explainer;
