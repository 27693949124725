import '@kerfed/common-ui/assets/helpers.css';
import FileImage from '@kerfed/common-ui/components/FileImage';
import { OptionsDefinition } from '@kerfed/common/options';
import React, { useMemo } from 'react';
import { MdCheckCircle, MdError } from 'react-icons/md';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';

import { RootState, useSelector } from '../store';
import * as actions from '../store/quote/actions';
import {
  getMethod,
  getPart,
  getQuote,
  makeGetComputedMethodOptions,
  makeGetComputedPartOptions,
} from '../store/quote/selectors';
import { getShop } from '../store/shop/selectors';
import EditablePartConfiguration from './EditablePartConfiguration';
import NoteSection from './NoteSection';
import PartHeader from './PartHeader';

type Props = {
  quoteId: string;
  shopId: string;
  partId: string;
  methodId: string;
  shop: Components.Schemas.Shop;
  part: Components.Schemas.QuotePart;
  partOptions: Components.Schemas.PartOptions;
  method: Components.Schemas.MethodBase;
  methodOptions: Components.Schemas.MethodOptions;
  pricing: OptionsDefinition;
  onOptionsProcessSet: typeof actions.optionsProcessSet;
};

const CustomerSuppliedCheckbox = ({ quoteId, partId }) => {
  const dispatch = useDispatch();
  const getPartOptions = useMemo(makeGetComputedPartOptions, []);

  const isCsm = useSelector(
    state =>
      getPartOptions(state, { quoteId, partId })?.isCustomerSuppliedMaterial,
  );

  const onChange = () =>
    dispatch(actions.optionsCsmSet({ quoteId, partId, isCsm: !isCsm }));

  return (
    <div className="checkbox-group">
      <input
        type="checkbox"
        name="customerSupplied"
        onChange={onChange}
        checked={!!isCsm}
      />
      <label onClick={onChange}>Customer Will Supply Material</label>
      {isCsm && (
        <p>
          <i>
            <b>Customer Supplied Material:</b> Customer responsible for shipping
            to shop, and lead time will start after arrival.
          </i>
        </p>
      )}
    </div>
  );
};

const CustomerNoteSection = ({ quoteId, partId }) => {
  const dispatch = useDispatch();
  const getPartOptions = useMemo(makeGetComputedPartOptions, []);

  const note = useSelector(
    state => getPartOptions(state, { quoteId, partId })?.note,
  );

  const onNoteChange = note =>
    dispatch(actions.optionsNoteSet({ quoteId, partId, note }));

  return <NoteSection note={note} onNoteChange={onNoteChange} />;
};

interface PropsFlat extends Props {
  method: Components.Schemas.MethodFlat;
}

export const EditableFlatPart = ({
  quoteId,
  partId,
  part,
  method,
  shop,
}: PropsFlat) => (
  <div>
    <PartHeader partId={partId} quoteId={quoteId} />
    <div className="grid">
      <div className="row">
        <div className="col-md">
          {part?.obb ? (
            <span className="badge">
              {part.obb[0].toFixed(2)}" x {part.obb[1].toFixed(2)}"{' '}
              {method?.thickness
                ? ' x ' + method?.thickness.toFixed(2) + '"'
                : null}
            </span>
          ) : null}
          <EditablePartConfiguration quoteId={quoteId} partId={partId} />
          {shop?.settings?.isCustomerMaterialAllowed ? (
            <CustomerSuppliedCheckbox quoteId={quoteId} partId={partId} />
          ) : null}
          <CustomerNoteSection quoteId={quoteId} partId={partId} />
        </div>
        <div className="col-md">
          {method?.drawings?.svg && <FileImage url={method.drawings.svg} />}
        </div>
      </div>
    </div>
  </div>
);

interface PropsRoll extends Props {
  method: Components.Schemas.MethodRoll;
}

export const EditableRollPart = ({
  quoteId,
  partId,
  part,
  method,
  shop,
}: PropsRoll) => (
  <div>
    <PartHeader partId={partId} quoteId={quoteId} />
    <div className="grid">
      <div className="row">
        <div className="col-md">
          <span>
            {method?.thickness ? (
              <span className="badge">
                {method.thickness.toFixed(3)}" Thick
              </span>
            ) : null}
            {method?.diameter && method?.height ? (
              <span className="badge">
                &#8709;{method.diameter.toFixed(2)}" x{' '}
                {method.height.toFixed(2)}"
              </span>
            ) : null}
          </span>
          <EditablePartConfiguration quoteId={quoteId} partId={partId} />
          {shop?.settings?.isCustomerMaterialAllowed ? (
            <CustomerSuppliedCheckbox quoteId={quoteId} partId={partId} />
          ) : null}
          <CustomerNoteSection quoteId={quoteId} partId={partId} />
        </div>
        <div className="col-md">
          {method?.drawings?.svg && <FileImage url={method.drawings.svg} />}
        </div>
      </div>
    </div>
  </div>
);

interface PropsAdd extends Props {
  method: Components.Schemas.MethodAdd;
}

export const EditableAddPart = ({
  quoteId,
  partId,
  method,
  part,
}: PropsAdd) => (
  <div>
    <PartHeader partId={partId} quoteId={quoteId} />
    <div className="grid">
      <div className="row">
        <div className="col-md">
          <EditablePartConfiguration quoteId={quoteId} partId={partId} />
          <CustomerNoteSection quoteId={quoteId} partId={partId} />
        </div>
        <div className="col-md">
          {method?.drawings?.svg && <FileImage url={method.drawings.svg} />}
        </div>
      </div>
    </div>
  </div>
);

interface PropsTurn extends Props {
  method: Components.Schemas.MethodTurn;
}

export const EditableTurnPart = ({
  quoteId,
  partId,
  part,
  method,
  shop,
}: PropsTurn) => (
  <div>
    <PartHeader partId={partId} quoteId={quoteId} />
    <div className="grid">
      <div className="row">
        <div className="col-md">
          {method?.diameter && method?.height ? (
            <span className="badge">
              &#8709;{method.diameter.toFixed(3)}" x {method.height.toFixed(3)}"
            </span>
          ) : null}
          <EditablePartConfiguration quoteId={quoteId} partId={partId} />
          {shop?.settings?.isCustomerMaterialAllowed ? (
            <CustomerSuppliedCheckbox quoteId={quoteId} partId={partId} />
          ) : null}
          <CustomerNoteSection quoteId={quoteId} partId={partId} />
        </div>
        <div className="col-md">
          {method?.drawings?.svg && <FileImage url={method.drawings.svg} />}
        </div>
      </div>
    </div>
  </div>
);

interface PropsMill extends Props {
  method: Components.Schemas.MethodMill;
}

export const EditableMillPart = ({
  quoteId,
  partId,
  part,
  method,
  shop,
}: PropsMill) => (
  <div>
    <PartHeader partId={partId} quoteId={quoteId} />
    <div className="grid">
      <div className="row">
        <div className="col-md">
          <EditablePartConfiguration quoteId={quoteId} partId={partId} />
          {shop?.settings?.isCustomerMaterialAllowed ? (
            <CustomerSuppliedCheckbox quoteId={quoteId} partId={partId} />
          ) : null}
          <CustomerNoteSection quoteId={quoteId} partId={partId} />
        </div>
        <div className="col-md">
          {method?.drawings?.svg && <FileImage url={method.drawings.svg} />}

          {(method?.areaRatio || 0) < 0.96 ? (
            <p style={{ marginTop: '.75em' }}>
              <MdError style={{ color: 'red' }} /> Only{' '}
              {((method.areaRatio || 0) * 100).toFixed(1)}% of part area is
              cuttable, check 3D view to see problem areas highlighted.
            </p>
          ) : (
            <p style={{ marginTop: '.75em' }}>
              <MdCheckCircle style={{ color: 'green' }} />
              {((method?.areaRatio || 0) * 100).toFixed(1)}% of the part area is
              cuttable.
            </p>
          )}
        </div>
      </div>
    </div>
  </div>
);

interface PropsBent extends Props {
  method: Components.Schemas.MethodBent;
}

export const EditableBentPart = ({
  quoteId,
  partId,
  part,
  method,
  shop,
}: PropsBent) => (
  <div>
    <PartHeader partId={partId} quoteId={quoteId} />
    <div className="grid">
      <div className="row">
        <div className="col-md">
          {part?.obb ? (
            <span className="badge">
              {part.obb[0].toFixed(2)}" x {part.obb[1].toFixed(2)}" x{' '}
              {part.obb[2].toFixed(2)}"
            </span>
          ) : null}
          <EditablePartConfiguration quoteId={quoteId} partId={partId} />
          {shop?.settings?.isCustomerMaterialAllowed ? (
            <CustomerSuppliedCheckbox quoteId={quoteId} partId={partId} />
          ) : null}
          <CustomerNoteSection quoteId={quoteId} partId={partId} />
        </div>
        <div className="col-md">
          {method?.drawings?.svg && <FileImage url={method.drawings.svg} />}
        </div>
      </div>
    </div>
  </div>
);

interface PropsCots extends Props {
  method: Components.Schemas.MethodCots;
}

export const EditableCotsPart = ({
  quoteId,
  partId,
  part,
  methodId,
  method,
  methodOptions,
  onOptionsProcessSet,
}: PropsCots) => {
  // compute the dropdown options using a memo to avoid recomputing
  const matches = method?.matches;
  const dropdown = useMemo(
    () =>
      matches?.map(match => (
        <li key={`process-select-${match.id}`}>
          <a
            style={{ cursor: 'pointer' }}
            className="dropdown-item"
            onClick={() =>
              onOptionsProcessSet({
                quoteId,
                partId,
                methodId,
                selections: { match: match.id, a: console.log({ match }) },
                isComplete: true,
              })
            }
          >
            {match.text || match.id}
          </a>
        </li>
      )),
    [matches],
  );

  // which id of 'matches' is selected
  const selected = methodOptions?.process?.match;
  // compute the text to display in the button
  const text = useMemo(
    () =>
      matches?.find(a => a.id === selected)?.text || selected || 'Select Part',
    [selected, matches],
  );
  return (
    <div>
      <PartHeader partId={partId} quoteId={quoteId} />
      <div className="grid">
        <div className="row">
          <div className="col-md-6">
            <div className="dropdown">
              <button
                className={`btn btn-outline-primary dropdown-toggle my-1 w-100`}
                type="button"
                placeholder="Select Part Number"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {text}
              </button>
              <ul
                className="dropdown-menu w-100"
                aria-labelledby="dropdownMenuButton"
              >
                {dropdown}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface PropsManual extends Props {
  method: Components.Schemas.MethodBase;
}

export const EditableManualPart = ({
  quoteId,
  partId,
  methodId,
  part,
  partOptions,
  shop,
}: PropsManual) => (
  <div>
    <PartHeader partId={partId} quoteId={quoteId} />
    <EditablePartConfiguration quoteId={quoteId} partId={partId} />
  </div>
);

export const UnfinishedPart = ({ partId, quoteId }: Props) => (
  <div>
    <PartHeader partId={partId} quoteId={quoteId} unfinished={true} />
    <div className="loader loader-inline">
      <div className="spinner-border " role="status" />
      <h5>Analyzing Part</h5>
    </div>
    <br />
  </div>
);

export const UnfabPart = ({ partId, quoteId }: Props) => (
  <div>
    <PartHeader partId={partId} quoteId={quoteId} unfinished={true} />
    <p>Unable to automatically quote part.</p>
  </div>
);

const EditablePart = (props: Props) => {
  const { methodId, method, shop } = props;

  const hasMethods = shop?.settings?.hasMethods;
  if (hasMethods && !hasMethods.includes(methodId)) {
    return <UnfabPart {...props} />;
  }
  switch (methodId) {
    case 'cots':
      return (
        <EditableCotsPart
          {...props}
          method={method as Components.Schemas.MethodCots}
        />
      );
    case 'bent':
      return (
        <EditableBentPart
          {...props}
          method={method as Components.Schemas.MethodBent}
        />
      );
    case 'flat':
      return (
        <EditableFlatPart
          {...props}
          method={method as Components.Schemas.MethodFlat}
        />
      );
    case 'mill':
      return (
        <EditableMillPart
          {...props}
          method={method as Components.Schemas.MethodMill}
        />
      );
    case 'roll':
      return (
        <EditableRollPart
          {...props}
          method={method as Components.Schemas.MethodRoll}
        />
      );
    case 'turn':
      return (
        <EditableTurnPart
          {...props}
          method={method as Components.Schemas.MethodTurn}
        />
      );
    case 'add':
      return (
        <EditableAddPart
          {...props}
          method={method as Components.Schemas.MethodAdd}
        />
      );
    case 'manual':
      return (
        <EditableManualPart
          {...props}
          method={method as Components.Schemas.MethodBase}
        />
      );
    default:
      return <UnfinishedPart {...props} />;
  }
};

const mapStateToProps = () => {
  const getPartOptions = makeGetComputedPartOptions();
  const getMethodOptions = makeGetComputedMethodOptions();

  return (state: RootState, props: Props) => {
    const quote = getQuote(state, props);
    const shopId = quote?.content?.shopId || '';
    const shop = getShop(state, { shopId });
    const part = getPart(state, props);
    const partOptions = getPartOptions(state, props);
    const methodId = partOptions?.methodId || '';
    const method = getMethod(state, { ...props, methodId });
    const methodOptions = getMethodOptions(state, {
      ...props,
      methodId,
    });

    return {
      shopId,
      shop,
      part,
      partOptions,
      methodId,
      method,
      methodOptions,
    };
  };
};

const mapDispatchToProps = {
  onOptionsNoteSet: actions.optionsNoteSet,
  onOptionsCsmSet: actions.optionsCsmSet,
  onOptionsProcessSet: actions.optionsProcessSet,
};

export default compose<Props, { quoteId: string; partId: string }>(
  connect(mapStateToProps, mapDispatchToProps),
)(EditablePart);
