import { FormattedId } from '@kerfed/common-ui/components/formatting';
import { EmbedContext } from '@kerfed/common-ui/contexts';
import { htmlToUnicode } from '@kerfed/common/utils';
import React, { useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import * as api from '../api';
import {
  EditablePartList,
  EmailAsk,
  ErrorBoundary,
  FileList,
  LocationAsk,
  OrderEmailButton,
  ShopInfo,
} from '../components';
import { ShareQuoteButton } from '../components/ShareButton';
import { RootState } from '../store';
import withAuthentication from '../store/auth/withAuthentication';
import * as actions from '../store/quote/actions';
import {
  getFiles,
  getQuoteComputed,
  getQuoteContent,
  makeGetComputedQuoteOptions,
} from '../store/quote/selectors';
import { QuoteComputed } from '../store/quote/types';
import withQuoteDetail from '../store/quote/withQuoteDetail';
import withQuoteTotals from '../store/quote/withQuoteTotals';
import { getShop } from '../store/shop/selectors';
import withShopDetail from '../store/shop/withShopDetail';
import { ActionType } from '../store/utils';

interface ExpediteDescription extends Components.Schemas.OptionDescription {
  cycleMax?: number;
}

type ExpediteSliderProps = {
  expediteId: string;
  expediteValues: ExpediteDescription[];
  onExpediteChange(expediteId?: string): any;
};

const ExpediteSlider = ({
  expediteId,
  expediteValues,
  onExpediteChange,
}: ExpediteSliderProps) =>
  expediteValues.length > 1 ? (
    <div className="col-md my-2">
      <span>
        {expediteValues.map(v => (
          <button
            className={
              v.id === expediteId
                ? 'btn btn-secondary'
                : 'btn btn-outline-secondary'
            }
            key={v.id}
            onClick={() => onExpediteChange(v.id)}
          >
            {htmlToUnicode(v.text)}
          </button>
        ))}
      </span>
    </div>
  ) : null;

type DiscountTextBoxProps = {
  discountId: string;
  isDiscounting: boolean;
  onDiscountChange(discountId?: string): any;
  discount?: number;
};

const DiscountTextBox = ({
  discountId,
  isDiscounting,
  onDiscountChange,
  discount,
}: DiscountTextBoxProps) => (
  <div className="input-group">
    <input
      style={{ maxWidth: '14em' }}
      className="form-control"
      placeholder="Enter Discount Code"
      onChange={event => onDiscountChange(event.target.value)}
      value={discountId}
    />
    {discountLabel(discount)}
    {isDiscounting ? (
      <div className="input-group-text">
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      </div>
    ) : null}
  </div>
);

const discountLabel = discount => {
  // If the discount is cleared, it will be undefined.
  if (discount === undefined) return null;
  // If the discount is invalid, it will be exactly zero.
  if (discount == 0.0)
    return <span className="input-group-text failure">Invalid code</span>;
  // Display a label for a valid discount code.
  return (
    <span className="input-group-text success">{`${(discount * 100).toFixed(
      1,
    )}% Off!`}</span>
  );
};

/**
 * An element to display if the cycle time is too large.
 *
 * If the cycle is OK, this won't display anything.
 */
const CycleLabel = ({ duration, expediteData }) => {
  // sum the setup duration and the parts duration
  const cycle = duration || 0.0;
  // max bookable cycle time for a job
  const cycleMax = expediteData?.cycleMaxMin || Number.POSITIVE_INFINITY;
  // is our cycle time less than the specified maximum
  const cycleOk = cycle < cycleMax;

  // TODO: check expediteData.hardLimit
  // and raise an error on order press if (!cycleOk && expediteData.hardLimit)
  if (cycleOk) return null;
  return (
    <span className="badge">
      CAUTION! Total cycle time ({cycle.toFixed(1)} min) longer than maximum for
      expedite level ({cycleMax.toFixed(1)} min)
    </span>
  );
};

// TODO: I think this was fixed in more recent versions of library:
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/17181#issuecomment-362392689
interface OrderButtonProps extends RouteComponentProps<{}> {
  quoteId: string;
  disabled: boolean;
}
const OrderCreateButton = withRouter<
  OrderButtonProps,
  React.FC<OrderButtonProps>
>(({ history, quoteId, disabled }) => {
  const dispatch = useDispatch();

  return (
    <button
      className="btn btn-lg btn-primary"
      disabled={disabled}
      title={
        (disabled &&
          'Not all parts have configured prices! Finish configuring them, or set their quantity to zero to check out.') ||
        ''
      }
      onClick={async () => {
        const orderId = await dispatch(actions.orderCreate({ quoteId }));
        if (orderId) history.push(`/orders/${orderId}`);
      }}
    >
      Check Out Now &#8594;
    </button>
  );
});

type Props = {
  quoteId: string;
  quote: Components.Schemas.Quote;
  quoteComputed: QuoteComputed;
  quoteOptions: Components.Schemas.QuoteOptions;
  files: { [fileId: string]: Components.Schemas.File };
  shopId: string;
  shop: Components.Schemas.Shop;
  expediteId: string;
  onOptionsExpediteSet: ActionType<typeof actions.optionsExpediteSet>;
  onDiscountUpdate: ActionType<typeof actions.discountUpdate>;
  onFileUpdate: typeof actions.fileUpdate;
};

const QuoteEditView = (props: Props) => {
  const { quoteId, shopId } = props;
  const { quoteOptions, quoteComputed, shop, files, expediteId } = props;
  const { onDiscountUpdate, onOptionsExpediteSet, onFileUpdate } = props;
  const { isEmbedded } = React.useContext(EmbedContext);

  // get expedite data
  // TODO: why is this an error if undefined?
  const duration = quoteComputed?.price?.total?.duration;
  const expedite = shop?.options?.expedite?.[expediteId || ''];

  // only ask for email if it has been explicitly set in the shop settings
  const askForEmail = !!shop?.settings?.isEmailRequested;
  //const askForEmail = false;

  const emailList =
    shop.settings && typeof shop.settings.emailList !== 'undefined'
      ? shop.settings.emailList
      : '';

  // Are any of the parts currently still computing price.
  const isComputingPrice = useMemo(() => {
    for (const partComputing of Object.values(
      quoteComputed?.isComputing || {},
    )) {
      for (const methodComputing of Object.values(partComputing || {})) {
        if (methodComputing !== undefined) return true;
      }
    }
    return false;
  }, [quoteComputed]);

  // can we actually check out now
  // TODO: do we need all of these checks?
  const price = quoteComputed?.price?.total;
  const canCheckout = price !== undefined && !isNaN(price) && price > 0;

  const onUploads = async (uploadIds: string[]) =>
    Promise.all(
      uploadIds.map(async uploadId => {
        const file = await api.quoteFileCreate(quoteId, { uploadId });
        onFileUpdate({ quoteId, fileId: file.id, file });
      }),
    );

  return (
    <div
      className={
        isEmbedded
          ? 'container-fluid bg-white py-3'
          : 'container-xl bg-white p-3'
      }
      data-iframe-height="true"
    >
      <LocationAsk shopId={shopId} />

      <ErrorBoundary>
        {isEmbedded ? null : <ShopInfo shopId={shopId} />}
      </ErrorBoundary>
      <div className="grid">
        <div className="row">
          <div className="col-md">
            <h1>
              Quote <FormattedId id={quoteId} />
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <ShareQuoteButton quoteId={quoteId} />
          </div>
        </div>
        {askForEmail && (
          <div className="row">
            <div className="col-md">
              <ErrorBoundary>
                <EmailAsk shopId={shopId} />
              </ErrorBoundary>
            </div>
          </div>
        )}
        <FileList files={files} onUploads={onUploads} />
        <div className="row">
          <div className="col-md">
            <h1>Parts List</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-auto">
            <OrderEmailButton
              isEmphasized={!canCheckout}
              quoteId={quoteId}
              disabled={isComputingPrice}
            />
            <OrderCreateButton
              quoteId={quoteId}
              disabled={isComputingPrice || !canCheckout}
            />
          </div>
        </div>
        <div className="row">
          <ExpediteSlider
            expediteId={quoteOptions?.expediteId || ''}
            expediteValues={shop?.options?.expedite || []}
            onExpediteChange={expediteId =>
              onOptionsExpediteSet({ quoteId, expediteId })
            }
          />
        </div>
        <div className="row py-2">
          <div className="col-md">
            <DiscountTextBox
              discountId={quoteOptions?.discountId || ''}
              isDiscounting={!!quoteComputed?.isDiscounting}
              onDiscountChange={discountId =>
                onDiscountUpdate({ quoteId, shopId, discountId })
              }
              discount={quoteComputed?.discount}
            />
            <CycleLabel duration={duration} expediteData={expedite} />
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <EditablePartList quoteId={quoteId} />
            <CycleLabel duration={duration} expediteData={expedite} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-auto">
            <OrderEmailButton
              isEmphasized={!canCheckout}
              quoteId={quoteId}
              disabled={isComputingPrice}
            />
            <OrderCreateButton
              quoteId={quoteId}
              disabled={isComputingPrice || !canCheckout}
            />
          </div>
        </div>
        {isEmbedded ? null : <div className="segmentRectangle" />}
      </div>

      {!!quoteComputed?.isOrdering ? (
        <div style={{ display: 'block' }} className="modal in" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="loader ">
              <div className="spinner-border " role="status" />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

/**
 * Shows a loading interstitial if the quote and shop are not yet loaded or
 * there is a blocking errors in the shop pricing system.
 */
export const QuoteEditLoader = (props: Props) => {
  const { quote, quoteId, shop } = props;

  if (!quote || !shop) {
    return (
      <div data-iframe-height="true" className="loader">
        <div className="spinner-border " role="status"></div>
        <h3 className="p-3">
          Quote #<FormattedId id={quoteId} />
        </h3>
      </div>
    );
  } else {
    return <QuoteEditView {...props} />;
  }
};

const mapShopStateToProps = (state: RootState, props: Props) => {
  const quoteContent = getQuoteContent(state, props);
  const shopId = quoteContent?.shopId || '';

  return {
    shopId,
    quote: quoteContent,
  };
};

const mapQuoteStateToProps = () => {
  const getQuoteOptions = makeGetComputedQuoteOptions();

  return (state: RootState, props: Props) => {
    const quoteComputed = getQuoteComputed(state, props);
    const quoteOptions = getQuoteOptions(state, props);
    const files = getFiles(state, props) || {};
    const shop = getShop(state, props);

    return {
      files,
      shop,
      quoteComputed,
      quoteOptions,
      expediteId: quoteOptions?.expediteId,
    };
  };
};

const mapDispatchToProps = {
  onDiscountUpdate: actions.discountUpdate,
  onOptionsExpediteSet: actions.optionsExpediteSet,
  onFileUpdate: actions.fileUpdate,
};

export default compose<Props, { quoteId: string }>(
  withAuthentication,
  withQuoteDetail,
  connect(mapShopStateToProps, null),
  withShopDetail,
  connect(mapQuoteStateToProps, mapDispatchToProps),
  withQuoteTotals,
)(QuoteEditLoader);
