import React, { Component } from 'react';

type Props = {
  shopId: string;
};

export default class LocationAsk extends Component<Props> {
  state = {
    coords: undefined,
  };

  unsubscribe() {
    throw Error('unsubscribe() called before subscription.');
  }

  componentDidMount() {
    // Set up handler that updates when user login status changes.
    this.askLocation();
  }

  askLocation = () => {
    if (this.props.shopId === 'kerfed' && navigator.geolocation && false) {
      navigator.geolocation.getCurrentPosition(this.saveLocation);
    }
  };

  saveLocation = position => {
    // save lat/long user coordinates
    this.setState({ coords: position.coords });
  };

  render() {
    const { coords } = this.state;
    if (coords?.latitude) {
      return (
        <span className="badge">
          coords: {coords.latitude}, {coords.longitude}
        </span>
      );
    }
    return null;
  }
}
