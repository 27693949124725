// todo : this is the only thing using the v0 API
import { onPageView } from '@kerfed/common/api/functions';

/**
 * Submit a page view to our tracking function.
 */
async function pageView() {
  // this is browser-specific, userLanguage is IE.
  const language = navigator.language || navigator.userLanguage;
  // referenced for viewport size
  const doc = document.documentElement;
  // these are keys for the GA measurement protocol
  const view = {
    ua: navigator.userAgent,
    dr: document.referrer,
    dp: location.pathname,
    dl: location.href,
    dh: location.hostname,
    dt: document.title,
    ul: language,
    sd: screen.colorDepth,
    sr: `${window.screen.width}x${window.screen.height}`,
    vp: `${doc.clientWidth}x${doc.clientWidth}`,
    de: document.characterSet,
    ds: 'web',
  };
  try {
    onPageView(view);
  } catch (err) {
    console.log({ view, err });
  }
}

export default () => {
  // record a page view
  pageView();
  // render nothing
  return null;
};
