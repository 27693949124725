import { useMutation, useQueryCache } from 'react-query';
import * as api from '../api';

import { KEYS_QUERY, SHOP_QUERY, SHOP_EDIT_QUERY, USER_QUERY } from './keys';

export const useCreateKeyMutation = (userId: string) => {
  const cache = useQueryCache();

  return useMutation<
    Paths.UserKeyCreate.Responses.$201,
    any,
    Paths.UserKeyCreate.RequestBody
  >(args => api.userKeyCreate(userId, args), {
    onSuccess: () => {
      cache.invalidateQueries(KEYS_QUERY);
    },
  });
};

export const useDeleteKeyMutation = (userId: string) => {
  const cache = useQueryCache();

  return useMutation<
    Paths.UserKeyDelete.Responses.$200,
    any,
    {
      keyId: string;
    }
  >(args => api.userKeyDelete(userId, args.keyId), {
    onSuccess: () => {
      cache.invalidateQueries(KEYS_QUERY);
    },
  });
};

export const useShopEditMutation = (shopId: string) => {
  const cache = useQueryCache();
  return useMutation<
    Paths.ShopEditPut.Responses.$200,
    any,
    { shopContent: any }
  >(args => api.shopEditPut(shopId, args.shopContent), {
    onSuccess: () => {
      cache.invalidateQueries(SHOP_QUERY);
      cache.invalidateQueries(SHOP_EDIT_QUERY);
    },
  });
};

export const useUserMutation = (userId: string) => {
  const cache = useQueryCache();
  return useMutation<Paths.UserUpdate.Responses.$200, any>(
    args => api.userUpdate(userId, args),
    {
      onSuccess: () => {
        cache.invalidateQueries(USER_QUERY);
      },
    },
  );
};
