import dset from 'dset';
import { PayloadAction } from '@reduxjs/toolkit';

import { QuoteState, QuotePricing } from './types';

export const quoteUpdate = (
  state: QuoteState,
  action: PayloadAction<{ quoteId: string; quote: Components.Schemas.Quote }>,
) => {
  const { quoteId, quote } = action.payload;
  dset(state, [quoteId, 'content'], quote);
};

export const quoteRemove = (
  state: QuoteState,
  action: PayloadAction<{ quoteId: string }>,
) => {
  const { quoteId } = action.payload;
  delete state[quoteId];
};

export const priceUpdate = (
  state: QuoteState,
  action: PayloadAction<{ quoteId: string; price: QuotePricing }>,
) => {
  const { quoteId, price } = action.payload;
  dset(state, [quoteId, 'computed', 'price'], price);
};

export const partUpdate = (
  state: QuoteState,
  action: PayloadAction<{
    quoteId: string;
    partId: string;
    part: Components.Schemas.QuotePart;
  }>,
) => {
  const { quoteId, partId, part } = action.payload;
  dset(state, [quoteId, 'parts', partId], part);

  // Set initial spec for all methods.
  // This checks that there is a default spec, no existing user-selected
  // options, and a currently empty user-generated specification.
  const methods = state?.[quoteId]?.parts?.[partId]?.methods || {};
  for (const [methodId, method] of Object.entries(methods)) {
    // If there is no default spec, skip
    // this should have been set by hackySpecPopulator
    if (!method.defaults?.spec) continue;

    // If there are already user-selected options for this method, skip this.
    const methodOptions =
      state?.[quoteId]?.options?.method?.[partId]?.[methodId];
    if (Object.keys(methodOptions || {}).length > 0) continue;

    // If there is already a generated spec for this method, skip this.
    const spec = state?.[quoteId]?.specs?.[partId]?.[methodId];
    if (spec) continue;

    dset(state, [quoteId, 'specs', partId, methodId], method.defaults?.spec);
  }
};

export const partRemove = (
  state: QuoteState,
  action: PayloadAction<{ quoteId: string; partId: string }>,
) => {
  const { quoteId, partId } = action.payload;
  if (state[quoteId]?.parts?.[partId]) delete state[quoteId].parts![partId];
};

export const fileUpdate = (
  state: QuoteState,
  action: PayloadAction<{
    quoteId: string;
    fileId: string;
    file: Components.Schemas.File;
  }>,
) => {
  const { quoteId, fileId, file } = action.payload;
  dset(state, [quoteId, 'files', fileId], file);
};

export const fileRemove = (
  state: QuoteState,
  action: PayloadAction<{ quoteId: string; fileId: string }>,
) => {
  const { quoteId, fileId } = action.payload;
  if (state[quoteId]?.files?.[fileId]) delete state[quoteId].files![fileId];
};

export const orderingStart = (
  state: QuoteState,
  action: PayloadAction<{ quoteId: string; nonce: string }>,
) => {
  const { quoteId, nonce } = action.payload;
  dset(state, [quoteId, 'computed', 'isOrdering'], nonce);
};

export const orderingEnd = (
  state: QuoteState,
  action: PayloadAction<{ quoteId: string; nonce: string; error?: string }>,
) => {
  const { quoteId, nonce, error } = action.payload;
  if (state[quoteId]?.computed?.isOrdering !== nonce) return;

  dset(state, [quoteId, 'computed', 'isOrdering'], undefined);
  dset(state, [quoteId, 'errors', 'ordering'], error);
};

export const optionsNoteSet = (
  state: QuoteState,
  action: PayloadAction<{ quoteId: string; partId: string; note: string }>,
) => {
  const { quoteId, partId, note } = action.payload;
  dset(state, [quoteId, 'options', 'part', partId, 'note'], note);
};

export const discountClear = (
  state: QuoteState,
  action: PayloadAction<{ quoteId: string; discountId: string }>,
) => {
  const { quoteId, discountId } = action.payload;

  dset(state, [quoteId, 'options', 'quote', 'discountId'], discountId);
  dset(state, [quoteId, 'computed', 'discount'], undefined);
  dset(state, [quoteId, 'computed', 'isDiscounting'], undefined);
};

export const discountStart = (
  state: QuoteState,
  action: PayloadAction<{ quoteId: string; discountId: string; nonce: string }>,
) => {
  const { quoteId, discountId, nonce } = action.payload;

  dset(state, [quoteId, 'options', 'quote', 'discountId'], discountId);
  dset(state, [quoteId, 'computed', 'discount'], undefined);
  dset(state, [quoteId, 'computed', 'isDiscounting'], nonce);
};

export const discountEnd = (
  state: QuoteState,
  action: PayloadAction<{ quoteId: string; discount: number; nonce: string }>,
) => {
  const { quoteId, discount, nonce } = action.payload;
  if (state[quoteId]?.computed?.isDiscounting !== nonce) return;

  dset(state, [quoteId, 'computed', 'discount'], discount);
  dset(state, [quoteId, 'computed', 'isDiscounting'], undefined);
};

export const optionsExpediteSet = (
  state: QuoteState,
  action: PayloadAction<{
    quoteId: string;
    expediteId?: string;
  }>,
) => {
  const { quoteId, expediteId } = action.payload;
  dset(state, [quoteId, 'options', 'quote', 'expediteId'], expediteId);
};

export const optionsCsmSet = (
  state: QuoteState,
  action: PayloadAction<{ quoteId: string; partId: string; isCsm: boolean }>,
) => {
  const { quoteId, partId, isCsm } = action.payload;
  dset(
    state,
    [quoteId, 'options', 'part', partId, 'isCustomerSuppliedMaterial'],
    isCsm,
  );
};

export const optionsPostSet = (
  state: QuoteState,
  action: PayloadAction<{
    quoteId: string;
    partId: string;
    methodId: string;
    selections: string[];
  }>,
) => {
  const { quoteId, partId, methodId, selections } = action.payload;
  dset(
    state,
    [quoteId, 'options', 'method', partId, methodId, 'postprocess'],
    selections,
  );
};

export const optionsProcessSet = (
  state: QuoteState,
  action: PayloadAction<{
    quoteId: string;
    partId: string;
    methodId: string;
    selections: { [processId: string]: string };
  }>,
) => {
  const { quoteId, partId, methodId, selections } = action.payload;
  dset(
    state,
    [quoteId, 'options', 'method', partId, methodId, 'process'],
    selections,
  );
};

export const optionsMethodSet = (
  state: QuoteState,
  action: PayloadAction<{ quoteId: string; partId: string; methodId: string }>,
) => {
  const { quoteId, partId, methodId } = action.payload;
  dset(state, [quoteId, 'options', 'part', partId, 'methodId'], methodId);
};

export const optionsQuantitySet = (
  state: QuoteState,
  action: PayloadAction<{ quoteId: string; partId: string; quantity?: number }>,
) => {
  const { quoteId, partId, quantity } = action.payload;
  dset(state, [quoteId, 'options', 'part', partId, 'quantity'], quantity);
};

export const specStart = (
  state: QuoteState,
  action: PayloadAction<{
    quoteId: string;
    partId: string;
    methodId: string;
    nonce: string;
  }>,
) => {
  const { quoteId, partId, methodId, nonce } = action.payload;
  dset(state, [quoteId, 'computed', 'isComputing', partId, methodId], nonce);
};

export const specFinish = (
  state: QuoteState,
  action: PayloadAction<{
    quoteId: string;
    partId: string;
    methodId: string;
    spec?: Components.Schemas.Specification;
    nonce: string;
  }>,
) => {
  const { quoteId, partId, methodId, spec, nonce } = action.payload;
  const isComputing =
    state[quoteId]?.computed?.isComputing?.[partId]?.[methodId];
  if (isComputing !== nonce) return;

  dset(state, [quoteId, 'computed', 'isComputing', partId], undefined);
  dset(state, [quoteId, 'specs', partId, methodId], spec);
};
