// Based on: https://stackoverflow.com/a/47385875
import { useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { auth } from '@kerfed/common/api/firebase';
import config_public from '@kerfed/config/config-public.json';

// Paths to the Facebook "pixel" tracking code
const pixelId = config_public?.facebook?.pixel;
const fbq = (window as any).fbq;

const Facebook = (props: RouteComponentProps) => {
  const [initialized, setInitialized] = useState(false);

  // Initialize if we have a usable pixel ID and the tracking code is loaded.
  // These should be loaded using snippet from FaceBook.
  if (!pixelId || !fbq) return null;

  // Try to run the pixel tracking code.
  try {
    if (!initialized) {
      // TODO: advancedMatching is missing a lot of required fields.
      const advancedMatching = auth.currentUser
        ? { em: auth.currentUser.email }
        : {};

      const options = {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
      };

      // Run the initialization.
      fbq('init', pixelId, advancedMatching, options);
      setInitialized(true);
    }

    fbq('track', 'PageView');
  } catch (err) {
    console.warn({ err });
  }

  return null;
};

export default withRouter(Facebook);
