import React, { Component, ChangeEvent } from 'react';
import '../styles/JSONEditor.scss';
import { Message } from '@kerfed/common-ui/components';
import ErrorBoundary from './ErrorBoundary';

type Props = {
  object: any;
  onSave: any;
};

/**
 * Edit a javascript passed `object` in a textarea as JSON
 * and when the user presses the "Save" button the passed
 * `onSave` method will be called with the result.
 *
 * Displays errors for incorrect JSON, and success message
 * when onSave returns. Doesn't use third party JSON editors
 * as they were all *extremely* slow when used with JSON of
 * any appreciable size.
 *
 */
export default class extends Component<Props> {
  state = {
    error: null,
    newJSON: JSON.stringify(this.props.object, null, 1),
    savingNew: false,
    saveError: null,
    saveConfirmation: false,
  };

  onSaveLocal = async () => {
    this.setState({
      savingNew: true,
      saveConfirmation: false,
      saveError: null,
    });
    const { newJSON } = this.state;
    try {
      // parse here to catch bad JSON
      const obj = JSON.parse(newJSON);
      await this.props.onSave(obj);

      this.setState({ saveConfirmation: true });
    } catch (err) {
      this.setState({ saveError: err.message });
      return;
    } finally {
      this.setState({ savingNew: false });
    }
  };

  onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ newJSON: event?.target?.value as string });
  };

  render() {
    const { newJSON, savingNew, saveError, saveConfirmation } = this.state;

    return (
      <div className="json-editor-container">
        {!newJSON ? (
          <div className="loader loader-inline">
            <div className="spinner-border " role="status" />
            <h3 className="p-3">Loading Editor</h3>
          </div>
        ) : (
          <div>
            <ErrorBoundary>
              <div>
                <textarea
                  spellCheck={false}
                  className="form-control json-area"
                  value={newJSON}
                  onChange={this.onChange}
                />

                <div className="button-container">
                  {saveError && Message(saveError, 'Save Error')}
                  {saveConfirmation &&
                    Message('Data saved successfully.', 'Saved', 'success')}
                  {savingNew && (
                    <div className="loader loader-inline">
                      <div className="spinner-border " role="status" />
                      <h3 className="p-3">Saving Data</h3>
                    </div>
                  )}
                  <button
                    className="btn btn-lg btn-outline-primary"
                    onClick={this.onSaveLocal}
                    disabled={savingNew}
                  >
                    Save Data
                  </button>
                </div>
              </div>
            </ErrorBoundary>
          </div>
        )}
      </div>
    );
  }
}
