import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { quoteCreate } from '../api';
import { quoteUpdate } from '../store/quote/actions';
import FileUploader from './FileUploader';
import withAuthentication from '../store/auth/withAuthentication';

import { compose } from 'recompose';

type Props = {
  shopId: string;
};

const QuoteUploader = ({ shopId }) => {
  // is the quote currently being uploaded
  const [creating, setCreating] = useState(false);
  // use to forward user to new page
  const history = useHistory();

  const onUploads = async uploadIds => {
    if (uploadIds) {
      setCreating(true);
      try {
        // create the quote using the upload ID
        const quote = await quoteCreate(shopId, uploadIds);
        quoteUpdate({ quoteId: quote.id, quote });
        // forward the user to the new quote page
        history.push(`/quotes/${quote.id}`);
      } catch {
        setCreating(false);
      }
    }
  };

  return creating ? (
    <div data-iframe-height="true" className="loader">
      <div className="spinner-border " role="status" />
      <h3 className="p-3">Creating Quote</h3>
    </div>
  ) : (
    <div className="dropzone">
      <FileUploader onUploads={onUploads} />
    </div>
  );
};

export default compose<Props, {}>(withAuthentication)(QuoteUploader);
