import React from 'react';
import { NavLink } from 'react-router-dom';
import logoWhite from 'url:../images/logo_white.svg';
import '../styles/navbar.scss';
import UserButton from './UserButton';
import 'bootstrap/js/dist/collapse';

export default () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <div className="container">
        <NavLink className="navbar-brand" to="/">
          <img src={logoWhite} width="225px" alt="logo"></img>
        </NavLink>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto align-right">
            <li className="nav-item">
              <NavLink className="nav-text" to="/help">
                Getting Started
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="instant-button" to="/new">
                Instant Quote
              </NavLink>
            </li>
            <li className="nav-item">
              <UserButton />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
