import '@kerfed/common-ui/assets/helpers.css';

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RootState } from '../store';
import { optionsQuantitySet } from '../store/quote/actions';
import { getPart, makeGetComputedPartOptions } from '../store/quote/selectors';
type Props = {
  unfinished?: boolean;
  quoteId: string;
  partId: string;
  part: Components.Schemas.QuotePart;
  partOptions: Components.Schemas.PartOptions;
  onOptionsQuantitySet: typeof optionsQuantitySet;
};

const QuantityPicker = (props: Props) => {
  const { quoteId, partId, partOptions, onOptionsQuantitySet } = props;

  // TODO: clean this up.
  const quantity = partOptions?.quantity || 0;
  const onQuantityChange = quantity =>
    onOptionsQuantitySet({
      quoteId,
      partId,
      quantity,
    });

  return (
    <div className="input-group mb-3">
      <div className="input-group-prepend">
        <button
          className="btn btn-outline-secondary"
          type="button"
          disabled={quantity <= 0}
          onClick={e => onQuantityChange(Math.max(0, quantity - 1))}
        >
          -
        </button>
      </div>
      <input
        type="text"
        className="form-control quantity-input"
        aria-describedby="basic-addon2"
        style={{ maxWidth: '4em' }}
        onChange={event => {
          // An empty string is interpreted as zero.
          const newQuantityString = event.target.value;
          if (!newQuantityString) return onQuantityChange(0);

          // Ignore changes that would make this field non-numeric.
          const newQuantity = Number.parseInt(newQuantityString, 10);
          if (!isFinite(newQuantity)) return;
          // Report any valid quantity change.
          return onQuantityChange(newQuantity);
        }}
        value={quantity}
      />
      <div className="input-group-append">
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={e => onQuantityChange(quantity + 1)}
        >
          +
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = () => {
  const getPartOptions = makeGetComputedPartOptions();

  return (state: RootState, props: Props) => {
    const partOptions = getPartOptions(state, { ...props });
    const part = getPart(state, props);
    return {
      part,
      partOptions,
    };
  };
};

const mapDispatchToProps = {
  onOptionsQuantitySet: optionsQuantitySet,
};
export default compose<Props, { quoteId: string; partId: string }>(
  connect(mapStateToProps, mapDispatchToProps),
)(QuantityPicker);
