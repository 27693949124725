import React, { ChangeEvent, PureComponent } from 'react';

type Props = {
  note?: string;
  onNoteChange(note?: string): void;
};

export default class NoteSection extends PureComponent<Props> {
  onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.props.onNoteChange(event.target.value);
  };

  render() {
    const { note } = this.props;
    return (
      <div className="form-group">
        <textarea
          className="form-control rounded-0"
          value={note}
          onChange={this.onChange}
          placeholder="Specify additional fabrication instructions."
          rows={3}
        ></textarea>
      </div>
    );
  }
}
