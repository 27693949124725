import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from './index';
// get from remote api
import { shopGet } from '../../api';

type Props = {
  shopId?: string;
};

const WithShop = React.memo((props: Props) => {
  const { shopId } = props;
  const dispatch = useDispatch();

  // Create a subscription to get a single shop update.
  useEffect(() => {
    if (!shopId) return;
    let isCancelled = false;

    const update = async () => {
      try {
        const shop = await shopGet(shopId);
        if (!shop || isCancelled) return;
        dispatch(
          actions.shopUpdate({
            shopId,
            shop,
          }),
        );
      } catch (e) {
        console.error(e);
        dispatch(
          actions.shopRemove({
            shopId,
          }),
        );
      }
    };

    update();
    return () => {
      isCancelled = true;
    };
  });
  return null;
});

export default Component => <T extends Props>(props: T) => (
  <div>
    <Component {...props} />
    <WithShop {...props} />
  </div>
);
