import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import {
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
} from 'react-redux';

import { reducer as authReducer } from './auth';
import { reducer as quoteReducer } from './quote';
import { reducer as orderReducer } from './order';
import { reducer as shopReducer } from './shop';
import { reducer as userReducer } from './user';

const rootReducer = combineReducers({
  auth: authReducer,
  quotes: quoteReducer,
  orders: orderReducer,
  shops: shopReducer,
  users: userReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false, // TODO: we do want to enable this later.
  }),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export default store;
