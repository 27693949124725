import { createSlice } from '@reduxjs/toolkit';

import { AuthState } from './types';
import * as reducers from './reducers';

const INITIAL_STATE: AuthState = {
  user: undefined,
};

const slice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers,
});

export const actions = slice.actions;
export const reducer = slice.reducer;
