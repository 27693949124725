import { useQuery } from 'react-query';
import * as api from '../api';
import {
  KEYS_QUERY,
  USER_QUERY,
  QUOTES_QUERY,
  ORDERS_QUERY,
  SHOP_QUERY,
  SHOP_EDIT_QUERY,
} from './keys';

/**
 * By default react-query retries all failed attempts 3 times. This is
 * desirable if we had an unexpected error, but undesirable if we had
 * an explicit unauthorized (401) or not found (404) error thrown.
 *
 * This custom retry policy will retry all errors except 401 and 404.
 * https://react-query.tanstack.com/guides/query-retries
 */
const retry = (failureCount: number, error: any): boolean => {
  if (failureCount >= 3 || [401, 404].includes(error.name)) return false;
  return true;
};

export const useUserQuery = (userId: string) => {
  return useQuery(USER_QUERY, () => api.userGet(userId), { retry });
};

export const useKeysQuery = (userId: string) => {
  return useQuery(KEYS_QUERY, () => api.userKeyList(userId), { retry });
};

export const useQuotesQuery = (offset = 0, shopId?: string) => {
  return useQuery(
    [QUOTES_QUERY, offset],
    () => api.quoteList(offset, 20, shopId),
    {
      retry,
      keepPreviousData: true,
    },
  );
};

export const useOrdersQuery = (offset = 0, shopId?: string) => {
  return useQuery(
    [ORDERS_QUERY, offset],
    () => api.orderList(offset, 20, shopId),
    {
      retry,
      keepPreviousData: true,
    },
  );
};

export const useShopEditQuery = (shopId: string) => {
  return useQuery(SHOP_EDIT_QUERY, () => api.shopEditGet(shopId), { retry });
};

export const useShopQuery = (shopId: string) => {
  return useQuery(SHOP_QUERY, () => api.shopGet(shopId), { retry });
};
