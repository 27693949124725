import { FormattedDate } from '@kerfed/common-ui/components/formatting';
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { useCreateKeyMutation, useDeleteKeyMutation } from '../query/mutations';
import { useKeysQuery, useUserQuery } from '../query/queries';
import { getCurrentUserIdIfNonAnonymous } from '../store/auth/selectors';
import withAuthentication from '../store/auth/withAuthentication';
import { User } from '../store/user/types';
import { compose } from 'recompose';

import { UserInfo } from '../components';
import { FaKey, FaTrashAlt, FaPlus } from 'react-icons/fa';

import 'bootstrap/js/dist/dropdown';
import { Message } from '@kerfed/common-ui/components';

const ROLE_OPTIONS = {
  read: { key: 'read', text: 'Read-Only', value: 'read' },
  write: { key: 'write', text: 'Read/Write', value: 'write' },
  admin: { key: 'admin', text: 'Admin', value: 'admin' },
};

type Props = {
  userId?: string;
  user?: User;
  isKeying?: boolean;
};

const NoUserProfile = () => (
  <div className="align-center m-5">
    <h2>Looks like you aren't logged in!</h2>
  </div>
);

const KeyHelper = ({ apiKey }: { apiKey: string }) => {
  const [isCopied, setCopied] = useState(false);

  return (
    <>
      <span
        style={{
          fontFamily: 'monospace',
          padding: '6px',
          marginRight: '5px',
          border: '1px dashed',
          backgroundColor: 'lightgreen',
        }}
      >
        {apiKey}
      </span>
      <CopyToClipboard text={apiKey} onCopy={() => setCopied(true)}>
        <button className="btn btn-primary btn-sm">
          {isCopied ? 'Copied!' : 'Click To Copy'}
        </button>
      </CopyToClipboard>
    </>
  );
};

type UserProfileProps = {
  userId: string;
};

const KeysEditor = ({ userId }) => {
  const keysQuery = useKeysQuery(userId);
  const keys = keysQuery.data;

  const [createKeyMutation, createKeyMutationMeta] = useCreateKeyMutation(
    userId,
  );
  const [deleteKeyMutation, deleteKeyMutationMeta] = useDeleteKeyMutation(
    userId,
  );
  const [role, setRole] = useState('admin');
  const [newKey, setNewKey] = useState<Components.Schemas.ApiKey | undefined>();

  return (
    <div>
      {keysQuery.isLoading ? (
        <div data-iframe-height={true} className="align-center loader">
          <div className="spinner-border" role="status" />
          <h3>Loading API Keys</h3>
        </div>
      ) : (
        <table className="table">
          <tbody>
            {keys && (
              <>
                {keys.items?.map(key => (
                  <tr key={key.id}>
                    <td>
                      <FaKey />
                      &nbsp;
                      {key.id === newKey?.id ? (
                        <KeyHelper apiKey={newKey?.key!} />
                      ) : (
                        <span
                          style={{
                            fontFamily: 'monospace',
                          }}
                        >
                          {key.key}
                        </span>
                      )}
                    </td>
                    <td>
                      <div>
                        <b>Role:</b> {ROLE_OPTIONS[key.role].text}
                      </div>
                      <div>
                        <b>Expires:</b>{' '}
                        {key.dateExpiration ? (
                          <FormattedDate date={key.dateExpiration} />
                        ) : (
                          'Never'
                        )}
                      </div>
                    </td>
                    <td>
                      <button
                        className="btn btn-primary align-center"
                        disabled={deleteKeyMutationMeta.isLoading}
                        onClick={() => {
                          deleteKeyMutation({ keyId: key.id });
                        }}
                      >
                        {deleteKeyMutationMeta.isLoading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <FaTrashAlt />
                        )}
                      </button>
                    </td>
                  </tr>
                ))}
              </>
            )}

            <tr>
              <td colSpan={3}>
                <div className="btn-group">
                  <button
                    className="btn btn-primary"
                    disabled={createKeyMutationMeta.isLoading}
                    onClick={() => {
                      createKeyMutation(
                        { role },
                        {
                          onSuccess: createdKey => setNewKey(createdKey),
                        },
                      );
                    }}
                  >
                    {createKeyMutationMeta.isLoading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <FaPlus />
                    )}
                    &nbsp;Create a new API key
                  </button>

                  <div className="dropdown">
                    <button
                      className="btn btn-outline-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Role: {ROLE_OPTIONS[role].text}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {Object.values(ROLE_OPTIONS).map(blob => (
                        <a
                          style={{ cursor: 'pointer' }}
                          key={`role-select-${blob.key}`}
                          className="dropdown-item"
                          onClick={_ => setRole(blob.key)}
                        >
                          {blob.text}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

type AccountProps = {
  userId?: string;
};

export const Account = ({ userId }: AccountProps) => {
  return (
    <div className="container-md p-3 bg-white">
      <h1>My Account</h1>
      <hr />
      {userId ? (
        <>
          <h2>User Info</h2>
          <UserInfo userId={userId} />
          <hr />
          <div>
            <h2>API Keys</h2>
            <p>
              Generate and manage API keys which allow you to use our
              <a href="https://api.kerfed.com"> REST API</a> to do anything your
              account is allowed to do, including getting quotes and orders,
              analyzing parts, or creating and editing a new Shop for custom
              pricing. Note that the full API key is only available immediately
              after creation for security; delete it and create a new one if you
              lose it.
            </p>
            <KeysEditor userId={userId} />
          </div>
        </>
      ) : (
        Message('Log in to see account information.', 'Not Logged In')
      )}

      <div className="segmentRectangle" />
    </div>
  );
};

const mapStateToProps = state => {
  const userId = getCurrentUserIdIfNonAnonymous(state);
  return {
    userId,
  };
};

export default compose<Props, {}>(
  withAuthentication,
  connect(mapStateToProps),
)(Account);
