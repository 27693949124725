import { createSlice } from '@reduxjs/toolkit';

import { OrderState } from './types';
import * as reducers from './reducers';

const INITIAL_STATE: OrderState = {};

const slice = createSlice({
  name: 'orders',
  initialState: INITIAL_STATE,
  reducers,
});

export const actions = slice.actions;
export const reducer = slice.reducer;
