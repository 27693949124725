import '@kerfed/common-ui/assets/helpers.css';
import { FormattedId } from '@kerfed/common-ui/components';
import FileImage from '@kerfed/common-ui/components/FileImage';
import { titleCase } from '@kerfed/common/utils';
import React, { useState } from 'react';
import { useSelector } from '../store';
import { getParts } from '../store/order/selectors';
import ErrorBoundary from './ErrorBoundary';
import SafePrice from './SafePrice';

const SetupFooter = ({ price }) => {
  return (
    <>
      <tbody>
        {Object.entries(price?.setups || {})
          .sort()
          .filter(([_, s]) => s?.price > 0)
          .map(([setupId, setup]) => (
            <tr key={setupId}>
              <td />
              <td>{setupId}</td>

              <td>Setup</td>
              <td>{Math.ceil(setup.utilization)}</td>
              <td>
                <SafePrice price={setup.price} message="" />
              </td>
              <td>
                <SafePrice
                  price={setup.price * Math.ceil(setup.utilization)}
                  message=""
                />
              </td>
            </tr>
          ))}
      </tbody>
    </>
  );
};

export const TotalsFooter = ({ price, columns, className }) => {
  const pad = columns - 1;
  return (
    <tbody className={className}>
      <tr>
        <th colSpan={columns} className="total-firstrow">
          <h3>Order Total</h3>
        </th>
      </tr>
      <tr>
        <th colSpan={pad} className="total-text">
          <h5>Subtotal</h5>
        </th>
        <th>
          <h5>
            <SafePrice
              price={price?.units + price?.setup || 0.0}
              message="One or more parts are missing required options."
            />
          </h5>
        </th>
      </tr>

      <tr>
        <th colSpan={pad} className="total-text">
          <h5>Discount</h5>
        </th>
        <th>
          <h5 style={{ color: 'green' }}>
            <SafePrice
              price={price?.discount}
              message="Error applying discount code."
            />
          </h5>
        </th>
      </tr>
      <tr>
        <th colSpan={pad} className="total-text">
          <h5>Shipping</h5>
        </th>
        <th>
          <h5>
            <SafePrice price={price?.shipping} />
          </h5>
        </th>
      </tr>
      <tr>
        <th colSpan={pad} className="total-text">
          <h5>Taxes</h5>
        </th>
        <th>
          <h5>
            <SafePrice price={price?.taxes} />
          </h5>
        </th>
      </tr>
      <tr>
        <th colSpan={pad} className="total-text">
          <h3>Total</h3>
        </th>
        <th>
          <h3>
            <SafePrice
              price={price?.total}
              message="One or more parts are missing required options."
            />
          </h3>
        </th>
      </tr>
    </tbody>
  );
};

type Descriptions = {
  [optionId: string]: {
    [valueId: string]: {
      text: string;
    };
  };
};

type PartProps = {
  partId: string;
  part: Components.Schemas.OrderPart;
  descriptions: Descriptions;
};

/**
 * Extract a List component that indicates what settings
 * the part was ordered with, i.e. material and finish.
 *
 * @param part the part
 * @param options options specified on the part
 * @param descriptions our pricing structure
 */
function OrderedInfo(
  part: Components.Schemas.OrderPart,
  descriptions: Descriptions,
) {
  const spec = part.spec;
  // will store as array of ListItem elements
  const labels: any = [];
  // collect process labels
  for (const [key, value] of Object.entries(spec?.solution?.process || {})) {
    try {
      const current = descriptions[key].values[value].label;
      if (current) {
        labels.push(
          <li key={`${key}-material`}>
            <h5>{titleCase(key.split('_').join(' '))}</h5>
            {current}
          </li>,
        );
      }
    } catch (err) {
      continue;
    }
  }
  // return a List element with our labels
  return <ul className="description-list">{labels}</ul>;
}

// Return an order information for turned parts:
const GenericPart = ({ partId, part, descriptions }: PartProps) => (
  <div className="grid" style={{ marginBottom: '5em' }}>
    <div className="row">
      <div className="col-md">
        <ErrorBoundary>
          <FileImage url={part?.method?.drawings?.svg} />
        </ErrorBoundary>
      </div>
      <div className="col-md">{OrderedInfo(part, descriptions)}</div>
    </div>
  </div>
);

type Props = {
  orderId: string;
  descriptions: Descriptions;
  price?: Components.Schemas.Price;
};

export default ({ orderId, descriptions, price }: Props) => {
  const parts = useSelector(state => getParts(state, { orderId }));
  const [expand, setExpand] = useState({});

  if (!parts) {
    return (
      <div data-iframe-height={true} className="align-center loader">
        <div className="spinner-border" role="status" />
        <h3 className="p-3">Loading Parts</h3>
      </div>
    );
  }

  return (
    <table className="table order-part-list table-bordered table-responsive-lg">
      <thead>
        <tr>
          <th>Part Number</th>
          <th>Part Name</th>
          <th>Method</th>
          <th>Quantity</th>
          <th>Unit Price</th>
          <th>Total Price</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(parts || {})
          .sort()
          .map(([partId, part]) => (
            <ErrorBoundary key={`${partId}_detail`}>
              <tr
                key={partId}
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  setExpand({ ...expand, [partId]: !expand[partId] })
                }
              >
                <td>
                  <FormattedId id={partId} />
                </td>
                <td>{part?.name || ''}</td>
                <td>{part?.spec?.solution?.process?.process}</td>
                <td>{part?.options?.quantity || 0} </td>
                <td>
                  <SafePrice
                    price={part?.spec?.unitPrice}
                    message="This part requires manual quoting."
                  />
                </td>
                <td>
                  <SafePrice
                    price={
                      (part?.spec?.unitPrice || NaN) *
                      (part?.options?.quantity || NaN)
                    }
                    message="This part requires manual quoting."
                  />
                </td>
              </tr>
              {!!expand[partId] && (
                <tr>
                  <td colSpan={6}>
                    <GenericPart
                      partId={partId}
                      part={parts[partId]}
                      descriptions={descriptions}
                    />
                  </td>
                </tr>
              )}
            </ErrorBoundary>
          ))}
      </tbody>
      <SetupFooter price={price} />
      <TotalsFooter price={price} columns={6} className="partlist-total" />
    </table>
  );
};
