// No exported actions (there are no public actions).
import { batch } from 'react-redux';
import { AppDispatch } from '..';
import { actions } from './index';
import { User } from './types';
import * as userActions from '../user/actions';

export const authUpdate = ({ user }: { user: User }) => async (
  dispatch: AppDispatch,
) => {
  batch(() => {
    // Update the record for this user.
    if (user) {
      dispatch(
        userActions.userUpdate({
          userId: user.uid,
          user: {
            id: user.uid,
            email: user.email || '',
            name: user.displayName || user.email || 'Unknown User',
          },
        }),
      );
    }

    // Update the authentication state.
    dispatch(actions.authUpdate({ user }));
  });
};

export const authClear = actions.authClear;
