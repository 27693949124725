import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { capitalize } from 'lodash';

import { htmlToUnicode } from '@kerfed/common/utils';
import QuantityPicker from './Quantity';

import * as actions from '../store/quote/actions';
import {
  getQuoteContent,
  getPart,
  getMethodSpec,
  getMethodIsComputing,
  makeGetComputedPartOptions,
  makeGetComputedMethodOptions,
} from '../store/quote/selectors';
import { getShop } from '../store/shop/selectors';
import { RootState } from '../store';
import { FaTimesCircle } from 'react-icons/fa';

const CATEGORY_NAMES = {
  color: 'Color',
  finish: 'Finish',
  material: 'Material',
  method: 'Method',
  thickness: 'Thickness',
};
// display order for categories
const CATEGORY_ORDER = ['material', 'thickness', 'finish', 'color'];

type EditableProcessOptionProps = {
  processId: string;
  selection: string;
  options: Components.Schemas.OptionDescription[];
  onOptionChange: (processId: string, selection?: string) => void;
};

const EditableProcessOption = ({
  processId,
  selection,
  options,
  onOptionChange,
}: EditableProcessOptionProps) => {
  // format color options
  const formatted = React.useMemo(
    () =>
      options.map(blob => ({
        ...blob,
        // it would be nicer if the color icons could be passed to the icon field
        // but semantic-ui-react then wouldn't render them properly when selected
        // this is the suggested workaround:
        // https://github.com/Semantic-Org/Semantic-UI-React/issues/1147
        label: (
          <span>
            {blob.hex ? (
              <span style={{ color: blob.hex }}>&#9632;&nbsp;</span>
            ) : null}
            {blob.label}
          </span>
        ),
      })),
    [options],
  );

  // note that `selection` is the value of `options.id`, `selected` is the options object
  const selected = React.useMemo(
    () => formatted.find(i => i.id === selection),
    [options, selection],
  );

  // is this dropdown currently in an error state
  const error = React.useMemo(
    () =>
      !selected?.label ||
      (!!selection && !options.map(({ id }) => id).includes(selection)),
    [options, selection],
  );

  const onClick = option => {
    const value = option.id ? (option.id as string) : undefined;
    if (option.id !== selection) {
      onOptionChange(processId, value);
    } else {
      onOptionChange(processId, undefined);
    }
  };

  return (
    <>
      <p>Configure {CATEGORY_NAMES[processId] || processId}</p>
      <div className="part-config">
        <button
          type="button"
          className={`btn dropdown-toggle ${error ? 'warn' : 'ok'}`}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {selected?.label ||
            `Select ${CATEGORY_NAMES[processId] || processId}`}
        </button>
        <div className="dropdown-menu">
          {formatted.map(option => (
            <button
              onClick={() => onClick(option)}
              className={`dropdown-item ${option.id === selection && 'active'}`}
              key={`${option.id}-process`}
              type="button"
            >
              {option.label}
              {option.id === selection && (
                <span>
                  &nbsp;
                  <FaTimesCircle />
                </span>
              )}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

type EditablePostOptionProps = {
  selections: string[];
  values: any[];
  onOptionChange: (selections: string[]) => void;
};

const EditablePostOption = ({
  selections,
  values,
  onOptionChange,
}: EditablePostOptionProps) => {
  // which groups (i.e. `A1`, `B2`, etc, have been consumed
  const usedGroups = React.useMemo(
    () =>
      values
        .filter(value => selections.includes(value.id))
        .map(value => value.group),
    [selections, values],
  );

  return (
    <form className="part-config-post">
      {values.map(blob => (
        <div className="form-check" key={`check-${blob.id}`}>
          <input
            className="form-check-input"
            type="checkbox"
            name={blob.id}
            disabled={
              !selections.includes(blob.id) && usedGroups.includes(blob.group)
            }
            id={`radio-post-${blob.id}`}
            onChange={_ =>
              onOptionChange(
                selections.includes(blob.id)
                  ? selections.filter(i => i !== blob.id)
                  : [...selections, blob.id],
              )
            }
            checked={selections.includes(blob.id)}
          />
          <label className="form-check-label" htmlFor={`radio-post-${blob.id}`}>
            {htmlToUnicode(blob.text)}
          </label>
        </div>
      ))}
    </form>
  );
};

type OptionsCombo = {
  [optionId: string]: {
    [nextCategoryId: string]: string[];
  };
};

type Props = {
  quoteId: string;
  shopId: string;
  partId: string;
  partOptions?: Components.Schemas.PartOptions;
  methodId: string;
  methods: Components.Schemas.Methods;
  methodOptions?: Components.Schemas.MethodOptions;
  methodSpec?: Components.Schemas.Specification;
  methodIsComputing?: boolean;
  optionsCombos: { [categoryId: string]: OptionsCombo };
  optionsDescriptions: {
    [categoryId: string]: {
      [optionId: string]: Components.Schemas.OptionDescription;
    };
  };
  shop?: Components.Schemas.Shop;
  onOptionsPostSet: typeof actions.optionsPostSet;
  onOptionsProcessSet: typeof actions.optionsProcessSet;
};

/**
 * The series of dropdowns to configure a part.
 */
const EditablePartConfiguration = ({
  quoteId,
  partId,
  methods,
  methodId,
  methodOptions,
  optionsCombos,
  optionsDescriptions,
  onOptionsPostSet,
  onOptionsProcessSet,
}: Props) => {
  const method = methods[methodId];
  const optionsComponents = [] as any;

  let selectedOptions = {};
  let currOptions = Object.entries(optionsCombos?.['method']?.[methodId] || {});

  while (currOptions.length) {
    const nextOptions: typeof currOptions = [];

    for (const [processId, optionIds] of currOptions) {
      const optionDescriptions = optionIds.map(optionId => {
        const description = optionsDescriptions[processId];

        // get the stored label if available
        const value = description.values?.[optionId];
        if (value) return { id: optionId, ...value };

        return { id: optionId, label: capitalize(optionId.replace('_', ' ')) };
      });

      const optionSelection = methodOptions?.process?.[processId];
      if (optionSelection && optionIds.includes(optionSelection)) {
        selectedOptions[processId] = optionSelection;

        const childCombos = Object.entries(
          optionsCombos[processId]?.[optionSelection] || {},
        );
        if (childCombos) nextOptions.push(...childCombos);
      }

      optionsComponents.push(
        <EditableProcessOption
          key={processId}
          processId={processId}
          selection={optionSelection}
          options={optionDescriptions}
          onOptionChange={(processId, optionId) => {
            // Ignore selections that are already selected.
            if (optionId === optionSelection) return;

            // On a new selection, update the selection set.
            onOptionsProcessSet({
              quoteId,
              partId,
              methodId,
              selections: { ...selectedOptions, [processId]: optionId },
            });
          }}
        />,
      );
    }

    currOptions = nextOptions;
  }
  // we are currently just using basically undefined sort behavior
  // if you don't sort optionsComponents they twitch around and
  // reorder themselves like a Hogwarts painting
  const sorted = React.useMemo(
    () =>
      optionsComponents.sort(
        (i, j) => CATEGORY_ORDER.indexOf(i.key) > CATEGORY_ORDER.indexOf(j.key),
      ),
    [optionsComponents],
  );

  return (
    <div className="part-config-group">
      <p>Configure Quantity</p>
      <QuantityPicker quoteId={quoteId} partId={partId} />
      {sorted || null}
      {Object.keys(method?.postprocess || {}).length > 0 && (
        <>
          <p>Configure Postprocessing Options</p>
          <EditablePostOption
            selections={methodOptions?.postprocess || []}
            values={method?.postprocess || []}
            onOptionChange={selections => {
              onOptionsPostSet({
                quoteId,
                partId,
                methodId,
                selections,
              });
            }}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = () => {
  const getPartOptions = makeGetComputedPartOptions();
  const getMethodOptions = makeGetComputedMethodOptions();

  return (state: RootState, props: Props) => {
    const shopId = getQuoteContent(state, props)?.shopId || '';
    const shop = getShop(state, { shopId });
    const part = getPart(state, props);
    const partOptions = getPartOptions(state, props);

    const methodId = partOptions?.methodId || '';
    const methods = part?.methods || {};

    const methodProps = { ...props, methodId };
    const methodOptions = getMethodOptions(state, methodProps);
    const methodSpec = getMethodSpec(state, methodProps);
    const methodIsComputing = getMethodIsComputing(state, methodProps);

    return {
      shopId,
      partOptions,
      methodId,
      methods,
      methodOptions,
      methodSpec,
      methodIsComputing,
      optionsCombos: shop.combos,
      optionsDescriptions: shop.options,
    };
  };
};

const mapDispatchToProps = {
  onOptionsPostSet: actions.optionsPostSet,
  onOptionsProcessSet: actions.optionsProcessSet,
  onOptionsMethodSet: actions.optionsMethodSet,
};

export default compose<Props, { quoteId: string; partId: string }>(
  connect(mapStateToProps, mapDispatchToProps),
)(EditablePartConfiguration);
