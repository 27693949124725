import { Address } from '@kerfed/common/schemas/fields';
import { validateEmail } from '@kerfed/common/utils';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from '../store';
import {
  shippingApply,
  shippingUpdate,
  shippingMethodUpdate,
} from '../store/order/actions';
import {
  getIsShipping,
  getShipping,
  getShippingMethodId,
  getShippingMethods,
} from '../store/order/selectors';
import EditableAddressBlock from './EditableAddressBlock';

const ExemptBlurb = () => (
  <p>
    <b>For tax-exempt orders:</b> If we do not have your exemption information
    on file, we may need to contact you before releasing parts to manufacturing.
  </p>
);

type Props = {
  orderId: string;
};

export default ({ orderId }: Props) => {
  const dispatch = useDispatch();
  const shipping = useSelector(state => getShipping(state, { orderId }));
  const methods = useSelector(state => getShippingMethods(state, { orderId }));

  // disable input if we're either updating shipping methods or ordering
  const isCalculating = useSelector(state => getIsShipping(state, { orderId }));

  const selectedMethodId = useSelector(state =>
    getShippingMethodId(state, { orderId }),
  );

  // apply shipping field changes and query the API
  const onApply = shipping => dispatch(shippingApply({ orderId, shipping }));

  // update shipping field changes without querying shipping API
  const onUpdate = shipping => dispatch(shippingUpdate({ orderId, shipping }));

  // select a shipping method
  const onUpdateMethod = methodId =>
    dispatch(shippingMethodUpdate({ orderId, methodId }));

  if (!shipping) {
    // set default shipping values here
    onUpdate({ taxes: { exempt: false } });
  }

  // triggers when a field like `name` or `email` changes
  const onAddressFieldChange = (event: any): void => {
    const { name, value } = event.target;
    // update redux
    onUpdate({
      ...shipping,
      address: { ...shipping?.address, [name]: value },
    });
  };

  // triggers when a geotagged location is selected by the user
  const onAddressGeo = (location: Address) => {
    // update redux state and call the API to generate prices
    onApply({
      ...shipping,
      address: { ...shipping?.address, ...location },
    });
  };

  const onTaxChange = (): void => {
    if (!isCalculating) {
      onUpdate({ ...shipping, taxes: { exempt: !shipping?.taxes?.exempt } });
    }
  };

  const methodText = method =>
    !method.isDeferred
      ? `${method.description} (${method.days} day) - $${(
          method.price || 0.0
        ).toFixed(2)}`
      : method.description;

  const isEmailValid =
    !shipping?.address?.errors?.includes('email') &&
    (!shipping?.address?.email || validateEmail(shipping?.address?.email));

  return (
    <div className="grid">
      <div className="row">
        <div className="col-md">
          <h2>Shipping Address</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md">
          <div className="form-group">
            <input
              type="text"
              name="name"
              disabled={isCalculating}
              placeholder="Name"
              autoComplete="shipping name"
              value={shipping?.address?.name || ''}
              onChange={onAddressFieldChange}
              className={`form-control ${
                shipping?.address?.errors?.includes('name')
                  ? ' input-error'
                  : ''
              }`}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <div className="form-group">
            <input
              type="text"
              placeholder="Email"
              name="email"
              disabled={isCalculating}
              autoComplete="email"
              value={shipping?.address?.email || ''}
              onChange={onAddressFieldChange}
              className={`form-control ${isEmailValid ? '' : ' input-error'}`}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md">
          <div className="form-group">
            <EditableAddressBlock
              disabled={isCalculating}
              error={shipping?.address?.errors?.includes('line1')}
              onChange={onAddressGeo}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md">
          <div className="form-group">
            <input
              type="text"
              placeholder="Apartment, suite, etc. (optional)"
              name="line2"
              disabled={isCalculating}
              autoComplete="line2"
              value={shipping?.address?.line2 || ''}
              onChange={onAddressFieldChange}
              className="form-control"
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <div className="form-group">
            <input
              type="text"
              name="company"
              disabled={isCalculating}
              placeholder="Company (optional)"
              autoComplete="shipping company"
              value={shipping?.address?.company || ''}
              onChange={onAddressFieldChange}
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md">
          <div className="form-group">
            <input
              type="text"
              name="phone"
              disabled={isCalculating}
              placeholder="Phone Number (optional)"
              autoComplete="shipping tel"
              value={shipping?.address?.phone || ''}
              onChange={onAddressFieldChange}
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-md checkbox-group">
          <input
            type="checkbox"
            name="taxExempt"
            disabled={isCalculating}
            checked={shipping?.taxes?.exempt || false}
            onChange={() => null}
            onClick={onTaxChange}
          />
          <label onClick={onTaxChange}>Order Is Tax Exempt</label>
          {shipping?.taxes?.exempt && <ExemptBlurb />}
        </div>
      </div>
      {isCalculating ? (
        <div className="row">
          <div className="col-md">
            <div className="loader-inline">
              <div className="spinner-border " role="status" />
              <h3 className="p-3">Computing Shipping</h3>
            </div>
          </div>
        </div>
      ) : null}
      {methods && !isCalculating ? (
        <div className="row my-4">
          <div className="col-md">
            <div className="form-group">
              <h4>Select Shipping Method</h4>
              {Object.keys(methods).map(key => (
                <div key={key} className="form-check radio-shipping">
                  <input
                    className="form-check-input "
                    type="radio"
                    name={key}
                    key={`radio-${key}`}
                    onClick={_ => onUpdateMethod(key)}
                    onChange={_ => null}
                    checked={key === selectedMethodId}
                  />
                  <label
                    className="form-check-label"
                    onClick={_ => onUpdateMethod(key)}
                    htmlFor={`radio-${key}`}
                  >
                    {methodText(methods[key])}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
