import React from 'react';
import { OrderList } from '../components';

export default ({ shopId }) => {
  return (
    <div className="container-md bg-white p-3">
      <h1>View Existing Orders</h1>
      <OrderList />
      <div className="segmentRectangle" />
    </div>
  );
};
