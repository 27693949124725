import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';

import { getQuoteComputed, getQuote } from './selectors';
import { RootState } from '..';
import * as actions from './actions';

type Props = {
  quoteId: string;
};

const WithQuoteTotalsInternal = ({ quoteId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.priceUpdate({ quoteId }));
  });

  return null;
};

// This should contain selectors for everything that could
// potentially change the pricing of a quote.
const mapStateToProps = (state: RootState, props: Props) => ({
  discountValue: getQuoteComputed(state, props)?.discount,
  specs: getQuote(state, props)?.specs,
});

const WithQuoteTotals = compose<Props, { quoteId: string }>(
  connect(mapStateToProps, null),
)(WithQuoteTotalsInternal);

export default Component => (props: Props) => (
  <div>
    <Component {...props} />
    <WithQuoteTotals quoteId={props.quoteId} />
  </div>
);
