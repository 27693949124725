import Explainer from '@kerfed/common-ui/components/Explainer';
import { FormattedId, Message } from '@kerfed/common-ui/components';

import React, { useState } from 'react';

import { useUserQuery } from '../query/queries';
import { useUserMutation } from '../query/mutations';

import avatar from 'url:../assets/avatar.svg';

export default ({ userId }) => {
  const [isEdit, setEdit] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [fields, setFields] = useState({ name: '', email: '' });

  const [createUserMutation] = useUserMutation(userId);
  const { isLoading, data: user, error } = useUserQuery(userId);

  const saveData = async () => {
    // show a loader while function executes
    setSaving(true);
    // explicitly list the fields we're updating
    // as we may have dumped full user object into fields
    await createUserMutation(fields);
    setSaving(false);
    setEdit(false);
  };

  const onChange = (event: any) => {
    // update the state with whatever they typed
    const { name, value } = event.target;
    setFields({ ...fields, [name]: value });
  };

  const toggleEdit = () => {
    // if not editing currently and a user has
    // finished loading, set the editable fields
    // to this value before opening the form
    if (!isEdit && !isLoading) {
      setFields(user);
    }
    // toggle editing to the other position
    setEdit(!isEdit);
  };

  console.log({ isLoading, error, user });

  //const user = data;

  return isSaving || isLoading ? (
    <div className="align-center loader">
      <div className="spinner-border" role="status" />
      <h5>{isSaving ? 'Saving Data' : 'Loading Data'}</h5>
    </div>
  ) : isEdit ? (
    <div className="form-group">
      <h3>Editing User Info</h3>
      <label className="my-1" htmlFor="userEmailInput">
        Email Address
      </label>
      <input
        type="text"
        id="userEmailInput"
        name="email"
        className="form-control"
        placeholder="user@company.com"
        aria-label="User's email"
        value={fields.email}
        autoComplete="email"
        onChange={onChange}
      />
      <label className="my-1" htmlFor="userNameInput">
        Full Name
      </label>
      <input
        type="text"
        name="name"
        id="userNameInput"
        className="form-control"
        placeholder="Joe Smith"
        aria-label="User's Name"
        value={fields.name}
        autoComplete="name"
        onChange={onChange}
      />
      <div className="btn-group my-2">
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={saveData}
        >
          Save Data
        </button>
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={_ => setEdit(!isEdit)}
        >
          Cancel
        </button>
      </div>
    </div>
  ) : (
    <>
      {user && (
        <div>
          <img className="img-fluid w-25" src={user?.photoUrl || avatar} />
          <h5> {user?.name}</h5>
          <Explainer
            explanation={'UID is your unique user identification number.'}
          >
            UID: <FormattedId id={userId} />
          </Explainer>
          {user?.email ? (
            <div>
              Email: <i>{user.email}</i>
            </div>
          ) : null}
          <button
            className="btn btn-outline-secondary my-2"
            type="button"
            onClick={toggleEdit}
          >
            Edit User Info
          </button>
        </div>
      )}
    </>
  );
};
