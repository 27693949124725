import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { htmlToUnicode } from '@kerfed/common/utils';

import * as actions from '../store/quote/actions';
import {
  getQuoteContent,
  getPart,
  getMethodSpec,
  makeGetComputedPartOptions,
  makeGetComputedMethodOptions,
} from '../store/quote/selectors';
import { getShop } from '../store/shop/selectors';
import { RootState } from '../store';

// TODO(PV): Move this upstream to the shop definition?
const PROCESS_OPTIONS = [
  {
    key: 'flat',
    value: 'flat',
    text: 'Waterjet / Laser',
    description:
      "CNC lasers or waterjets can produce even large (5'x10') flat parts very quickly and accurately (typically 0.005\" or better).",
  },
  {
    key: 'bent',
    value: 'bent',
    text: 'CNC Bent',
    description:
      'CNC press brakes can take a laser or waterjet blank and form it with cylindrical bends into complex boxes, brackets, and other parts. ',
  },
  {
    key: 'roll',
    value: 'roll',
    text: 'CNC Roll',
    description:
      'CNC slip rolling can take a laser or waterjet blank of material up to 0.25" thick and roll it into a cylinder.',
  },
  {
    key: 'turn',
    value: 'turn',
    text: 'CNC Lathe',
    description:
      'CNC lathes such as the Haas ST15 or Mazack QT 200 can produce radially symmetric parts very quickly.',
  },
  {
    key: 'mill',
    value: 'mill',
    text: 'CNC Mill',
    description:
      'Conventional CNC milling can produce complex geometry as a series of extruded cuts using machines such as the Haas VF2SS or the Fanuc Robodrill.',
  },

  {
    key: 'cots',
    value: 'cots',
    text: 'COTS',
    description:
      'The part is commercially available and has been identified from a geometric hash.',
  },
  {
    key: 'add',
    value: 'add',
    text: '3D Print',
    description:
      'Additive techniques such as FDM and SLS can produce intricate geometries inexpensively.',
  },
  {
    key: 'manual',
    value: 'manual',
    text: 'Manual Quote',
    description: 'Have the part quoted manually.',
  },
];

type ProcessOption = typeof PROCESS_OPTIONS[number] & {
  disabled: boolean;
  error?: Components.Schemas.Error;
  isDone?: boolean;
};

type Props = {
  quoteId: string;
  partId: string;
  methodId: string;
  methods: Components.Schemas.Methods;
  shop: Components.Schemas.Shop;
  onOptionsMethodSet: typeof actions.optionsMethodSet;
};

const EditablePartMethodSelector = ({
  quoteId,
  partId,
  methodId,
  methods,
  shop,
  onOptionsMethodSet,
}: Props) => {
  const options = {} as { [key: string]: ProcessOption };
  for (const option of PROCESS_OPTIONS) {
    const process = methods[option.key] as Components.Schemas.MethodBase;
    const { isDone, error } = process?.status || {};
    const disabled = !isDone || !!error?.code;

    options[option.key] = {
      ...option,
      text: option.text,
      disabled,
      error,
      isDone,
    };
  }

  // which methods does this shop want to present as available
  // should this computed by machines?
  const hasMethods = shop?.settings?.hasMethods;

  const onSelectionChange = methodId =>
    methodId && onOptionsMethodSet({ quoteId, partId, methodId });

  return (
    <div>
      <div
        className="btn-group btn-method-group my-2"
        role="group"
        aria-label="Part manufacturing methods"
      >
        {Object.values(options)
          .filter(v => !!hasMethods?.includes(v.key))
          .map(v => (
            <button
              key={v.key}
              data-toggle="tooltip"
              data-placement="top"
              title={
                v?.error?.message ||
                (v.isDone ? '' : 'Hang tight, still processing.')
              }
              className={`btn btn-method ${
                v.key === methodId
                  ? 'btn-primary'
                  : v.disabled
                  ? v.isDone
                    ? 'btn-outline-danger'
                    : 'btn-outline-secondary'
                  : 'btn-outline-primary'
              }`}
              disabled={v.disabled}
              onClick={() => onSelectionChange(v.key)}
            >
              {!v.isDone && (
                <span
                  className="spinner-border spinner-button"
                  role="status"
                  aria-hidden="true"
                />
              )}

              {htmlToUnicode(v.text)}
            </button>
          ))}
      </div>
      <hr />
    </div>
  );
};

const mapStateToProps = () => {
  const getPartOptions = makeGetComputedPartOptions();
  const getMethodOptions = makeGetComputedMethodOptions();

  return (state: RootState, props: Props) => {
    const shopId = getQuoteContent(state, props)?.shopId || '';
    const shop = getShop(state, { shopId });
    const part = getPart(state, props);
    const partOptions = getPartOptions(state, props);
    const methodId = partOptions?.methodId || '';
    const methods = part?.methods || {};
    const methodOptions = getMethodOptions(state, { ...props, methodId });
    const methodSpec = getMethodSpec(state, { ...props, methodId });

    return {
      shopId,
      shop,
      partOptions,
      methodId,
      methods,
      methodOptions,
      methodSpec,
    };
  };
};

const mapDispatchToProps = {
  onOptionsPostSet: actions.optionsPostSet,
  onOptionsProcessSet: actions.optionsProcessSet,
  onOptionsMethodSet: actions.optionsMethodSet,
};

export default compose<Props, { quoteId: string; partId: string }>(
  connect(mapStateToProps, mapDispatchToProps),
)(EditablePartMethodSelector);
