import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
// use dist bootstrap
import 'bootstrap/dist/css/bootstrap.css';
// import custom fonts
import './fonts/fonts.scss';
// import default custom styles
import './styles/index.scss';
// bootstrap overrides
import './styles/overrides.scss';

import App from './App';
import store from './store';

import config_public from '@kerfed/config/config-public.json';

// Configure top-level sentry.io logging
import * as Sentry from '@sentry/browser';
// Use a separate sentry route for frontend
if (config_public?.sentry?.frontend) {
  Sentry.init({ dsn: config_public?.sentry?.frontend });
}

// Start rendering React application.
// add the scripts in HEAD here so we can include config keys
ReactDOM.render(
  <Provider store={store}>
    <Helmet>
      <script
        async={true}
        defer={true}
        src={`https://maps.googleapis.com/maps/api/js?key=${config_public.google.maps_api}&libraries=places`}
      />
    </Helmet>
    <App />
  </Provider>,
  document.getElementById('root'),
);
