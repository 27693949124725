import React from 'react';

export default () => (
  <div className="container-md p-3 bg-white align-center">
    <img className="w-25" src="/static/images/frown.svg" />
    <h1>404: Page Not Found</h1>

    <div className="segmentRectangle" />
  </div>
);
