/**
 * functions.ts
 *
 * This is the V0 client-side (i.e. in the web app) interface for cloud functions.
 *
 * IT IS DEPRICIATED; ALL NEW FUNCTIONALITY SHOULD USER `api.ts`
 * which references the `/v1` API.
 */

import { auth } from './firebase';

import config_public from '@kerfed/config/config-public.json';

const baseUri = `${config_public?.firebase?.functionsURL}/v0`;

/**
 * `fetch` a URL with retry if it fails the first time
 *
 * Cloud functions or network calls randomly fail, and it seems like
 * the best place to put the retry logic is in the actual fetch call.
 * Apparently this is a fairly common solution and seems much more
 * reasonable than the things we were doing before.
 *
 * Copied from:
 * https://dev.to/ycmjason/javascript-fetch-retry-upon-failure-3p6g
 *
 * @param url string, the URL to fetch data from
 * @param options any, the options to pass to `fetch`
 * @param retries number, the number of times to retry before erroring
 *
 **/
const fetch_retry = async (url: string, options: any, retries: number) => {
  let error;
  for (let i = 0; i < retries; i++) {
    try {
      return await fetch(url, options);
    } catch (err) {
      console.warn(`fetch had to retry due to error: ${err}`);
      error = err;
    }
  }
  throw error;
};

// Make an authenticated call to a Firebase HTTP Cloud Function.
function callAuthenticated(uri: string, params: any) {
  if (!auth.currentUser) {
    throw new Error('Must be logged in to make authenticated call.');
  }

  return auth.currentUser.getIdToken().then(function(token: string) {
    return fetch_retry(
      uri,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      },
      2,
    );
  });
}

// submit page views to visit functions
// TODO : we never want to wait for this or care about a return code
export async function onPageView(data: any) {
  const uri = `${baseUri}/onPageView`;
  try {
    return callAuthenticated(uri, data);
  } catch (err) {
    console.log(err);
    return;
  }
}
