import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  FormattedId,
  FormattedDate,
  Message,
} from '@kerfed/common-ui/components';
import { useOrdersQuery } from '../query/queries';

/**
 * Get an appropriate badge color for a given status string.
 *
 * @param status i.e. "open"
 */
function statusBadge(status?: string) {
  if (status === 'open') {
    return 'badge-primary';
  } else if (status === 'paid') {
    return 'badge-success';
  } else {
    return 'badge-info';
  }
}

const OrderRow = ({ order }: { order: Components.Schemas.Order }) => (
  <tr>
    <td>
      <Link to={'/orders/' + order.id} className="header">
        <FormattedId id={order.id} />
      </Link>
    </td>

    <td>
      <FormattedDate date={order?.dateCreated} />
    </td>
    <td className="align-center">
      <div className={`badge ${statusBadge(order?.status)}`}>
        {order?.status}
      </div>
    </td>
  </tr>
);

export default ({ shopId }) => {
  const [offset, setOffset] = useState(0);
  const { isLoading, data, error } = useOrdersQuery(offset, shopId);
  const orders = data?.items;
  const next = data?.next;
  const perPage = 20;

  return (
    <div>
      {error ? (
        Message(error?.message, 'Error Retrieving Order List')
      ) : orders && orders.length > 0 ? (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th colSpan={3}>
                Showing {offset}-{offset + orders.length}
                <span className="float-right">
                  <button
                    className="btn btn-outline-secondary mx-1"
                    onClick={() => setOffset(Math.max(offset - perPage, 0))}
                    disabled={offset <= 0}
                  >
                    Prev
                  </button>
                  <button
                    className="btn btn-outline-secondary"
                    onClick={() => setOffset(next || offset)}
                    disabled={orders?.length < perPage}
                  >
                    Next
                  </button>
                </span>
              </th>
            </tr>
            <tr>
              <th>Order Number</th>
              <th>Created</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {orders.map(order => (
              <OrderRow key={order.id} order={order} />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={3}>
                Showing {offset}-{offset + orders.length}
                <span className="float-right">
                  <button
                    className="btn btn-outline-secondary mx-1"
                    onClick={() => setOffset(Math.max(offset - perPage, 0))}
                    disabled={offset <= 0}
                  >
                    Prev
                  </button>
                  <button
                    className="btn btn-outline-secondary"
                    onClick={() => setOffset(next || offset)}
                    disabled={orders?.length < perPage}
                  >
                    Next
                  </button>
                </span>
              </th>
            </tr>
          </tfoot>
        </table>
      ) : (
        <div>
          {isLoading ? (
            <div data-iframe-height={true} className="align-center loader">
              <div className="spinner-border" role="status" />
              <h3 className="p-3">Loading Orders</h3>
            </div>
          ) : (
            <h4>No orders found.</h4>
          )}
        </div>
      )}
    </div>
  );
};
