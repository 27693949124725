/**
 * api.ts
 *
 * This is the client-side interface for the Kerfed V1 REST API.
 *
 * Documentation:
 * https://api.kerfed.com/docs/v1/
 */

import { auth } from '@kerfed/common/api/firebase';

import config_public from '@kerfed/config/config-public.json';

const baseUri = `${config_public?.firebase?.functionsURL}/v1`;

// Make an authenticated call to a Firebase HTTP Cloud Function.
const callAuthenticated = async ({
  uri,
  method,
  queries,
  params,
  dateSince,
}: {
  uri: string;
  method: string;
  queries?: { [key: string]: string | number };
  params?: any;
  dateSince?: Date;
}) => {
  const fullQueries = queries || {};

  // Create authentication token.
  const currentUser = auth.currentUser;
  if (!currentUser)
    throw new Error('Must be logged in to make authenticated call.');
  const bearerToken = await currentUser.getIdToken();

  // Create request headers with authentication.
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${bearerToken}`,
    'Content-Type': 'application/json',
  };
  if (dateSince) {
    headers['If-Modified-Since'] = dateSince.toISOString();
  }

  // Append a link token to the call if one is present in the URI.
  const urlParams = new URLSearchParams(window.location.search);
  const linkToken = urlParams.get('token');
  if (linkToken) fullQueries['token'] = linkToken;

  // Construct the query string.
  const queryString = Object.entries(fullQueries)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  const fullUri = queryString ? `${uri}?${queryString}` : uri;

  // Retry the call several times before returning failure.
  return fetch(fullUri, {
    method,
    headers,
    body: JSON.stringify(params),
  });
};

// default messages for each response status code.
const statusMessages = {
  304: 'Resource not modified.',
  401: 'Unauthorized to access resource!',
  404: "Requested resource doesn't exist.",
};

const ResponseError = async (res: Response) => {
  /**
   * Format a response that didn't succeed into an Error-like object
   * that has `name` and `message` properties so we can correctly
   * handle different response codes upstream.
   */
  let message;
  try {
    // may not exist or may not be JSON

    const blob = await res.json();
    // functions can respond with a 'message' or 'error' field
    message = blob.message || blob.error;
  } catch (err) {
    console.log({
      err,
      name: err.name,
      body: res.body(),
      status: res.status,
    });
  } finally {
    return {
      name: res.status,
      message: message || statusMessages[res.status] || 'Unexpected error!',
    };
  }
};

export const shopGet = async (shopId: string, dateSince?: Date) => {
  const uri = `${baseUri}/shops/${shopId}`;
  const res = await callAuthenticated({ uri, method: 'GET', dateSince });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.ShopGet.Responses.$200;
};

export const shopDiscount = async (
  shopId: string,
  args: Paths.ShopDiscount.RequestBody,
) => {
  const uri = `${baseUri}/shops/${shopId}/discount`;
  const res = await callAuthenticated({ uri, method: 'POST', params: args });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.ShopDiscount.Responses.$200;
};

export const orderCreate = async (args: Paths.OrderCreate.RequestBody) => {
  const uri = `${baseUri}/orders`;
  const res = await callAuthenticated({ uri, method: 'POST', params: args });
  if (res.status !== 201) throw await ResponseError(res);
  return (await res.json()) as Paths.OrderCreate.Responses.$201;
};

export const orderGet = async (orderId: string, dateSince?: Date) => {
  const uri = `${baseUri}/orders/${orderId}`;
  const res = await callAuthenticated({ uri, method: 'GET', dateSince });
  if (res.status === 304) return undefined;
  // will throw 401, 404, etc
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.OrderGet.Responses.$200;
};

/**
 * Get a list of orders for either a user or shop.
 *
 * @param offset record count to offset by
 * @param limit number of records to limit query to
 * @param shopId get orders for this shop if user is admin
 * @returns
 */
export const orderList = async (
  offset?: number,
  limit?: number,
  shopId?: string,
) => {
  const uri = `${baseUri}/orders`;
  const queries = {
    offset: offset || 0,
    limit: limit || 20,
    ...(shopId && { shopId }),
  };
  const res = await callAuthenticated({
    uri,
    method: 'GET',
    queries,
  });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.OrderList.Responses.$200;
};

export const orderPurchase = async (
  orderId: string,
  args: Paths.OrderPurchase.RequestBody,
) => {
  const uri = `${baseUri}/orders/${orderId}/purchase`;
  const res = await callAuthenticated({ uri, method: 'POST', params: args });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.OrderPurchase.Responses.$200;
};

export const orderShare = async (
  orderId: string,
  args: Paths.OrderShare.RequestBody,
) => {
  const uri = `${baseUri}/orders/${orderId}/share`;
  const res = await callAuthenticated({ uri, method: 'POST', params: args });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.OrderShare.Responses.$200;
};

export const orderShipping = async (
  orderId: string,
  args: Paths.OrderShipping.RequestBody,
) => {
  const uri = `${baseUri}/orders/${orderId}/shipping`;
  const res = await callAuthenticated({ uri, method: 'POST', params: args });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.OrderShipping.Responses.$200;
};

export const orderPartGet = async (
  orderId: string,
  partId: string,
  dateSince?: Date,
) => {
  const uri = `${baseUri}/orders/${orderId}/parts/${partId}`;
  const res = await callAuthenticated({
    uri,
    method: 'GET',
    dateSince,
  });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.OrderPartGet.Responses.$200;
};

export const orderPartList = async (
  orderId: string,
  offset?: number,
  limit?: number,
) => {
  const uri = `${baseUri}/orders/${orderId}/parts`;
  const res = await callAuthenticated({
    uri,
    method: 'GET',
    queries: {
      offset: offset || 0,
      limit: limit || 100,
    },
  });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.OrderPartList.Responses.$200;
};

export const quoteCreate = async (shopId: string, uploadIds: string[]) => {
  const uri = `${baseUri}/quotes`;
  const res = await callAuthenticated({
    uri,
    method: 'POST',
    params: {
      shopId,
      uploadIds,
    },
  });
  if (res.status !== 201) throw await ResponseError(res);
  return (await res.json()) as Paths.QuoteCreate.Responses.$201;
};

export const quoteGet = async (quoteId: string, dateSince?: Date) => {
  const uri = `${baseUri}/quotes/${quoteId}`;
  const res = await callAuthenticated({
    uri,
    method: 'GET',
    dateSince,
  });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.QuoteGet.Responses.$200;
};

export const quoteList = async (
  offset?: number,
  limit?: number,
  shopId?: string,
) => {
  const queries = {
    offset: offset || 0,
    limit: limit || 100,
    ...(shopId && { shopId }),
  };
  const uri = `${baseUri}/quotes`;
  const res = await callAuthenticated({
    uri,
    queries,
    method: 'GET',
  });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.QuoteList.Responses.$200;
};

export const quoteShare = async (
  quoteId: string,
  args: Paths.QuoteShare.RequestBody,
) => {
  const uri = `${baseUri}/quotes/${quoteId}/share`;
  const res = await callAuthenticated({ uri, method: 'POST', params: args });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.QuoteShare.Responses.$200;
};

export const quoteFileCreate = async (
  quoteId: string,
  args: Paths.QuoteFileCreate.RequestBody,
) => {
  const uri = `${baseUri}/quotes/${quoteId}/files`;
  const res = await callAuthenticated({ uri, method: 'POST', params: args });
  if (res.status !== 201) throw await ResponseError(res);
  return (await res.json()) as Paths.QuoteFileCreate.Responses.$201;
};

export const quoteFileGet = async (
  quoteId: string,
  fileId: string,
  dateSince?: Date,
) => {
  const uri = `${baseUri}/quotes/${quoteId}/files/${fileId}`;
  const res = await callAuthenticated({ uri, method: 'GET', dateSince });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.QuoteFileGet.Responses.$200;
};

export const quoteFileList = async (
  quoteId: string,
  offset?: number,
  limit?: number,
  dateSince?: Date,
) => {
  const uri = `${baseUri}/quotes/${quoteId}/files`;
  const res = await callAuthenticated({
    uri,
    method: 'GET',
    queries: {
      offset: offset || 0,
      limit: limit || 100,
    },
    dateSince,
  });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.QuoteFileList.Responses.$200;
};

export const quotePartCreate = async (
  quoteId: string,
  args: Paths.QuotePartCreate.RequestBody,
) => {
  const uri = `${baseUri}/quotes/${quoteId}/parts`;
  const res = await callAuthenticated({
    uri,
    method: 'POST',
    params: args,
  });
  if (res.status !== 201) throw await ResponseError(res);
  return (await res.json()) as Paths.QuotePartCreate.Responses.$201;
};

export const quotePartGet = async (
  quoteId: string,
  partId: string,
  dateSince?: Date,
) => {
  const uri = `${baseUri}/quotes/${quoteId}/parts/${partId}`;
  const res = await callAuthenticated({
    uri,
    method: 'GET',
    dateSince,
  });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.QuotePartGet.Responses.$200;
};

export const quotePartList = async (
  quoteId: string,
  offset?: number,
  limit?: number,
  dateSince?: Date,
) => {
  const uri = `${baseUri}/quotes/${quoteId}/parts`;
  const res = await callAuthenticated({
    uri,
    method: 'GET',
    queries: {
      offset: offset || 0,
      limit: limit || 100,
    },
    dateSince,
  });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.QuotePartList.Responses.$200;
};

export const quotePrice = async (
  quoteId: string,
  args: Paths.QuotePrice.RequestBody,
) => {
  const uri = `${baseUri}/quotes/${quoteId}/price`;
  const res = await callAuthenticated({ uri, method: 'POST', params: args });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.QuotePrice.Responses.$200;
};

type ProgressCallback = (loaded: number, total: number) => any;

/**
 * Uploads a file using the REST API.
 *
 * This generates an upload ID, gets a signed URL to use for
 * uploading to that upload ID, performs the upload, then returns the ID.
 * @param file
 */
export const uploadFile = async (
  file: File,
  onProgress?: ProgressCallback,
): Promise<string> => {
  // Make an initial call to generate an upload ID.
  const uri = `${baseUri}/uploads`;
  const contentType = file.type || 'application/octet-stream';
  const resCreate = await callAuthenticated({
    uri,
    method: 'POST',
    params: {
      filename: file.name,
      contentType,
    },
  });
  if (resCreate.status !== 201) throw await ResponseError(resCreate);
  const { id, url } = await resCreate.json();

  // Upload the file to the URL.
  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);
    xhr.setRequestHeader('x-goog-meta-filename', file.name);
    xhr.setRequestHeader('Content-Type', contentType);

    if (xhr.upload && onProgress) {
      xhr.upload.onprogress = evt => {
        if (!evt.lengthComputable) return;
        onProgress(evt.loaded, evt.total);
      };
    }

    xhr.onload = () =>
      xhr.status == 200
        ? res(id)
        : rej(new Error(xhr.responseText || 'Network request failed'));

    xhr.onerror = () =>
      rej(new Error(xhr.responseText || 'Network request failed'));

    xhr.send(file);
  });
};

export const userGet = async (userId: string) => {
  const uri = `${baseUri}/users/${userId}`;
  const res = await callAuthenticated({
    uri,
    method: 'GET',
  });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.UserGet.Responses.$200;
};

export const userUpdate = async (
  userId: string,
  user: Components.Schemas.User,
) => {
  const uri = `${baseUri}/users/${userId}`;
  const res = await callAuthenticated({
    uri,
    method: 'POST',
    params: { userId, user },
  });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.text()) as Paths.UserUpdate.Responses.$200;
};

export const userKeyList = async (userId: string) => {
  const uri = `${baseUri}/users/${userId}/keys`;
  const res = await callAuthenticated({
    uri,
    method: 'GET',
  });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.UserKeyList.Responses.$200;
};

export const userKeyCreate = async (
  userId: string,
  args: Paths.UserKeyCreate.RequestBody,
) => {
  const uri = `${baseUri}/users/${userId}/keys`;
  const res = await callAuthenticated({
    uri,
    method: 'POST',
    params: args,
  });
  if (res.status !== 201) throw await ResponseError(res);
  return (await res.json()) as Paths.UserKeyCreate.Responses.$201;
};

export const userKeyDelete = async (userId: string, keyId: string) => {
  const uri = `${baseUri}/users/${userId}/keys/${keyId}`;
  const res = await callAuthenticated({
    uri,
    method: 'DELETE',
  });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.UserKeyDelete.Responses.$200;
};

export const shopEditGet = async (shopId: string) => {
  const uri = `${baseUri}/shops/${shopId}/edit`;
  const res = await callAuthenticated({
    uri,
    method: 'GET',
  });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.ShopEditGet.Responses.$200;
};

export const shopEditPut = async (shopId: string, shopContent: any) => {
  const uri = `${baseUri}/shops/${shopId}/edit`;
  const res = await callAuthenticated({
    uri,
    method: 'PUT',
    params: { shopId, shopContent },
  });
  if (res.status !== 200) throw await ResponseError(res);
  return (await res.json()) as Paths.ShopEditPut.Responses.$200;
};
