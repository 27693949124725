import { RootState } from '..';
import { createSelector } from 'reselect';
import { getShop } from '../shop/selectors';

interface QuoteProps {
  quoteId: string;
}

interface FileProps extends QuoteProps {
  fileId: string;
}

interface PartProps extends QuoteProps {
  partId: string;
}

interface MethodProps extends PartProps {
  methodId: string;
}

type Method =
  | Components.Schemas.MethodBase
  | Components.Schemas.MethodAdd
  | Components.Schemas.MethodBent
  | Components.Schemas.MethodCots
  | Components.Schemas.MethodFlat
  | Components.Schemas.MethodMill
  | Components.Schemas.MethodRoll
  | Components.Schemas.MethodTurn;

export const getQuote = (state: RootState, { quoteId }: QuoteProps) =>
  state.quotes[quoteId];

export const getOptions = (state: RootState, props: QuoteProps) =>
  getQuote(state, props)?.options;

export const getQuoteContent = (state: RootState, props: QuoteProps) =>
  getQuote(state, props)?.content;

export const getQuoteComputed = (state: RootState, props: QuoteProps) =>
  getQuote(state, props)?.computed;

export const getQuoteDefaults = (state: RootState, props: QuoteProps) =>
  getQuoteContent(state, props)?.defaults;

export const getQuoteDefaultOptions = (state: RootState, props) =>
  getQuoteDefaults(state, props)?.options;

const getQuoteOptions = (state: RootState, props: QuoteProps) =>
  getOptions(state, props)?.quote;

export const makeGetComputedQuoteOptions = () =>
  createSelector(
    [getQuoteOptions, getQuoteDefaultOptions],
    (options, defaults) => {
      if (!defaults) return options;
      if (!options) return defaults;
      return {
        ...defaults,
        ...options,
      };
    },
  );

export const getFiles = (state: RootState, { quoteId }: QuoteProps) =>
  state.quotes[quoteId]?.files;

export const getFile = (state: RootState, props: FileProps) =>
  getFiles(state, props)?.[props.fileId];

export const getParts = (state: RootState, { quoteId }: QuoteProps) => {
  return state.quotes[quoteId]?.parts;
};
export const getPart = (state: RootState, props: PartProps) =>
  getParts(state, props)?.[props.partId];

export const getPartDefaults = (state: RootState, props) => {
  return getPart(state, props)?.defaults;
};
export const getPartDefaultOptions = (state: RootState, props) => {
  return getPartDefaults(state, props)?.options;
};
const getPartOptions = (state: RootState, props: PartProps) =>
  getOptions(state, props)?.part?.[props.partId];

export const makeGetComputedPartOptions = () =>
  createSelector(
    [getPartOptions, getPartDefaultOptions],
    (options, defaults) => {
      if (!defaults) return options;
      if (!options) return defaults;
      return {
        ...defaults,
        ...options,
      };
    },
  );

export const getMethod = (
  state: RootState,
  { quoteId, partId, methodId }: MethodProps,
) => state.quotes[quoteId]?.parts?.[partId]?.methods?.[methodId] as Method;

export const getMethodSpec = (state: RootState, props: MethodProps) =>
  getQuote(state, props)?.specs?.[props.partId]?.[props.methodId];

export const getMethodDefaults = (state: RootState, props: MethodProps) =>
  getMethod(state, props)?.defaults;

export const getMethodIsComputing = (state: RootState, props: MethodProps) =>
  getQuoteComputed(state, props)?.isComputing?.[props.partId]?.[props.methodId];

export const getMethodDefaultOptions = (state: RootState, props: MethodProps) =>
  getMethod(state, props)?.defaults?.options;

const getMethodOptions = (state: RootState, props: MethodProps) =>
  getOptions(state, props)?.method?.[props.partId]?.[props.methodId];

export const makeGetComputedMethodOptions = () =>
  createSelector(
    [getMethodOptions, getMethodDefaultOptions],
    (options, defaults) => {
      if (!defaults) return options;
      if (!options) return defaults;
      return {
        ...defaults,
        ...options,
        process: {
          ...defaults?.process,
          ...options?.process,
        },
      };
    },
  );

export const getMethodId = (state, props: PartProps) =>
  getPartOptions(state, props)?.methodId;
