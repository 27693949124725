import { RootState } from '..';

interface OrderProps {
  orderId: string;
}

interface PartProps extends OrderProps {
  partId: string;
}

export const getOrder = (state: RootState, { orderId }: OrderProps) =>
  state.orders[orderId];

export const getOrderContent = (state: RootState, props: OrderProps) =>
  getOrder(state, props)?.content;

export const getOrderComputed = (state: RootState, props: OrderProps) =>
  getOrder(state, props)?.computed;

export const getParts = (state: RootState, { orderId }: OrderProps) =>
  getOrder(state, { orderId })?.parts;

export const getPart = (state: RootState, props: PartProps) =>
  getParts(state, props)?.[props.partId];

export const getBilling = (state: RootState, { orderId }: OrderProps) =>
  getOrder(state, { orderId })?.options?.billing;

export const getShipping = (state: RootState, { orderId }: OrderProps) =>
  getOrder(state, { orderId })?.options?.shipping;

export const getShippingMethods = (state: RootState, { orderId }: OrderProps) =>
  getOrder(state, { orderId })?.computed?.shipping?.methods;

export const getShippingParcels = (state: RootState, { orderId }: OrderProps) =>
  getOrder(state, { orderId })?.computed?.shipping?.parcels;

export const getShippingMethodId = (
  state: RootState,
  { orderId }: OrderProps,
) => getOrder(state, { orderId })?.options?.shipping?.method?.id;

export const getShippingMethod = (state: RootState, { orderId }: OrderProps) =>
  getOrder(state, { orderId })?.options?.shipping?.method;

export const getTaxes = (state: RootState, { orderId }: OrderProps) =>
  // note that taxes with the token lives in COMPUTED
  getOrder(state, { orderId })?.computed?.shipping?.taxes;

export const getIsShipping = (state: RootState, { orderId }: OrderProps) =>
  !!getOrder(state, { orderId })?.computed?.isShipping;

export const getIsShippingEditing = (
  state: RootState,
  { orderId }: OrderProps,
) => !!getOrder(state, { orderId })?.computed?.isShippingEditing;

export const getIsOrdering = (state: RootState, { orderId }: OrderProps) =>
  !!getOrder(state, { orderId })?.computed?.isOrdering;
