import React from 'react';
import { Link } from 'react-router-dom';
import { QuoteUploader } from '../components';

export default () => (
  <div className="container-sm article-text bg-white p-0">
    <a href="https://www.youtube.com/watch?v=slfWvma1s_M" target="_blank">
      <video
        width="100%"
        height="100%"
        autoPlay={true}
        loop={true}
        muted={true}
      >
        <source src="/static/images/robot.mp4" type="video/mp4" />
      </video>
    </a>
    <div className="p-3">
      <div>
        <h1 className="my-2">Tutorial</h1>
        <p>
          The great part about 3D printing is the interface: take a 3D model,
          send it to a printer, get your parts back. Kerfed is an application
          designed around the idea that you could have the same interface as a
          3D printer but for many different manufacturing processes.
        </p>
        <p>
          Upload a full assembly and Kerfed will automatically analyze the
          geometry of every part, pick which process can fabricate that part,
          and even infer things like hole tolerances from the assembly
          interferences. Currently, the
          <Link to="/technology"> Kerfed Engine </Link>
          supports conventional CNC milling, 3D printing, waterjet and laser,
          press brake bending, and geometric detection of certain commercially
          available parts (primarily dowel pins and ball bearings). Check out{' '}
          <Link to="/pricing">more detail</Link> about supported processes.
        </p>
      </div>
      <div>
        <h2>Uploading Your Assembly</h2>
        <p>
          Getting things into the Kerfed Engine is easy: export the file from
          your CAD system, and upload the file to a new quote. On the quote page
          you can drag and drop the file, or click the box for an upload dialog.
        </p>
        <p>
          Once you have a new quote, you can configure the part for material,
          thickness, finishes, and any postprocessing operations (i.e.{' '}
          <a href="https://blog.kerfed.com/posts/counter/">countersinks,</a>{' '}
          reams, welded seams). We recommend clicking on the proof drawings in
          the "Part Info" column to review dimensions and geometry; this is the
          exact geometry that will be released to manufacturing when orders are
          placed.
        </p>
        <QuoteUploader shopId="kerfed" />
      </div>
      <div>
        <h2>Exporting from 3D CAD</h2>
        <img className="img-fluid my-3" src="/static/images/tutorial_2.png" />
        <p>
          Solidworks, Autodesk Inventor, and other CAD systems can export in
          many different formats. <strong>STEP</strong> is the universal format
          we recommend when using Solidworks, Autodesk Inventor, Fusion360, or
          other systems.
        </p>
        <p>
          Other formats supported include{' '}
          <strong>STEP, 3DXML, GLTF/GLB, R14 ASCII DXF</strong>, DWG, AI, CDR,
          PLY, STL, OBJ, SVG
        </p>
      </div>
      <div>
        <h2>Exporting from 2D CAD</h2>
        <img
          className="img-fluid my-3"
          src="/static/images/dxf_comparison.png"
        />
        <p>
          If you would like tight control over entities and you have a clean
          <strong> annotation-free closed contour</strong> 2D drawing we
          recommend uploading it as an <strong>R14 ASCII DXF. </strong>
        </p>
        <p>
          <a href="https://blog.kerfed.com/posts/laser/">Check out a guide</a>{' '}
          for how to generate 2D drawings manually in Solidworks. Here are some{' '}
          <a href="https://blog.kerfed.com/posts/dxf/">further tips</a> on
          outputting usable DXF files, which can be summarized as:
        </p>
        <ul>
          <li>Check the DXF to make sure all geometry represents cut lines.</li>
          <li>Export the file as R14 ASCII DXF.</li>
          <li>After uploading to automatic quoting, check the drawings.</li>
        </ul>
      </div>
      <div className="segmentRectangle" />
    </div>
  </div>
);
