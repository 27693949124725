import { createSlice } from '@reduxjs/toolkit';

import { ShopState } from './types';
import * as reducers from './reducers';

const INITIAL_STATE: ShopState = {};

const slice = createSlice({
  name: 'shops',
  initialState: INITIAL_STATE,
  reducers,
});

export const actions = slice.actions;
export const reducer = slice.reducer;
