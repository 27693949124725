import '@kerfed/common-ui/assets/helpers.css';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RootState } from '../store';
import { getQuote, getQuoteComputed } from '../store/quote/selectors';
import { QuotePricing } from '../store/quote/types';
import AddPartButton from './AddPartButton';
import EditablePartRow from './EditablePartRow';
import ErrorBoundary from './ErrorBoundary';
import SafePrice from './SafePrice';

type Props = {
  quoteId: string;
  parts?: { [partId: string]: Components.Schemas.QuotePart };
  price?: QuotePricing;
};

export const TotalsTableFooter = ({ price }: { price?: QuotePricing }) => {
  return (
    <tbody className="partlist-total">
      <tr>
        <th colSpan={3}>
          <h2>Total Price</h2>
        </th>
      </tr>
      <tr>
        <th colSpan={2}>
          <h5>Fabrication Subtotal</h5>
        </th>
        <th>
          <h5>
            <SafePrice
              price={price?.units}
              message="One or more parts are missing required options."
            />
          </h5>
        </th>
      </tr>
      {Object.entries(price?.setups || {})
        .sort()
        .filter(([_, s]) => s.price > 0)
        .map(([setupId, setup]) => (
          <tr key={setupId}>
            <td colSpan={2}>
              <h5>
                Setup x{Math.ceil(setup.utilization)}: {setupId}
              </h5>
            </td>
            <td>
              <h5>
                <SafePrice
                  price={setup.price * Math.ceil(setup.utilization)}
                  message=""
                />
              </h5>
            </td>
          </tr>
        ))}

      <tr>
        <th colSpan={2}>
          <h5>Discount</h5>
        </th>
        <th>
          <h5 style={{ color: 'green' }}>
            <SafePrice
              price={price?.discount}
              message="Error applying discount code."
            />
          </h5>
        </th>
      </tr>
      <tr>
        <th colSpan={2}>
          <h2>Total</h2>
        </th>
        <th>
          <h2>
            <SafePrice
              price={price?.total}
              message="One or more parts are missing required options."
            />
          </h2>
        </th>
      </tr>
    </tbody>
  );
};

const isFabricatable = (part: Components.Schemas.QuotePart) =>
  part?.defaults?.options?.methodId &&
  part?.defaults?.options?.methodId !== 'manual';

const EditablePartList = (props: Props) => {
  const { quoteId, parts, price } = props;
  const partsList = Object.entries(parts || {});

  return parts ? (
    <table className="table table-bordered table-responsive">
      <thead>
        <tr>
          <th>Part</th>
          <th className="price-column">Unit Price</th>
          <th className="price-column">Total Price</th>
        </tr>
      </thead>
      <tbody>
        {partsList
          .filter(([_, part]) => isFabricatable(part))
          .map(([partId, _]) => (
            <ErrorBoundary key={partId}>
              <EditablePartRow quoteId={quoteId} partId={partId} />
            </ErrorBoundary>
          ))}
        {partsList
          .filter(([_, part]) => !isFabricatable(part))
          .map(([partId, _]) => (
            <ErrorBoundary key={partId}>
              <EditablePartRow quoteId={quoteId} partId={partId} />
            </ErrorBoundary>
          ))}
        <tr>
          <td colSpan={3} className="my-5">
            <AddPartButton quoteId={quoteId} />
          </td>
        </tr>
      </tbody>

      {parts && (
        <ErrorBoundary>
          <TotalsTableFooter price={price} />
        </ErrorBoundary>
      )}
    </table>
  ) : (
    <div data-iframe-height={true} className="align-center loader">
      <div className="spinner-border" role="status" />
      <h3 className="p-3">Loading Parts</h3>
    </div>
  );
};

const mapStateToProps = () => (state: RootState, props: Props) => {
  const quote = getQuote(state, props);
  const parts = quote?.parts;
  const price = getQuoteComputed(state, props)?.price;

  return {
    parts,
    price,
  };
};

export default compose<Props, { quoteId: string }>(connect(mapStateToProps))(
  EditablePartList,
);
