import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as api from '../api';
import * as actions from '../store/quote/actions';

type Props = { quoteId: string };

export const AddPartButton = ({ quoteId }: Props) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [isClicked, setClicked] = useState(false);

  const onClick = async () => {
    // hide the input and call cloud function
    setClicked(true);
    try {
      const part = await api.quotePartCreate(quoteId, {
        name: name || 'Manual Operation',
      });
      dispatch(actions.partUpdate({ quoteId, partId: part.id, part }));
      setName('My Custom Operation');
    } finally {
      setClicked(false);
    }
  };

  const onChange = event => setName(event.target.value);

  // Hide button until function returns.

  return (
    <div className="add-part-button">
      <div className="input-group">
        <div className="input-group-prepend">
          <button
            className="btn btn-outline-primary"
            type="button"
            disabled={isClicked}
            onClick={onClick}
          >
            Add Custom Operation
          </button>
        </div>
        <input
          className="form-control"
          placeholder="My Custom Operation"
          value={name}
          onChange={onChange}
        />
      </div>
      {isClicked ? (
        <div className="progress my-2">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            style={{ width: '100%' }}
          ></div>
        </div>
      ) : null}
    </div>
  );
};

export default AddPartButton;
