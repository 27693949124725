import React from 'react';
import { Link } from 'react-router-dom';
import { RightLeftCard } from '../components';

export default () => (
  <div className="landing">
    <div className="bigbanner">
      <div className="banner align-center">
        <img src="/static/images/feature_white.svg" />
        <div className="container-sm pitch">
          <h1>Modern Automated Manufacturing</h1>
          <h2>
            Automation isn't just robots: automatic quoting, ordering, and CAM
            means parts faster.
          </h2>
        </div>
      </div>
    </div>
    <div className="container-lg">
      <div className="grid">
        <div className="row">
          <div className="col-md">
            <div className="grid">
              <div className="row">
                <div className="card col-md m-2">
                  <img className="img-fluid" src="/static/images/step1.png" />
                  <div className="card-body">
                    <div className="card-title align-center">
                      <h2>Step 1</h2>
                    </div>
                    <h5 className="card-text">
                      Upload a 2D or 3D assembly or part from Solidworks or
                      other CAD system.
                    </h5>
                  </div>
                </div>
                <div className="card col-md m-2">
                  <img className="img-fluid" src="/static/images/step2.svg" />
                  <div className="card-body">
                    <div className="card-title align-center">
                      <h2>Step 2</h2>
                    </div>
                    <h5 className="card-text">
                      The Kerfed Engine analyzes the geometry of every part in
                      the assembly and quotes in seconds.
                    </h5>
                  </div>
                </div>
                <div className="card col-md m-2">
                  <img className="img-fluid" src="/static/images/step3.png" />
                  <div className="card-body">
                    <div className="card-title align-center">
                      <h2>Step 3</h2>
                    </div>
                    <h5 className="card-text">
                      Ordered parts are immediately released to manufacturing
                      with generated cut-ready files.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" />
      </div>

      <RightLeftCard
        cards={[
          {
            title: 'Tutorial',
            text:
              'The Kerfed Engine can quote hundreds of parts in under a minute: check out a quick guide on how to get your parts quoted.',
            image: '/static/images/tutorial.jpg',
            link: '/help',
          },
          {
            title: 'Video Demo',
            text: 'Check out a walkthrough of the quoting process',
            image: '/static/images/youtube.png',
            link: 'https://www.youtube.com/watch?v=slfWvma1s_M',
          },
        ]}
      />
    </div>

    <div className="explain-dark">
      <div className="container-sm">
        <div className="grid">
          <div className="row">
            <div className="col-md">
              <h2 className="pitch">Supported Machines</h2>
              <ul>
                <li>
                  <b>CNC milling machines</b> can cut pocketed parts easily and
                  from many different materials.
                </li>
                <li>
                  <b>CNC lathes</b> can produce precise radially symmetric parts
                  quickly.
                </li>
                <li>
                  <b>Waterjets</b> can cut nearly any material up to 4" thick
                  with no heat effected zone.
                </li>
                <li>
                  <b>kW-Class Fiber Lasers</b>can cut thin metals at 1000+
                  inches per minute.
                </li>
                <li>
                  <b>CNC press brakes</b> can form complicated faceted sheet
                  metal parts quickly.
                </li>
                <li>
                  <b>CNC slip rollers</b> can produce complex cylindrical parts
                  from up to 3/16" thick sheet.
                </li>

                <li>
                  <Link
                    style={{ color: 'white', textDecoration: 'underline' }}
                    to="/pricing"
                  >
                    Full process overview
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md">
              <h2 className="pitch">Materials</h2>
              <ul>
                <li>
                  <b>Aluminum alloys</b>, including Aluminum 6061-T6, Aluminum
                  5052-H32, Aluminum 3003-H14, Aluminum 7071-T6 High-Strength
                  Aerospace, Aluminum MIC-6 cast plate.
                </li>
                <li>
                  <b>Stainless steels</b>, including 304 Stainless and 316
                  Stainless
                </li>
                <li>
                  <b>Alloy steels</b>, including A36 hot-rolled plate,
                  cold-rolled sheet, galvanized sheet, A588 weathering steel,
                  and more.
                </li>
                <li>
                  <b>Plastics</b>, including Delrin/Acetal, Acrylic,
                  Polycarbonate, Polyurethane, HDPE, LDPE, and more.
                </li>
                <li>
                  <Link
                    style={{ color: 'white', textDecoration: 'underline' }}
                    to="/materials/kerfed"
                  >
                    Full material database
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <h2 className="pitch">Secure By Default</h2>
              <img className="w-25" src="/static/images/gcplogo.svg" />
              <p>
                Files are transmitted via secure SSL connections to the
                ISO-27001 and FedRAMP certified Google Cloud Platform.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
