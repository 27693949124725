/**
 * RightLeftCard.ts
 * -------------------
 *
 * Display an image-text block in alternating right and left.
 */
import React from 'react';

import { FancyCard, CardProps } from './FancyCard';

type Props = {
  cards: Array<CardProps>;
};

export default ({ cards }: Props) => {
  return (
    <div className="grid">
      {cards.map((card: CardProps, i: number) => (
        <div key={`cardRow${i}`} className="row my-5">
          {i % 2 ? <div className="col-lg-5" /> : null}
          <FancyCard {...card} />
        </div>
      ))}
    </div>
  );
};
