import React from 'react';
import { QuoteUploader, QuoteList } from '../components';

export default () => {
  return (
    <div className="container-md bg-white p-3">
      <h1>View Existing Quotes</h1>
      <QuoteList />
      <hr />
      <h2>Get A New Quote</h2>
      <QuoteUploader shopId="kerfed" />
      <div className="segmentRectangle" />
    </div>
  );
};
