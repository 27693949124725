import {
  FormattedDate,
  FormattedId,
} from '@kerfed/common-ui/components/formatting';
import { EmbedContext } from '@kerfed/common-ui/contexts';
import React, { useContext, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import {
  EditableShippingSection,
  ErrorBoundary,
  PartList,
  ShopInfo,
  StripeBillingSection,
} from '../components';
import { TotalsFooter } from '../components/PartList';
import { ShareOrderButton } from '../components/ShareButton';
import { useSelector } from '../store';

import '../styles/sticky.scss';

import withAuthentication from '../store/auth/withAuthentication';
import {
  billingUpdate,
  orderPurchase,
  shippingUpdate,
} from '../store/order/actions';
import {
  getBilling,
  getIsOrdering,
  getIsShipping,
  getIsShippingEditing,
  getOrder,
  getOrderComputed,
  getOrderContent,
  getShipping,
  getShippingMethodId,
} from '../store/order/selectors';
import withOrderDetail from '../store/order/withOrderDetail';
import { getShop } from '../store/shop/selectors';
import withShopDetail from '../store/shop/withShopDetail';
import { validateAddress } from '@kerfed/common/utils';

const statuses = {
  open: 0,
  paid: 1,
  accepted: 2,
  shipped: 3,
  received: 4,
};

const checkCompleted = (order?: Components.Schemas.Order) =>
  statuses[order?.status || ''] >= statuses.paid;

const PaymentButton = ({
  error,
  onPayment,
  disabled,
}: {
  error?: string;
  onPayment(): any;
  disabled?: boolean;
}) => {
  return (
    <div className="btn-group" role="group" aria-label="Basic example">
      <button
        className="btn btn-lg btn-primary"
        disabled={disabled}
        onClick={onPayment}
      >
        Place Order &#8594;
      </button>
      {!!error && <div className="alert alert-danger my-0">{error}</div>}
    </div>
  );
};

const OrderView = ({ orderId }: { orderId: string }) => {
  const dispatch = useDispatch();
  const { isEmbedded } = useContext(EmbedContext);

  const shopId = useSelector(
    state => getOrder(state, { orderId })?.content?.shopId || '',
  );
  const isOrdering = useSelector(state => getIsOrdering(state, { orderId }));

  const purchaseError = useSelector(
    state => getOrder(state, { orderId })?.errors?.purchase,
  );
  const order = useSelector(state => getOrderContent(state, { orderId }))!;
  const computed = useSelector(state => getOrderComputed(state, { orderId }))!;
  const rawDescriptions = useSelector(
    state => getShop(state, { shopId })?.options,
  );

  // update shipping field changes without querying shipping API
  const onShippingUpdate = shipping =>
    dispatch(shippingUpdate({ orderId, shipping }));
  // update shipping field changes without querying shipping API
  const onBillingUpdate = billing =>
    dispatch(billingUpdate({ orderId, billing }));

  const onOrderPurchase = () => dispatch(orderPurchase({ orderId }));

  const isCompleted = checkCompleted(order);
  const { quoteId } = order;
  const { price } = computed;

  // Convert descriptions from array to mapping.
  // TODO(PV): Figure out a single description representation.
  const descriptions = rawDescriptions;

  const [paymentError, setError] = useState('');

  // Check various conditions that would prevent purchasing.
  const hasValidPrice = isFinite(price?.units || NaN);

  const hasValidBilling = useSelector(
    state => !!getBilling(state, { orderId })?.token,
  );

  const hasValidShipping = useSelector(
    state =>
      !getIsShipping(state, { orderId }) &&
      !getIsShippingEditing(state, { orderId }) &&
      !!getShipping(state, { orderId }) &&
      !!getShippingMethodId(state, { orderId }) &&
      typeof price?.shipping === 'number' &&
      isFinite(price?.shipping),
  );

  const shipping = useSelector(state => getShipping(state, { orderId }));
  const billing = useSelector(state => getBilling(state, { orderId }));

  const elements = useElements();
  const stripe = useStripe();

  /**
   * The actual function which initiates the payment
   * and places the order into the "paid" state.
   *
   */
  const onPayment = async () => {
    // these should have always been populated with some
    // default values by our components
    if (!shipping || !billing) {
      console.warn({ shipping, billing });
      return;
    }

    // set the purchasing error to nothing
    setError('');
    onShippingUpdate({
      ...shipping,
      address: { ...shipping.address, errors: [] },
    });

    console.log('onPayment!');

    // these will be undefined if stripe hasn't been loaded yet
    if (!elements || !stripe) {
      return;
    }

    const ship_check = validateAddress(shipping?.address);
    if (!shipping || ship_check.name || ship_check.line1 || ship_check.zip) {
      const ship_errors = Object.entries(ship_check)
        .filter(i => i[1])
        .map(i => i[0]);
      onShippingUpdate({
        ...shipping,
        address: { ...shipping.address, errors: ship_errors },
      });
      setError('Shipping address incomplete.');
      return;
    }

    if (!billing.address_same_as_shipping) {
      const bill_check = validateAddress(billing?.address);

      if (bill_check.name || bill_check.line1 || bill_check.zip) {
        setError('Billing address incomplete.');
        const bill_errors = Object.entries(bill_check)
          .filter(i => i[1])
          .map(i => i[0]);
        onBillingUpdate({
          ...billing,
          address: { ...billing?.address, errors: bill_errors },
        });
        return;
      }
    }

    // Use elements.getElement to get a reference to the mounted Element.
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }
    // Pass the Element directly to other Stripe.js methods:
    // e.g. createToken - https://stripe.com/docs/js/tokens_sources/create_token?type=cardElement
    const { token, error } = await stripe.createToken(cardElement);

    if (error) {
      setError(error?.message || '');
      return;
    }
    // add the token to redux state
    onBillingUpdate({ ...billing, token });
    // execute the purchase
    onOrderPurchase();
  };

  const paymentDisabled = isOrdering || !hasValidPrice;

  return (
    <div
      className={`${
        isEmbedded ? 'container-fluid' : 'container'
      } bg-white py-2`}
      data-iframe-height="true"
    >
      <div className="grid">
        <div className="row">
          <div className="col">
            <ErrorBoundary>
              {isEmbedded ? null : <ShopInfo shopId={shopId} />}
            </ErrorBoundary>
            <h1>
              Order <FormattedId id={orderId} />
            </h1>
            <h4 className="my-2">
              <Link to={`/quotes/${quoteId}`}>&#8592; Back to Cart</Link>
            </h4>
            <ShareOrderButton orderId={orderId} />
          </div>
          <div className="col-lg-12">
            {isCompleted ? (
              <div className="row">
                <div className="col">
                  <div className="order-thanks">
                    <h1>Thanks for the order!</h1>
                    <h3>
                      Placed <FormattedDate date={order?.dateModified} />
                    </h3>
                    You're all set, and should receive an email confirmation
                    shortly.
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {!isCompleted && (
          <div className="row my-4">
            <div className="col-md-6">
              {isCompleted ? null : (
                <EditableShippingSection orderId={orderId} />
              )}
              {isCompleted ? null : <StripeBillingSection orderId={orderId} />}
            </div>
            <div className="col-md-6 ">
              <div className="sticky-sidebar">
                <div className="sticky-inner">
                  <table className="table">
                    <TotalsFooter
                      price={price}
                      columns={2}
                      className="total-sidebar"
                    />
                  </table>
                  <PaymentButton
                    disabled={paymentDisabled}
                    error={paymentError}
                    onPayment={onPayment}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row my-4">
          <div className="col">
            <h2>Order Items</h2>
            {purchaseError && (
              <div className="alert alert-danger" role="alert">
                {purchaseError}
              </div>
            )}
            {!isCompleted && (
              <PaymentButton
                disabled={paymentDisabled}
                error={paymentError}
                onPayment={onPayment}
              />
            )}
            <div className="my-3">
              <PartList
                orderId={orderId}
                descriptions={descriptions}
                price={price}
              />
            </div>
            {!isCompleted && (
              <PaymentButton
                disabled={paymentDisabled}
                error={paymentError}
                onPayment={onPayment}
              />
            )}
          </div>
        </div>
        {isOrdering ? (
          <div style={{ display: 'block' }} className="modal in" role="dialog">
            <div className="loader loader-inline">
              <div className="spinner-border " role="status" />
              <h3 className="p-3">Placing Order!</h3>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

type Props = {
  orderId: string;
  order: Components.Schemas.Order;
  shop: Components.Schemas.Shop;
};

const OrderEdit = ({ orderId, order, shop }: Props) => {
  if (!order || !shop) {
    return (
      <div className="loader loader-inline" data-iframe-height="true">
        <div className="spinner-border" role="status" />
        <h3 className="p-3">
          Order #<FormattedId id={orderId} />
        </h3>
      </div>
    );
  } else {
    return <OrderView orderId={orderId} />;
  }
};

const mapOrderStateToProps = (state, { orderId }) => {
  const order = getOrderContent(state, { orderId });
  const shopId = order?.shopId;

  return {
    shopId,
    order,
  };
};

const mapShopStateToProps = (state, { shopId }) => ({
  shop: state.shops[shopId],
});

export default compose<Props, { orderId: string }>(
  withAuthentication,
  withOrderDetail,
  connect(mapOrderStateToProps),
  withShopDetail,
  connect(mapShopStateToProps),
)(OrderEdit);
