import * as firebase from 'firebase/app';
import 'firebase/auth';

import config_public from '../../../config/src/config-public.json';

// Perform first-time initialization of firebase.
if (!firebase.apps.length) {
  firebase.initializeApp(config_public.firebase);
}
export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
