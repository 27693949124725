import { batch } from 'react-redux';

import { generateId } from '@kerfed/common/utils';

import { actions } from '.';
import { RootState, AppDispatch } from '..';
import * as api from '../../api';

export const quoteUpdate = actions.userUpdate;
export const keyUpdate = actions.keyUpdate;

type KeyCreateProps = {
  role: string;
  expiration?: Date;
};

export const keyCreate = (
  userId: string,
  { role, expiration }: KeyCreateProps,
) => async (
  dispatch: AppDispatch,
  getState: () => RootState,
): Promise<string | undefined> => {
  const nonce = generateId();

  // Indicate that ordering has started.
  dispatch(
    actions.keyingStart({
      userId,
      nonce,
    }),
  );

  try {
    // Perform the order creation API call.
    const key = await api.userKeyCreate(userId, {
      role,
      expiration: expiration?.toISOString(),
    });

    batch(() => {
      // Load the resulting order into the state.
      dispatch(
        actions.keyUpdate({
          userId,
          keyId: key.id,
          key,
          isNew: true,
        }),
      );

      // Update the result in the quote (indicating the creation completed).
      dispatch(
        actions.keyingEnd({
          userId,
          nonce,
        }),
      );
    });

    return key.id;
  } catch (err) {
    // Update the result in the quote (indicating the creation failed).
    console.warn(err);
    dispatch(
      actions.keyingEnd({
        userId,
        nonce,
      }),
    );
    return undefined;
  }
};

export const keyDelete = (userId: string, keyId: string) => async (
  dispatch: AppDispatch,
  getState: () => RootState,
): Promise<string | undefined> => {
  const nonce = generateId();

  // Indicate that ordering has started.
  dispatch(
    actions.keyingStart({
      userId,
      nonce,
    }),
  );

  try {
    // Perform the order creation API call.
    const key = await api.userKeyDelete(userId, keyId);

    batch(() => {
      // Load the resulting order into the state.
      dispatch(
        actions.keyRemove({
          userId,
          keyId,
        }),
      );

      // Update the result in the quote (indicating the deletion completed).
      dispatch(
        actions.keyingEnd({
          userId,
          nonce,
        }),
      );
    });

    return key.id;
  } catch (err) {
    // Update the result in the quote (indicating the deletion failed).
    console.warn(err);
    dispatch(
      actions.keyingEnd({
        userId,
        nonce,
      }),
    );
    return undefined;
  }
};

export const userUpdate = actions.userUpdate;
export const userRemove = actions.userRemove;
