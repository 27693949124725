import {
  FormattedDate,
  FormattedId,
  Message,
} from '@kerfed/common-ui/components';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuotesQuery } from '../query/queries';

export default ({ shopId }) => {
  const [offset, setOffset] = useState(0);

  const { isLoading, data, error } = useQuotesQuery(offset, shopId);
  const quotes = data?.items;
  const next = data?.next;
  const perPage = 20;

  return (
    <div>
      {error ? (
        Message(error?.message, 'Error Retrieving Quote List')
      ) : quotes && quotes.length > 0 ? (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th colSpan={3}>
                <small>
                  Showing {offset}-{offset + quotes.length}
                </small>
                <span className="float-right">
                  <button
                    type="button"
                    className="btn btn-outline-secondary mx-1"
                    onClick={() => setOffset(Math.max(offset - perPage, 0))}
                    disabled={offset <= 0}
                  >
                    Prev
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => setOffset(next || offset)}
                    disabled={quotes.length < perPage}
                  >
                    Next
                  </button>
                </span>
              </th>
            </tr>
            <tr>
              <th>Quote Number</th>
              <th>Shop</th>
              <th>Date Modified </th>
            </tr>
          </thead>
          <tbody>
            {quotes.map(quote => (
              <tr key={quote.id}>
                <td>
                  <Link to={`/quotes/${quote.id}`} className="header">
                    <FormattedId id={quote.id} />
                  </Link>
                </td>
                <td>
                  <b>{quote?.shopId}</b>
                </td>
                <td>
                  <FormattedDate date={quote?.dateModified} />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={3}>
                <small>
                  Showing {offset}-{offset + quotes.length}
                </small>
                <span className="float-right">
                  <button
                    className="btn btn-outline-secondary mx-1"
                    onClick={() => setOffset(Math.max(offset - 20, 0))}
                    disabled={offset <= 0}
                  >
                    Prev
                  </button>
                  <button
                    className="btn btn-outline-secondary"
                    onClick={() => setOffset(next || offset)}
                    disabled={quotes.length < perPage}
                  >
                    Next
                  </button>
                </span>
              </th>
            </tr>
          </tfoot>
        </table>
      ) : (
        <div>
          {isLoading ? (
            <div data-iframe-height={true} className="align-center loader">
              <div className="spinner-border" role="status" />
              <h3 className="p-3">Loading Quotes</h3>
            </div>
          ) : (
            <h4>No quotes found.</h4>
          )}
        </div>
      )}
    </div>
  );
};
