/* eslint-disable no-console */
// Define functions used for summing prices
import { PartSpecification } from '../schemas/part';
import { TotalPrice } from '../schemas/price';
import { moneyRound } from '../utils';
// recalculate the sum of price components
export function sumTotal(total: TotalPrice) {
  return moneyRound(
    (total?.units || 0) +
      (total?.setup || 0) +
      (total.taxes || 0) +
      (total.shipping || 0) +
      (total.discount || 0),
  );
}
export function computeTotalPrice(
  specs: PartSpecification[],
  discount?: number,
): TotalPrice {
  // Start out with an empty price and no setups.
  const total = {
    setups: {},
    units: 0.0,
    taxes: 0.0,
    setup: 0.0,
    shipping: 0.0,
    discount: 0.0,
    total: 0.0,
  } as TotalPrice;

  // Iterate through prices to collate setups and add up total cost.
  for (const spec of specs) {
    // Ignore parts that have zero quantity.
    if (spec?.solution?.quantity === 0) {
      continue;
    }

    // if there is no solution set everything to NaN then bail
    if (!spec || !spec.unitPrice) {
      total.units = NaN;
      total.total = NaN;
      total.setup = NaN;
      total.shipping = NaN;
      total.discount = NaN;

      total.setups = {};
      return total;
    }

    // If a part with an unspecified unit price is provided,
    // propagate this to the total unit price.
    if (!spec || !spec.unitPrice) {
      total.units = NaN;
      continue;
    }

    const quantity = spec?.solution?.quantity || 0;
    // Add the unit price to the total price.
    total.units += spec.unitPrice * quantity;
    // Add the cycle time to the total
    //total.duration += spec.unitDuration * quantity;

    // Collate the setups needed to create this part.
    if (spec.unitSetups) {
      for (const [setupId, unitSetup] of Object.entries(spec.unitSetups)) {
        // Create a new setup if this is the first use.
        if (!(setupId in total.setups)) {
          total.setups[setupId] = {
            price: 0.0,
            duration: 0.0,
            utilization: 0.0,
          };
        }
        // Add this new part to the running total.
        const setup = total.setups[setupId];
        total.setups[setupId] = {
          // Use the most expensive of the setup estimates.
          price: Math.max(setup.price, unitSetup.price),
          duration: Math.max(setup.duration, unitSetup.duration),
          // Sum the utilization to compute number of setups later.
          utilization: setup.utilization + unitSetup.utilization * quantity,
        };
      }
    }
  }

  // After we've combined all the setups, sum them.
  for (const setup of Object.values(total.setups)) {
    const utilization = Math.ceil(setup.utilization);
    total.setup += setup.price * utilization;
    //total.setupDuration += setup.duration * utilization;
  }

  // calculate a discount
  total.discount =
    discount && discount > 0.0
      ? -moneyRound(discount * (total.setup + total.units))
      : 0.0;

  // do the final sum
  total.total = sumTotal(total);

  // Return the completed price information.
  return total;
}
