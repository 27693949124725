import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from './actions';

import { auth } from '@kerfed/common/api/firebase';

const AUTH_USER_KEY = 'auth';

type Props = {};

const WithAuthentication = React.memo(() => {
  const dispatch = useDispatch();
  const initialAuth = localStorage.getItem(AUTH_USER_KEY);
  if (initialAuth) {
    dispatch(actions.authUpdate({ user: JSON.parse(initialAuth) }));
  } else {
    dispatch(actions.authClear());
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        const serializedUser = JSON.stringify(user);
        localStorage.setItem(AUTH_USER_KEY, serializedUser);
        // Use a cheap trick to convert this document to a serializable format.
        dispatch(actions.authUpdate({ user: JSON.parse(serializedUser) }));
      } else {
        localStorage.removeItem(AUTH_USER_KEY);
        dispatch(actions.authClear());
      }
    });

    return unsubscribe;
  });

  return null;
});

export default Component => <T extends Props>(props: T) => (
  <div>
    <Component {...props} />
    <WithAuthentication />
  </div>
);
