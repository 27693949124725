//https://stackoverflow.com/questions/52945342/mongooseerror-with-uuid-when-using-import-instead-of-require
import { v4 as uuid } from 'uuid';
import _debounce from 'lodash/debounce';
import isEmail from 'validator/lib/isEmail';

import { PartDocument } from './schemas/part';
import { Address } from './schemas/fields';

// shorthand for generating a UUID
export const generateId = (): string => uuid();

// format a UUID into a clipped format '0000-0000-0000'
export const formatUuid = (id?: string): string => {
  const cleanId = id ? id.replace('-', '').toUpperCase() : 'XXXXXXXXXXXX';
  return `${cleanId.substr(0, 4)}-${cleanId.substr(4, 4)}-${cleanId.substr(
    8,
    4,
  )}`;
};

export const computeTotalDetections = (detections?: {
  [key: string]: number;
}): number => Object.values(detections || {}).reduce((a, b) => a + b, 0.0);

/**
 * Title case a string: 'hey sup bby' -> 'Hey Sup Bby'
 *
 * @param text : string to be title cased
 */
export const titleCase = (text: string): string => {
  try {
    // split by whitespace and apply modifiers
    const split = text
      .split(' ')
      .filter(t => t.length > 0)
      .map(t => t[0].toUpperCase() + t.substr(1).toLowerCase());
    // join back into single string
    return split.join(' ');
  } catch (err) {
    // If failed to titleCase, return original string
    console.warn(`titleCase failed on: ${text}`, err);
    return text;
  }
};

/**
 * Replace HTML style symbols like " &#216;" with unicode equivilants
 *
 * This is nice because various things hate unicode, but JSX
 * hates HTML symbols. So we keep all weirdo symbols as HTML
 * right up to display where we replace them with unicode using
 * this function.
 */
export const htmlToUnicode = (text?: string): string => {
  if (!text) return '';
  return text.replace(/&#(\d+);/g, function(match: any, dec: any) {
    // fromCodePoint allows emoji, unlike fromCharCode:
    // https://stackoverflow.com/questions/22312364/printing-emojis-with-javascript-and-html
    return String.fromCodePoint(dec);
  });
};

/**
 * A very simple email validator designed to avoid
 * false negatives.
 *
 * @param email string from user
 */

export const validateEmail = (email?: string): boolean =>
  email && isEmail(email, { isDisplayName: false });

export type AddressErrors = {
  name: boolean;
  phone: boolean;
  email: boolean;
  line1: boolean;
  line2: boolean;
  company: boolean;
  zip: boolean;
  city: boolean;
};

/**
 * Validate fields of an address returning one boolean per field.
 *
 * @param address filled in address
 */
export const validateAddress = (address?: Address): AddressErrors => {
  function isEmpty(value) {
    return !value || value === '';
  }

  // check the fields in the address
  const error = {
    phone: true,
    email: !validateEmail(address?.email),
    name: isEmpty(address?.name),
    line1: isEmpty(address?.line1),
    line2: isEmpty(address?.line1),
    company: isEmpty(address?.company),
    city: isEmpty(address?.city),
    zip: !(
      typeof address?.zip === 'string' &&
      address.zip.trim().replace(/[^\d]/, '').length === 5
    ),
  };
  // check phone number
  if (typeof address?.phone == 'string') {
    // get digits-only phone number
    const phone = address.phone.trim().replace(/[^\d]/, '');
    error.phone = !(phone.length === 10 || phone.length === 11);
  }

  return error;
};

/**
 * Round money correctly: people have many strong opinions
 * when you are off by $0.01, so be consistent.
 *
 * What QuickBooks apparently does (and thus, us) is round to
 * the nearest cent rather than always rounding up.
 *
 * @param value number rounded to two decimal places
 */
export const moneyRound = (value: number): number => {
  return Math.round(value * 100) / 100;
};

/**
 * Pass to sort lists correctly for numeric values:
 * stuff.sort(numericOrder);
 */
export const numericOrder = (a: number, b: number): number => {
  return a - b;
};

/**
 * Definition of process priority ordering.
 * (This is hoisted outside the function body for optimization.)
 */
const PROCESS_PRIORITY = {
  flat: 1,
  bent: 2,
  mill: 3,
  turn: 4,
  add: 5,
  cots: 6,
  manual: 7,
};

/**
 * Turns a process ID into a sortable priority number.
 *
 * Higher priority parts have a lower number.
 * Unknown processes return positive Infinity.
 *
 * @param processId type of process, eg 'flat', 'bent
 */
export const processPriority = (processId?: string): number => {
  if (!processId) return Infinity;
  return PROCESS_PRIORITY[processId] || Infinity;
};

/**
 * Comparison function for process types.
 * Returns a sort ordering based on their priority.
 */
export const processCompare = (first: string, second: string) => {
  const prioFirst = processPriority(first);
  const prioSecond = processPriority(second);

  if (prioFirst === Infinity && prioSecond === Infinity) return 0;
  return prioFirst - prioSecond;
};

/**
 * For two arrays return True if any member of the
 * second array is larger than the corresponding
 * member of the first array
 *
 * @param first  first array to compare
 * @param second second array to compare
 * @returns True if the first array is lexically larger than the second.
 */
export const anyGreater = (first: number[], second: number[]): boolean => {
  for (let i = 0; i < first.length; i++) {
    if (first[i] > second[i]) {
      return true;
    }
  }
  return false;
};

/**
 * Clamp a number to be within an upper and lower bound.
 *
 * @param value the value to be clamped
 * @param lower the minimum acceptable value
 * @param upper the maximum acceptable value
 */
export const clamp = (value: number, lower: number, upper: number): number => {
  if (value >= upper) {
    return upper;
  } else if (value <= lower) {
    return lower;
  } else {
    return value;
  }
};

/**
 * Evaluate a simple mathematical function defined by keys
 * in an object.
 *
 * @param model
 *    Defines the mathematical function.
 *    Examples of supported models:
 *
 *    {type: 'power',
 *     factor: 10.0,
 *     exponent: -1.2}
 *
 *     {type: 'polynomial',
 *      values: [1.0, 2.0, 3.0]}
 *
 *     {type: 'table',
 *      keys: [0.0, 1.0, 2.0],
 *      values: [1.1, 1.2, 1.3]}
 *
 * @param value
 *    To plug into model
 *
 * @param epsilon
 *    Numerical tolerance for comparing floating point value
 */
export const evaluateModel = (
  model: any,
  value: number,
  epsilon: number = 1e-6,
): number => {
  if (model.type === 'power') {
    // return: Ax^B
    return model.factor * value ** model.exponent;
  } else if (model.type === 'polynomial') {
    // a model that returns results like:
    // values = [A]:        A
    // values = [A, B]:     A + B*value
    // values = [A, B, C]:  A + B*value + C*value^2
    //
    // first, map each value to factor * (value ^ exponent)
    // where exponent is position in the list
    // the reduce line sums the result
    return model.values
      .map((factor: number, exponent: number) => factor * value ** exponent)
      .reduce((a: number, b: number) => a + b, 0);
  } else if (model.type === 'table') {
    // find the index of the first element
    // that is larger than our value
    // if no larger values are found, this will be -1
    const right = model.keys.findIndex((v: number) => v >= value - epsilon);

    // if we have an exact match just return
    if (Math.abs(model.keys[right] - value) < epsilon) {
      return model.values[right];
    }

    // are we allowed to interpolate, by default the answer is yes
    if (model.hasOwnProperty('interpolate') && !model.interpolate) {
      // no exact match and not allowed to interpolate so return NaN
      return NaN;
    }

    // the two indexes making up our line
    // instantiate assuming we are safely mid- range
    const index = [right - 1, right];

    // correct indexes if we are not in the middle of the range
    if (right === -1) {
      // value is off the right side of our table
      index[0] = model.keys.length - 2;
      index[1] = model.keys.length - 1;
    } else if (right === 0) {
      // value if off the negative side of our table
      index[0] = 0;
      index[1] = 1;
    }

    // what is the slope of the line between indexes
    // i.e. rise over run
    const slope =
      (model.values[index[1]] - model.values[index[0]]) /
      (model.keys[index[1]] - model.keys[index[0]]);

    // how far are we past our first data point
    const delta = value - model.keys[index[0]];

    // offset from the first value by delta distance times slope
    return model.values[index[0]] + slope * delta;
  }

  return NaN;
};

/**
 * Returns whether a part is of a type that can be fabricated,
 * either by checking the pricing document or using a hardcoded
 * list of valid process names.
 *
 * @param part the part whose fabricatability is being assessed
 */
export const isFabricatable = (
  part: PartDocument,
  // TODO(PV): This should be OptionsDefition, but that causes a dependency loop.
  // Change this back once refactored into a separate utility class.
  pricing: any,
): boolean => {
  // if nothing defined it's not fabricatable
  if (!part || !part.process) {
    return false;
  }

  try {
    // filter out parts which didn't succeed
    const success = Object.entries(part.process).filter(
      ([key, value]) => value && value.success,
    );
    //if no processes reported success
    if (success.length === 0) {
      return false;
    }
    // String of which process makes this part.
    // i.e. 'bent', 'flat', etc
    const process = success[0][0];

    // If we don't have a key exit
    if (!process) {
      return false;
    }

    // If process pricing is undefined use hardcoded values.
    if (
      !pricing ||
      !pricing.descriptions ||
      !pricing.descriptions.process ||
      !pricing.descriptions.process.values
    ) {
      console.warn('Shop did not include pricing, using defaults');
      return (
        process === 'flat' ||
        process === 'bent' ||
        process === 'cots' ||
        process === 'roll' ||
        process === 'turn'
      );
    }
    // Otherwise, check the pricing document to see if
    // the shop has keys for the process
    return process in pricing.descriptions.process.values;
  } catch (err) {
    console.warn(err);
    return false;
  }
};

/**
 * Create a sparse state update based on entries of a collection.
 *
 * This queries the changes in a snapshot to only change the affected
 * objects, which makes the corresponding React update much more minimal.
 *
 * This is intended to be called within a React setState() function.
 *
 * @param state a React component state
 * @param snapshot a Firebase snapshot from a collection subscriber
 * @param key the key in the state that should be updated
 * @returns an updated React component state to be applied.
 */
export const updateStateOnSnapshot = (
  state: any,
  changes: firebase.firestore.DocumentChange[],
  key: string,
) => {
  const oldValues = state[key];
  const values = changes.reduce((oldValues, { doc, type }) => {
    const value = doc.data();

    // Use object spread to add and remove items from state.
    if (type === 'added' || type === 'modified') {
      // Update state with new information.
      return {
        ...oldValues,
        [doc.id]: { id: doc.id, ...value },
      };
    } else if (type === 'removed') {
      // Remove part from state mapping using object spread.
      const { [doc.id]: deletedValue, ...values } = oldValues;
      return values;
    } else {
      throw new Error(`invalid document modification: ${type}`);
    }
  }, oldValues || {});
  return { ...state, [key]: values };
};

export const debounce = <T extends unknown>(fn: T): T =>
  _debounce(fn, 500, { leading: true, trailing: true });

/**
 * Throws an error if an item is not in an array.
 *
 * @param array an array that should contain the item
 * @param item an item that should be in the array
 * @returns the original item if it was in the array
 */
export const validateItem = (arr: Array<any>, item: any) => {
  if (!arr.includes(item)) {
    throw new Error(`Item '${item}' not in array: [${arr}]`);
  }
  return item;
};

/**
 * Throws an error if a key is not in an object.
 *
 * @param object an object that should contain the key
 * @param key a key that should be present in the object
 * @returns the original key if it was in the array
 */
export const validateKey = (obj: object, key: string) => {
  if (!(key in obj)) {
    const keys = Object.keys(obj);
    throw new Error(`Key '${key}' not in object: [${keys}]`);
  }
  return key;
};

/**
 * Generate all permutations of items in a list of sets.
 * Each permutation contains exactly one element from each set.
 * If ignoreEmpty is passed, entries with no solution will be assigned `null`.
 *
 * @param items a list of sets from which to select items
 * @param ignoreEmpty if we should return a partial result given empty lists
 * @returns a generator which outputs one permutation at a time.
 */
export function* permute(
  items: any[][],
  ignoreEmpty?: boolean,
): IterableIterator<any[]> {
  // If the list of items is empty, we cannot continue.
  if (!items || !items.length) return;

  // Split permutations into the head and tail.
  const [head, ...tail] = items;
  if (!head.length) {
    if (!ignoreEmpty) {
      return;
    } else if (tail.length) {
      for (const others of permute(tail, ignoreEmpty)) {
        yield [null, ...others];
      }
    } else {
      yield [null];
    }
  }

  if (tail.length) {
    // If there are subsequent sets, combine their outputs.
    for (const item of head) {
      for (const others of permute(tail, ignoreEmpty)) {
        yield [item, ...others];
      }
    }
  } else {
    // For the last set, return each item in a 1-element array.
    yield* head.map(v => [v]);
  }
}

/**
 * Generate all permutations of items in a map of sets.
 * Each permutation contains exactly one element from each field.
 *
 * @param items a map of sets from which to select items
 * @param ignoreEmpty if we should return a partial result given empty lists
 * @returns a generator which outputs one permutation at a time.
 */
export function* permuteObject(
  items: {
    [key: string]: any[];
  },
  ignoreEmpty?: boolean,
): IterableIterator<{ [key: string]: any }> {
  // TODO: it may be possible to be more efficient here.
  // Construct matching arrays of keys and values.
  // We use the same iterator to guarantee the same ordering.
  const entries = Object.entries(items);
  const keys = entries.map(([k, _]) => k);
  const values = entries.map(([_, v]) => v);

  for (const p of permute(values, ignoreEmpty)) {
    if (p.length != keys.length)
      throw new Error(`Permutation length mismatch: len(${p}) != len(${keys})`);
    yield p.reduce(
      (a, elem, idx) => (elem !== null ? { ...a, [keys[idx]]: elem } : a),
      {},
    );
  }
}

/**
 * Take up to the first N elements from an iterator.
 *
 * @param iterable an iterable from which to pull elements
 * @param length the max number of elements to pull from iterable
 */
export function* take(iterable: IterableIterator<any> | any[], length: number) {
  for (const val of iterable) {
    if (length-- <= 0) return;
    yield val;
  }
}

/**
 * Quickly non-crypographically hash a string for keys.
 *
 * The top answer on StackOverflow, and substantially faster
 * than solutions that use reduce.
 *
 * @param text the string to be hashed
 */
export const stringHash = (text?: string): number => {
  if (!text) return 0;

  let hash = 0;
  for (const c of text) {
    hash = (hash << 5) - hash + c.codePointAt(0)!;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

/**
 * Create a promise that will resolve in a fixed amount of time.
 *
 * @param ms the number of milliseconds in which to resolve
 */
export const delay = (ms: number) =>
  new Promise(_ => setTimeout(_, ms)) as Promise<void>;

type CancelType<T, Fn> = Fn extends () => Promise<T> ? T : undefined;

/**
 * Execute an async function with a timeout.
 *
 * If the function does not resolve within the timeout, return and optionally
 * perform a cancellation operation.
 *
 * @param ms the number of milliseconds in which to resolve
 * @param mainFn the promise to execute within the timeout
 * @param cancelFn the async function to executed if the timeout is reached
 *
 * @returns [isTimeout, result] where 'result' is the output of either mainFn or cancelFn
 */
export const timed = async <T>(
  ms: number,
  mainFn: () => Promise<T>,
  cancelFn?: () => Promise<T>,
): Promise<[boolean, CancelType<T, typeof cancelFn>]> => {
  // Wait until the function finishes, or a timeout.
  const [isTimeout, result] = await Promise.race([
    (async (): Promise<[boolean, undefined]> => {
      await delay(ms);
      return [true, undefined];
    })(),
    (async (): Promise<[boolean, T]> => {
      return [false, await mainFn()];
    })(),
  ]);

  // Return the appropriate result.
  if (isTimeout) {
    return [true, cancelFn ? await cancelFn() : undefined];
  } else {
    return [false, result];
  }
};

// return a valid but obviously wrong date string
const BAD_DATE = new Date(0).toISOString();

export const dateToIso = (date: any): string => {
  return date?.toDate()?.toISOString() || BAD_DATE;
};
