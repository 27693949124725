import { auth } from '@kerfed/common/api/firebase';
import React, { useEffect, useState } from 'react';

// TODO: what are the state and prop types here?
export const EnsureLoggedIn: React.FC = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      if (user) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);

        // Perform anonymous signin if completely logged out.
        auth.signInAnonymously().catch(error => console.error(error));
      }
    });

    return unsubscribe;
  });

  if (isLoggedIn) return <>{children}</>;

  // Return loading screen while login occurs.
  return (
    <div data-iframe-height={true} className="loader">
      <div className="spinner-border " role="status" />
      <h3 className="p-3">Loading Session</h3>
    </div>
  );
};

export default EnsureLoggedIn;
