import React, { useState, useEffect, useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { OrderParams } from '@kerfed/common/schemas/order';
import { quoteShare, orderShare } from '../api';

import { FaLink } from 'react-icons/fa';

const selectAll = e => {
  e.preventDefault();
  e.target.focus();
  e.target.select();
};

type ShareProps = {
  onGenerateLink: () => Promise<string | undefined>;
};

export const ShareButton = ({ onGenerateLink }: ShareProps) => {
  const [isOpen, setOpen] = useState(false);
  const [isCopied, setCopied] = useState(false);
  const [link, setLink] = useState<string | undefined>(undefined);
  const hasToken = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.has('token');
  }, []);

  useEffect(() => {
    (async () => {
      // Only generate a new link if one doesn't already exist.
      if (!isOpen || link) return;

      // TODO(PV): Cancellation on multiple link calls.
      const generatedLink = await onGenerateLink();
      setLink(generatedLink);
    })();
  }, [isOpen, onGenerateLink]);

  // Always reset copied status when opening/closing.
  useEffect(() => {
    setCopied(false);
  }, [isOpen]);

  const CopyButton = () =>
    link ? (
      <CopyToClipboard text={link!} onCopy={() => setCopied(true)}>
        <button className="btn btn-primary btn-sm">
          {isCopied ? 'Copied!' : 'Copy'}
        </button>
      </CopyToClipboard>
    ) : (
      <div className="input-group-text">
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      </div>
    );

  return (
    <div className="input-group">
      <button
        className={`btn btn-outline-${hasToken ? 'danger' : 'primary'}`}
        onClick={_ => setOpen(!isOpen)}
        disabled={hasToken}
      >
        <FaLink />
        &nbsp;
        {hasToken ? 'Using Shared Link' : 'Share Link'}
      </button>
      {isOpen && (
        <>
          <input
            style={{ marginLeft: '2px', maxWidth: '30em' }}
            type="text"
            onClick={selectAll}
            value={link || ''}
            className="form-control"
            readOnly={true}
          />
          <CopyButton />
        </>
      )}
    </div>
  );
};

type QuoteShareProps = {
  quoteId: string;
  params?: OrderParams;
};

export const ShareQuoteButton = ({ quoteId, params }: QuoteShareProps) => {
  const onGenerateLink = async () => (await quoteShare(quoteId, params!)).link;
  return <ShareButton onGenerateLink={onGenerateLink} />;
};

type OrderShareProps = {
  orderId: string;
  params?: any;
};

export const ShareOrderButton = ({ orderId, params }: OrderShareProps) => {
  const onGenerateLink = async () => (await orderShare(orderId, params!)).link;
  return <ShareButton onGenerateLink={onGenerateLink} />;
};
