import React, { useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { actions } from './index';
import * as api from '../../api';

type Props = {
  quoteId?: string;
};

const WithQuote = React.memo((props: Props) => {
  const { quoteId } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  // Create a periodic subscription to quote updates.
  // TODO(PV): Make this periodic.
  useEffect(() => {
    if (!quoteId) return;
    let dateSince: Date | undefined;
    let cancelId: number | undefined;
    let isCancelled = false;

    const update = async () => {
      try {
        const nextDateSince = new Date(Date.now());
        const quote = await api.quoteGet(quoteId, dateSince);
        if (!quote) return;
        if (isCancelled) return;

        dispatch(
          actions.quoteUpdate({
            quoteId,
            quote,
          }),
        );

        dateSince = nextDateSince;
        cancelId = window.setTimeout(update, 3100);
      } catch (err) {
        if (err === 401 || err == 404) {
          // cancel the refreshes
          window.clearTimeout(cancelId);
          isCancelled = true;
          // forward to 404 page
          history.push('/404');
        } else {
          if (err.name !== 304) {
            dateSince = undefined;
            console.error({ err });
            dispatch(
              actions.quoteRemove({
                quoteId,
              }),
            );
          } else {
            dateSince = new Date(Date.now());
          }

          cancelId = window.setTimeout(update, 5100);
        }
      }
    };

    cancelId = window.setTimeout(update, 0);
    return () => {
      isCancelled = true;
      window.clearTimeout(cancelId);
    };
  });

  // Create a periodic subscription to the list of files.
  useEffect(() => {
    if (!quoteId) return;
    let dateSince: Date | undefined;
    let cancelId: number | undefined;

    const update = async () => {
      try {
        const nextDateSince = new Date(Date.now());
        const files = await api.quoteFileList(quoteId, 0, 100, dateSince);

        batch(() => {
          for (const file of files?.items || []) {
            dispatch(
              actions.fileUpdate({
                quoteId,
                fileId: file.id,
                file,
              }),
            );
          }
        });

        dateSince = nextDateSince;
        cancelId = window.setTimeout(update, 3700);
      } catch (e) {
        if (e.name !== 304) {
          dateSince = undefined;
          console.error(e);
        } else {
          dateSince = new Date(Date.now());
        }
        cancelId = window.setTimeout(update, 5700);
      }
    };

    cancelId = window.setTimeout(update, 0);
    return () => window.clearTimeout(cancelId);
  });

  // Create a periodic subscription to the list of parts.
  useEffect(() => {
    if (!quoteId) return;
    let dateSince: Date | undefined;
    let cancelId: number | undefined;

    const update = async () => {
      try {
        const nextDateSince = new Date(Date.now());
        const parts = await api.quotePartList(quoteId, 0, 100, dateSince);

        batch(() => {
          for (const part of parts?.items || []) {
            dispatch(
              actions.partUpdate({
                quoteId,
                partId: part.id,
                part,
              }),
            );
          }
        });

        dateSince = nextDateSince;
        cancelId = window.setTimeout(update, 4100);
      } catch (e) {
        dateSince = undefined;
        cancelId = window.setTimeout(update, 6700);
      }
    };

    cancelId = window.setTimeout(update, 0);
    return () => window.clearTimeout(cancelId);
  });

  return null;
});

export default Component => <T extends Props>(props: T) => (
  <div>
    <Component {...props} />
    <WithQuote quoteId={props.quoteId} />
  </div>
);
