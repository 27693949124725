import { PayloadAction } from '@reduxjs/toolkit';
import { AuthState, User } from './types';

export const authUpdate = (
  state: AuthState,
  action: PayloadAction<{
    user: User;
  }>,
) => {
  const { user } = action.payload;
  state.user = user;
};

export const authClear = (state: AuthState, _action: PayloadAction) => {
  state.user = undefined;
};
