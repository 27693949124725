import React from 'react';

type Props = {};

type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends React.Component<Props, State> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError = error => {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  };

  componentDidCatch = (error, errorInfo) => {
    // Log this to sentry.
    console.warn('Error boundary triggered', error, errorInfo);
  };

  render = () => {
    if (this.state.hasError) {
      return <div className="error-boundary" />;
    } else {
      return this.props.children || null;
    }
  };
}
