import React from 'react';

export default ({
  price,
  message,
  text = 'Manual Quoting Required',
}: {
  price: number | undefined;
  message?: string;
  text?: string;
}) => {
  if (!Number.isFinite(price)) {
    return (
      <div
        style={{
          textDecoration: 'underline dotted',
          textAlign: 'center',
          cursor: 'pointer',
        }}
        title={message || ''}
      >
        {text}
      </div>
    );
  } else if (!price) {
    return <>-</>;
  } else {
    return <>${price.toFixed(2)}</>;
  }
};
