import { generateId } from '@kerfed/common/utils';

import { RootState, AppDispatch } from '../index';
import { actions } from './index';
import {
  getBilling,
  getShipping,
  getTaxes,
  getShippingParcels,
  getOrderComputed,
  getShippingMethod,
} from './selectors';
import * as api from '../../api';
import { batch } from 'react-redux';

export const orderPurchase = ({ orderId }: { orderId: string }) => async (
  dispatch: AppDispatch,
  getState: () => RootState,
) => {
  const nonce = generateId();

  // Indicate that ordering has started.
  dispatch(actions.orderingStart({ orderId, nonce }));

  // Get the current state and use this to construct the order.
  const state = getState();
  const price = getOrderComputed(state, { orderId })?.price;

  const billing = getBilling(state, { orderId });

  // retrived from `options`
  const shipping = getShipping(state, { orderId });

  // retrieved from `computed`
  const parcels = getShippingParcels(state, { orderId });

  // retrieved from `options`
  const method = getShippingMethod(state, { orderId });

  // retrieved from `computed`
  const taxes = getTaxes(state, { orderId });

  // for taxes and shipping method token is the encrypted JWT
  // so we don't need to POST anything else
  const redactedShipping = {
    address: shipping?.address, // options
    parcels, // computed
    taxes, // computed
    method, // computed
  };

  // Redact some of the billing information.
  const redactedBilling = {
    address: billing?.address_same_as_shipping
      ? shipping?.address
      : billing?.address,
    token: {
      id: billing.token?.id || '',
    },
  };

  try {
    // Perform the actual order creation action.
    const order = await api.orderPurchase(orderId, {
      expectedTotal: price?.total || 0.0,
      billing: redactedBilling,
      shipping: redactedShipping,
    });

    batch(() => {
      // Load the resulting order into the state.
      dispatch(
        actions.orderUpdate({
          orderId: order.id,
          order,
        }),
      );
      dispatch(actions.orderingEnd({ orderId, nonce }));
    });
  } catch (err) {
    // Report an error if it fails.
    console.warn(`Order purchase failed: ${err?.message}`);
    dispatch(actions.orderingEnd({ orderId, nonce, error: err?.message }));
  }
};

// apply shipping calculating an address
export const shippingApply = ({
  orderId,
  shipping,
}: {
  orderId: string;
  shipping: Components.Schemas.Shipping;
}) => async (dispatch: AppDispatch) => {
  const nonce = generateId();

  // Indicate that ordering has started.
  dispatch(actions.shippingStart({ orderId, nonce }));

  // Perform the actual order creation action.
  try {
    const response = await api.orderShipping(orderId, shipping);
    dispatch(actions.shippingEnd({ orderId, nonce, shipping, response }));
  } catch (err) {
    // Report an error if it fails.
    console.warn(`Order shipping failed: ${err}`);
    const error = err?.toString ? err.toString() : 'Unknown shipping error.';
    dispatch(actions.shippingEnd({ orderId, nonce, shipping, error }));
  }
};

export const billingUpdate = actions.billingUpdate;
export const shippingUpdate = actions.shippingUpdate;
export const shippingMethodUpdate = actions.shippingMethodUpdate;
