import '@kerfed/common-ui/assets/helpers.css';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RootState } from '../store';
import { optionsQuantitySet } from '../store/quote/actions';
import {
  getMethodIsComputing,
  getMethodSpec,
  makeGetComputedPartOptions,
} from '../store/quote/selectors';
import EditablePart from './EditablePart';
import ErrorBoundary from './ErrorBoundary';
import SafePrice from './SafePrice';

export const computeTotalDetections = (detections?: {
  [key: string]: number;
}): number => Object.values(detections || {}).reduce((a, b) => a + b, 0.0);

const OptionalPrice = ({ isEnabled, price }) => {
  if (isEnabled) {
    return (
      <SafePrice
        price={price}
        text="No Quote"
        message="One or more part options required to compute this price are missing or invalid. Either finish selection of options, or get a manual quote via email."
      />
    );
  } else {
    return <span>-</span>;
  }
};

type Props = {
  quoteId: string;
  partId: string;
  part: Components.Schemas.QuotePart;
  partOptions: Components.Schemas.PartOptions;
  partSpec: Components.Schemas.Specification;
  isMethodComputing: boolean;
  onOptionsQuantitySet: typeof optionsQuantitySet;
};

const EditablePartRow = (props: Props) => {
  const { quoteId, partId } = props;
  const { partOptions, partSpec, isMethodComputing } = props;

  // TODO: clean this up.
  const quantity = partOptions?.quantity || 0;

  return (
    <tr>
      <td>
        <ErrorBoundary>
          <EditablePart quoteId={quoteId} partId={partId} />
        </ErrorBoundary>
      </td>

      <td>
        <ErrorBoundary>
          {!isMethodComputing ? (
            <div>
              <h2>
                <OptionalPrice
                  isEnabled={quantity}
                  price={partSpec?.unitPrice}
                />
              </h2>
              <hr />
              {partSpec?.description?.part && (
                <ReactMarkdown source={partSpec.description.part} />
              )}
            </div>
          ) : (
            <div className="loader loader-inline">
              <div className="spinner-border " role="status" />
            </div>
          )}
        </ErrorBoundary>
      </td>

      <td>
        <ErrorBoundary>
          {!isMethodComputing ? (
            <div>
              <h2>
                <OptionalPrice
                  isEnabled={quantity}
                  price={(partSpec?.unitPrice || 0.0) * quantity}
                />
              </h2>
              <hr />
              {partSpec?.description?.lead && (
                <ReactMarkdown source={partSpec.description.lead} />
              )}
            </div>
          ) : (
            <div className="loader loader-inline">
              <div className="spinner-border " role="status" />
            </div>
          )}
        </ErrorBoundary>
      </td>
    </tr>
  );
};

const mapStateToProps = () => {
  const getPartOptions = makeGetComputedPartOptions();

  return (state: RootState, props: Props) => {
    const partOptions = getPartOptions(state, { ...props });
    const methodId = partOptions?.methodId || '';
    const methodProps = { ...props, methodId };
    const partSpec = getMethodSpec(state, methodProps);
    const isMethodComputing = getMethodIsComputing(state, methodProps);

    return {
      partOptions,
      partSpec,
      isMethodComputing,
    };
  };
};

const mapDispatchToProps = {
  onOptionsQuantitySet: optionsQuantitySet,
};

export default compose<Props, { quoteId: string; partId: string }>(
  connect(mapStateToProps, mapDispatchToProps),
)(EditablePartRow);
