import React from 'react';

import { RightLeftCard } from '../components';

export default () => (
  <div>
    <div className="onboard">
      <img className="img-fluid w-25" src="/static/images/cube.svg" />
      <h2>Modern e-commerce for manufacturers.</h2>
    </div>

    <div className="container-lg p-3">
      <div className="grid">
        <div className="row">
          <div className="col-md">
            <h2>
              Replace old web forms with a fully automatic drop-in replacement.
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <img className="img-fluid" src="/static/images/busted.png" />
          </div>
          <div className="col-9">
            <img className="img-fluid" src="/static/images/new.png" />
          </div>
        </div>
      </div>
    </div>

    <div className="container-md">
      <RightLeftCard
        cards={[
          {
            title: 'Embed Anywhere',
            text:
              'The Kerfed Engine quoting widget can be added to any webpage with just two lines of HTML: we handle everything else. Click image to see technical details.',
            image: '/static/images/embed.png',
            link: 'https://github.com/kerfed/kerfed-embed-example',
          },
          {
            title: 'Automatic Quoting',
            text:
              'The Kerfed Engine provides automatic CAD analysis to accurately quote hundreds of parts in under a minute. Milling, turning, laser cutting, 3D printing, bending, and others are supported.',
            image: '/static/images/draw_satellite.svg',
            link: '/pricing',
          },
          {
            title: 'Release To Manufacturing',
            text:
              'Once orders are placed with a credit card, you get a full RTM package, including original models, CAM data, and prepaid autogenerated shipping labels (UPS labels get a 54% discount over retail!) with customer addresses filled in and parcels accurately calculated from part weight and size.',
            image: '/static/images/RTM.png',
            link: '/help',
          },
          {
            title: 'Automatic CAM',
            text:
              'We aim to produce files that can be loaded on machine tools and run immediately. We currently produce packed/nested DXF files for flat processes, and G-code for milling is in beta testing.',
            image: '/static/images/packed.png',
            link: '/help',
          },
          {
            title: 'API',
            text:
              'Kerfed can easily be integrated into existing systems via a modern REST API, for things like ERP integrations, built-in autoquoting, etc.',
            image: '/static/images/tutorial.jpg',
            link: 'https://api.kerfed.com',
          },
        ]}
      />
    </div>
  </div>
);
