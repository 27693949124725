import { createSlice } from '@reduxjs/toolkit';

import { UserState } from './types';
import * as reducers from './reducers';

const INITIAL_STATE: UserState = {};

const slice = createSlice({
  name: 'users',
  initialState: INITIAL_STATE,
  reducers,
});

export const actions = slice.actions;
export const reducer = slice.reducer;
