import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import { EmbedContext } from '@kerfed/common-ui/contexts';

import {
  NavBar,
  Footer,
  EnsureLoggedIn,
  Analytics,
  Facebook,
  ScrollToTop,
} from './components';
import {
  Home,
  OrderEdit,
  OrderList,
  QuoteEdit,
  QuoteList,
  QuoteNew,
  Tutorial,
  About,
  Returns,
  Terms,
  Technology,
  Tolerances,
  Design,
  Pricing,
  Privacy,
  Onboard,
  NotFound,
  ShopAdmin,
  Materials,
  Account,
} from './pages';
import './styles/App.css';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config_public from '@kerfed/config/config-public.json';
const stripePromise = loadStripe(config_public?.stripe?.public_key);

const queryCache = new QueryCache();

export default props => (
  <ReactQueryCacheProvider queryCache={queryCache}>
    <Router>
      <ScrollToTop />
      <div className="App">
        <EnsureLoggedIn>
          <EmbedContext.Consumer>
            {({ isEmbedded }) => !isEmbedded && <NavBar />}
          </EmbedContext.Consumer>
          <div className="App-content">
            <Switch>
              <Route exact={true} path="/" render={props => <Home />} />
              <Route exact={true} path="/help" render={props => <Tutorial />} />
              <Route
                exact={true}
                path="/onboard"
                render={props => <Onboard />}
              />
              <Route exact={true} path="/about" render={props => <About />} />
              <Route exact={true} path="/design" render={props => <Design />} />
              <Route exact={true} path="/terms" render={props => <Terms />} />
              <Route
                exact={true}
                path="/privacy"
                render={props => <Privacy />}
              />
              <Route
                exact={true}
                path="/pricing"
                render={props => <Pricing />}
              />
              <Route
                exact={true}
                path="/technology"
                render={props => <Technology />}
              />
              <Route
                exact={true}
                path="/tolerances"
                render={props => <Tolerances />}
              />
              <Route
                exact={true}
                path="/returns"
                render={props => <Returns />}
              />
              <Route
                exact={true}
                path="/materials"
                render={props => <Materials shopId={'kerfed'} />}
              />
              <Route
                exact={true}
                path="/materials/:shop"
                render={props => <Materials shopId={props.match.params.shop} />}
              />

              <Route
                path="/new"
                render={props => <QuoteNew shopId="kerfed" />}
              />
              <Route
                exact={true}
                path="/quotes/"
                render={props => <QuoteList />}
              />
              <Route
                path="/quotes/:quoteId"
                render={props => (
                  <QuoteEdit quoteId={props.match.params.quoteId} />
                )}
              />
              <Route
                exact={true}
                path="/orders/"
                render={props => <OrderList shopId="kerfed" />}
              />
              <Route
                path="/orders/:orderId"
                render={props => (
                  <Elements stripe={stripePromise}>
                    <OrderEdit orderId={props.match.params.orderId} />{' '}
                  </Elements>
                )}
              />

              <Route
                exact={true}
                path="/shop/:shop/"
                render={props => (
                  <Redirect to={`/shops/${props.match.params.shop}`} />
                )}
              />
              <Route
                exact={true}
                path="/shops/:shop/"
                render={props => <QuoteNew shopId={props.match.params.shop} />}
              />
              <Route
                path="/shops/:shop/admin"
                render={props => <ShopAdmin shopId={props.match.params.shop} />}
              />
              <Route path="/account" render={props => <Account />} />
              <Route component={NotFound} />
            </Switch>
          </div>
          <EmbedContext.Consumer>
            {({ isEmbedded }) => !isEmbedded && <Footer />}
          </EmbedContext.Consumer>
          <Analytics />
          <Facebook />
        </EnsureLoggedIn>
      </div>
    </Router>
  </ReactQueryCacheProvider>
);
