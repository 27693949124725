import dset from 'dset';
import { PayloadAction } from '@reduxjs/toolkit';

import { OrderState } from './types';
import { sumTotal } from '@kerfed/common/pricing/total';

export const orderUpdate = (
  state: OrderState,
  action: PayloadAction<{
    orderId: string;
    order: Components.Schemas.Order;
  }>,
) => {
  const { orderId, order } = action.payload;
  dset(state, [orderId, 'content'], order);

  if (order.billing)
    dset(state, [orderId, 'options', 'billing'], order.billing);

  if (order.shipping)
    dset(state, [orderId, 'options', 'shipping'], order.shipping);

  if (order.price) {
    dset(
      state,
      [orderId, 'computed', 'price', 'discount'],
      order.price.discount,
    );
    dset(state, [orderId, 'computed', 'price', 'setup'], order.price.setup);
    dset(state, [orderId, 'computed', 'price', 'total'], order.price.total);
    dset(state, [orderId, 'computed', 'price', 'setups'], order.price.setups);
    dset(state, [orderId, 'computed', 'price', 'units'], order.price.units);

    if (typeof order.price.shipping === 'number') {
      dset(
        state,
        [orderId, 'computed', 'price', 'shipping'],
        order.price.shipping,
      );
    }

    if (typeof order.price.taxes === 'number') {
      dset(state, [orderId, 'computed', 'price', 'taxes'], order.price.taxes);
    }
  }
};

export const orderRemove = (
  state: OrderState,
  action: PayloadAction<{
    orderId: string;
  }>,
) => {
  const { orderId } = action.payload;
  delete state[orderId];
};

export const orderingStart = (
  state: OrderState,
  action: PayloadAction<{
    orderId: string;
    nonce: string;
  }>,
) => {
  const { orderId, nonce } = action.payload;
  dset(state, [orderId, 'computed', 'isOrdering'], nonce);
};

export const orderingEnd = (
  state: OrderState,
  action: PayloadAction<{
    orderId: string;
    nonce: string;
    error?: string;
  }>,
) => {
  const { orderId, nonce, error } = action.payload;
  if (state[orderId]?.computed?.isOrdering !== nonce) return;

  dset(state, [orderId, 'computed', 'isOrdering'], undefined);
  // display to a user why the order failed
  dset(state, [orderId, 'errors', 'purchase'], error);
};

export const partUpdate = (
  state: OrderState,
  action: PayloadAction<{
    orderId: string;
    partId: string;
    part: Components.Schemas.OrderPart;
  }>,
) => {
  const { orderId, partId, part } = action.payload;
  dset(state, [orderId, 'parts', partId], part);
};

export const partRemove = (
  state: OrderState,
  action: PayloadAction<{
    orderId: string;
    partId: string;
  }>,
) => {
  const { orderId, partId } = action.payload;
  if (state[orderId]?.parts) delete state[orderId].parts![partId];
};

export const billingUpdate = (
  state: OrderState,
  action: PayloadAction<{
    orderId: string;
    billing: Components.Schemas.Billing;
  }>,
) => {
  const { orderId, billing } = action.payload;
  dset(state, [orderId, 'options', 'billing'], billing);
};

export const shippingUpdate = (
  state: OrderState,
  action: PayloadAction<{
    orderId: string;
    shipping: Components.Schemas.Shipping;
  }>,
) => {
  const { orderId, shipping } = action.payload;
  dset(state, [orderId, 'options', 'shipping'], shipping);
};

export const shippingStart = (
  state: OrderState,
  action: PayloadAction<{
    orderId: string;
    nonce: string;
  }>,
) => {
  const { orderId, nonce } = action.payload;

  // Clear any previous pricing
  shippingClear(state, { type: action.type, payload: { orderId } });
  // set the state to be in-progress
  dset(state, [orderId, 'computed', 'isShipping'], nonce);
};

export const shippingClear = (
  state: OrderState,
  action: PayloadAction<{
    orderId: string;
  }>,
) => {
  const { orderId } = action.payload;

  dset(state, [orderId, 'options', 'shipping', 'method'], null);
  dset(state, [orderId, 'computed', 'shipping', 'methods'], null);
  dset(state, [orderId, 'computed', 'price', 'shipping'], null);
  dset(state, [orderId, 'computed', 'price', 'taxes'], null);
  dset(state, [orderId, 'computed', 'price', 'total'], null);
};

/**
 * After the shipping API has returned
 *
 * @param state
 * @param action
 * @returns
 */
export const shippingEnd = (
  state: OrderState,
  action: PayloadAction<{
    orderId: string;
    nonce: string;
    shipping: Components.Schemas.Shipping;
    response?: Paths.OrderShipping.Responses.$200;
    error?: string;
  }>,
) => {
  const { orderId, nonce, shipping, response, error } = action.payload;
  if (state[orderId]?.computed?.isShipping !== nonce) return;
  dset(state, [orderId, 'computed', 'isShipping'], undefined);
  dset(state, [orderId, 'errors', 'shipping'], error);

  // If there was no response, just return.
  if (!response) return;

  // Extract the methods that were returned into an object.
  const methods = {};
  for (const method of response?.methods || []) {
    methods[method.id!] = method;
  }

  // set the selected shipping options
  dset(state, [orderId, 'options', 'shipping'], shipping);

  // methods, parcels, and taxes go in computed
  dset(state, [orderId, 'computed', 'shipping'], {
    methods,
    parcels: response?.parcels,
    taxes: response?.taxes,
  });

  // Set the default shipping methods from the result.
  // this will apply to OPTIONS, not computed
  const methodId = response?.defaultMethodId || Object.keys(methods)[0];
  // run the method apply operation to set totals correctly
  shippingMethodUpdate(state, {
    type: action.type,
    payload: { orderId, methodId },
  });
};

export const shippingMethodUpdate = (
  state: OrderState,
  action: PayloadAction<{
    orderId: string;
    methodId: string;
  }>,
) => {
  const { orderId, methodId } = action.payload;

  // details about the selected shipping method

  const shipping = state[orderId].computed?.shipping;
  const method = shipping?.methods?.[methodId];

  if (!shipping || !method) {
    return;
  }

  // apply the shipping method as an option (from computed)
  // dset(state, [orderId, 'options', 'shipping'], shipping);
  dset(state, [orderId, 'options', 'shipping', 'method'], method);
  // Write the taxes and shipping returned by the shipping computation into the computed price.
  dset(state, [orderId, 'computed', 'price', 'taxes'], shipping?.taxes?.amount);
  dset(state, [orderId, 'computed', 'price', 'shipping'], method.price);

  // re-compute and apply the total here
  const price = state[orderId].computed?.price;
  const total = sumTotal({
    shipping: price?.shipping || 0,
    discount: price?.discount || 0,
    taxes: price?.taxes || 0,
    setup: price?.setup || 0,
    units: price?.units || 0,
  });
  dset(state, [orderId, 'computed', 'price', 'total'], total);
};
