import dset from 'dset';
import { PayloadAction } from '@reduxjs/toolkit';

import { UserState } from './types';

export const userUpdate = (
  state: UserState,
  action: PayloadAction<{ userId: string; user: Components.Schemas.User }>,
) => {
  const { userId, user } = action.payload;
  dset(state, [userId, 'content'], user);
};

export const userRemove = (
  state: UserState,
  action: PayloadAction<{ userId: string }>,
) => {
  const { userId } = action.payload;
  delete state[userId];
};

export const keyUpdate = (
  state: UserState,
  action: PayloadAction<{
    userId: string;
    keyId: string;
    key: Components.Schemas.ApiKey;
    isNew?: boolean;
  }>,
) => {
  const { userId, keyId, key, isNew } = action.payload;
  dset(state, [userId, 'keys', keyId], { ...key, isNew });
};

export const keyRemove = (
  state: UserState,
  action: PayloadAction<{ userId: string; keyId: string }>,
) => {
  const { userId, keyId } = action.payload;
  if (state[userId]?.keys?.[keyId]) delete state[userId]!.keys![keyId];
};

export const keyingStart = (
  state: UserState,
  action: PayloadAction<{ userId: string; nonce: string }>,
) => {
  const { userId, nonce } = action.payload;
  dset(state, [userId, 'computed', 'isKeying'], nonce);
};

export const keyingEnd = (
  state: UserState,
  action: PayloadAction<{ userId: string; nonce: string }>,
) => {
  const { userId, nonce } = action.payload;
  if (state[userId]?.computed?.isKeying !== nonce) return;

  dset(state, [userId, 'computed', 'isKeying'], undefined);
};
