import { EmbedContext } from '@kerfed/common-ui/contexts';
import React, { useContext } from 'react';
import { ErrorBoundary, ShopInfo } from '../components';
import { useShopQuery } from '../query/queries';

/**
 * Create a table element for every material we support in our
 * pricing structure for a shop.
 *
 * Parameters
 * ---------------
 * pricing : object
 *   The shop.pricing document
 *
 * Returns
 * -------------
 * table : element
 *   A react element for the table
 */
const MaterialTable = ({ shop }) => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>Material</th>
        </tr>
      </thead>
      <tbody>
        {shop.options.material.map(item => (
          <tr>
            <td>
              <h5>{item.text}</h5>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

/**
 * A page with a list of materials supported by a shop as well
 * as a slight amount of blurbage.
 */
export default ({ shopId }: { shopId: string }) => {
  const { isEmbedded } = useContext(EmbedContext);
  const shopQuery = useShopQuery(shopId);
  const shop = shopQuery.data;

  return shopQuery.isLoading ? (
    <div className="loader loader-inline">
      <div className="spinner-border " role="status" />
      <h3 className="p-3">Loading Session</h3>
    </div>
  ) : (
    <div className="container-md bg-white p-3">
      <div>
        {isEmbedded ? null : <ShopInfo shopId={shopId} />}
        <h1>Material Database</h1>
        <p>
          The following is a list of materials that are currently in our
          database. Not every material is usable with every process. Don't see a
          material you're interested in? Let us know at
          <a href="mailto:support@kerfed.com"> support@kerfed.com</a>, we're
          usually able to load new material data in less than a day.
        </p>
        <ErrorBoundary>
          <MaterialTable shop={shop} />
        </ErrorBoundary>
        <div className="segmentRectangle" />
      </div>
    </div>
  );
};
